import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListTask = (pageIndex = 1, pageSize = 20, sortExpression=undefined, searchData=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    searchData?.startDate && params.append("startDate", searchData?.startDate);
    searchData?.endDate && params.append("endDate", searchData?.endDate);
    searchData?.keyword && params.append("keyword", searchData?.keyword);
    searchData?.departmentId && params.append("departmentId", searchData?.departmentId);
    searchData?.address && params.append("address", searchData?.address);
    searchData?.customerId && params.append("customerId", searchData?.customerId);
    searchData?.herdsmanId && params.append("herdsmanId", searchData?.herdsmanId);
    searchData?.needHelp != undefined && params.append("needHelp", searchData?.needHelp);
    return service.get(ApiUrl.GetListTaskCalendar, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetListTaskNeedHelp = (pageIndex = 1, pageSize = 10, sortExpression = undefined, searchData = undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    searchData?.startDate && params.append("startDate", searchData?.startDate);
    searchData?.endDate && params.append("endDate", searchData?.endDate);
    searchData?.keyword && params.append("keyword", searchData?.keyword);
    searchData?.departmentId && params.append("departmentId", searchData?.departmentId);
    searchData?.address && params.append("address", searchData?.address);
    searchData?.customerId && params.append("customerId", searchData?.customerId);
    searchData?.herdsmanId && params.append("herdsmanId", searchData?.herdsmanId);
    searchData?.needHelp != undefined && params.append("needHelp", searchData?.needHelp);
    return service.get(ApiUrl.GetListNeedHelp, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailTask = (id) => {
    return service.get(ApiUrl.GetDetailTaskCalendar.replace("{id}",id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateTask = (data) => {
    return service.post(ApiUrl.CreateTaskCalendar, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateTask = (data) => {
    return service.put(ApiUrl.UpdateTaskCalendar, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteTask = (id) => {
    return service.delete(ApiUrl.DeleteTaskCalendar.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentTask = (id) => {
    return service.delete(ApiUrl.DeletePermanentTaskCalendar.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveTask = (id) => {
    return service.put(ApiUrl.ActiveTaskCalendar.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveTask = (id) => {
    return service.put(ApiUrl.DeActiveTaskCalendar.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}

export const ChangeStatusTask = (data) => {
    return service.put(ApiUrl.ChangeStatusTaskCalendar, data).then((res) => { return res }).catch(err => { throw err });
}
export const HelpingCreateTask = (data) => {
    return service.post(ApiUrl.HelpingCreateTaskCalendar, data).then((res) => { return res }).catch(err => { throw err });
}
export const HelpingDeleteTask = (id) => {
    return service.delete(ApiUrl.HelpingDeleteTaskCalendar.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}

export const ReceiptHelping = (id) => {
    console.log(ApiUrl.ReceiptHelping.replace("{id}", id));
    return service.put(ApiUrl.ReceiptHelping.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const CancelReceiptHelping = (id) => {
    return service.put(ApiUrl.CancelReceiptHelping.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const AssignHerdsman = (data) => {
    return service.put(ApiUrl.AssignHerdsman, data).then((res) => { return res }).catch(err => { throw err });
}