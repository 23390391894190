const headCellsListSwatActivityReport = [
    {
        id: 'Herdsman.Department.Name',
        numeric: false,
        disablePadding: false,
        label: 'Biệt đội',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Herdsman.Name',
        numeric: false,
        disablePadding: false,
        label: 'Họ và tên',
        className: 'pt-3 pb-3',
    },
    
    {
        id: 'ReportDate',
        numeric: false,
        disablePadding: false,
        label: 'Ngày',
        className: 'pt-3 pb-3',
    },
    {
        id: 'details',
        numeric: false,
        disablePadding: false,
        label: 'Công việc',
        className: 'pt-3 pb-3',
    },
    //{
    //    id: 'TimeQuatity',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Thời lượng/ Số lượt',
    //    className: 'pt-3 pb-3',
    //    style: { maxWidth: "60px" }
    //},
    //{
    //    id: 'IsConfirm',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Xác  nhận',
    //    className: 'pt-3 pb-3',
    //},
    //{
    //    id: 'HerdsmanConfirm.Name',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Người xác  nhận',
    //    className: 'pt-3 pb-3',
    //},
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
    },
];

export default headCellsListSwatActivityReport;