
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
    // All day - single day
    {
        title: "All day - single day event",
        start: "2024-03-11",
        id: createEventId()
    },
    // All day - multiple day
    {
        title: "All day - multiple day event",
        start: "2024-03-11",
        end: "2024-03-13",
        id: createEventId()
    },
    // Start end 
    {
        title: "Start end event",
        start: "2024-03-19T12:00:00+07:00",
        end: "2024-03-20T12:00:00+07:00",
        id: createEventId()
    },
    // Only start
    {
        title: "Start at event",
        start: "2024-03-05T12:00:00+07:00",
        id: createEventId()
    }
]

export const INITIAL_TASKS = [
    // All day - single day
    {
        allDay: true,
        title: "All day - single day event",
        start: "2024-03-11",
        end: null,
        id: 0,
        sheepName:"Đặng Quang Khải",
        place: "",
        content: "",
        guiderName: "",
        zion: "",
        status: "",
        note: ""
    },
    // All day - multiple day
    {
        allDay: true,
        title: "All day - multiple day event",
        start: "2024-03-11",
        end: "2024-03-13",
        id: 1,
        sheepName:"Đặng Quang Khải",
        place: "",
        content: "",
        guiderName: "",
        zion: "",
        status: "",
        note: ""
    },
    // Start end
    {
        allDay: false,
        title: "Start end event",
        start: "2024-03-19T12:00:00+07:00",
        end: "2024-03-20T12:00:00+07:00",
        id: 2,
        sheepName:"Đặng Quang Khải",
        place: "",
        content: "",
        guiderName: "",
        zion: "",
        status: "",
        note: ""
    },
    // Only start
    {
        allDay: false,
        title: "Start at event",
        start: "2024-03-05T12:00:00+07:00",
        end: null,
        id: 3,
        sheepName:"Đặng Quang Khải",
        place: "",
        content: "",
        guiderName: "",
        zion: "",
        status: "",
        note: ""
    }
]

export function createEventId() {
  return String(eventGuid++)
}

export const copyTaskToEvent = (task) => {
    const event = {};
    event["id"] = task.id;
    event["title"] = task.title;
    event["allDay"] = task.allDay;
    event["start"] = task.start;
    event["end"] = task.end;

    return event;
}