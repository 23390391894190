export const headCellsHerdsmanPoint = [
    {
        id: 'rank',
        numeric: false,
        disablePadding: true,
        label: '#',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'herdsmanName',
        numeric: false,
        disablePadding: false,
        label: 'Họ và tên',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    //{
    //    id: 'departmentName',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Biệt đội',
    //    className: 'pt-3 pb-3',
    //    style: { minWidth: "100px" }
    //},
    {
        id: 'zionName',
        numeric: false,
        disablePadding: false,
        label: 'Địa vực',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'nmQuantity',
        numeric: false,
        disablePadding: false,
        label: 'Số lần giáo dục',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'omQuantity',
        numeric: false,
        disablePadding: false,
        label: 'Số lần phát biểu',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'basicPoint',
        numeric: false,
        disablePadding: false,
        label: 'Mục tiêu cơ bản',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'totalPoint',
        numeric: false,
        disablePadding: true,
        label: 'Tổng điểm',
        className: 'pt-3 pb-3',
    },
    //{
    //    id: 'action',
    //    numeric: false,
    //    disablePadding: true,
    //    label: '',
    //    className: 'pt-3 pb-3 pl-4',
    //},
];

//export default headCellsHerdsmanPoint;

export const headCellsHerdsmanPointDaily = [
    {
        id: 'reportDate',
        numeric: false,
        disablePadding: false,
        label: 'Ngày',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'nmSumQuantity',
        numeric: false,
        disablePadding: false,
        label: 'Số lần giáo dục',
        className: 'pt-3 pb-3',
    },
    {
        id: 'omSumQuantity',
        numeric: false,
        disablePadding: false,
        label: 'Số lần phát biểu',
        className: 'pt-3 pb-3',
    },
    {
        id: 'totalPoint',
        numeric: false,
        disablePadding: true,
        label: 'Tổng điểm',
        className: 'pt-3 pb-3',
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
    },
];

export const headCellsZionPoint = [
    {
        id: 'rank',
        numeric: false,
        disablePadding: true,
        label: '#',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'zionName',
        numeric: false,
        disablePadding: false,
        label: 'Địa vực',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'averagePoint',
        numeric: false,
        disablePadding: false,
        label: 'Điểm XH',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'nmQuantity',
        numeric: false,
        disablePadding: false,
        label: 'Số lần giáo dục',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'omQuantity',
        numeric: false,
        disablePadding: false,
        label: 'Số lần phát biểu',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'basicPoint',
        numeric: false,
        disablePadding: false,
        label: 'Mục tiêu cơ bản',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'totalPoint',
        numeric: false,
        disablePadding: true,
        label: 'Tổng điểm',
        className: 'pt-3 pb-3',
    },
];