import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListGroup from './head-cell-list-group';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as groupManagementAction from "../../redux/store-sarang/group/group-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';

import FormAddEditGroup from './components/form-add-edit-group';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function GroupList() {
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids

        // -- End Data to map with ids

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);

    useEffect(() => {
        getListGroupManagement();
    }, []);

    const getListGroupManagement = (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, code="", name = "") => {
        showLoading(true);
        // setPage(pageIndex-1)
        groupManagementAction.GetListGroup(pageIndex, pageSize, sortExpression, code.trim(), name.trim()).then(
            (res) => {
                if (res &&
                    res.content &&
                    res.content.items
                ) {
                    setData(res.content.items)
                    setTotalItemCount(res.content.totalItemCount)
                }
                showLoading(false);
            },
            (err) => {
                showLoading(false);
                err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        );
    }
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListGroupManagement(page + 1, rowsPerPage, sortExpression);
        console.log(sortExpression);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListGroupManagement(newPage, rowsPerPage, sortExpression);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListGroupManagement(1, event.target.value, sortExpression);
    };

    const handleSubmitAddEditSwat = async (data) => {
        showLoading(true);
        
        //let formData = new FormData();
        //let formData = {
            
        //};
        //for (let key in data) {
        //    // false hoặc 0 vẫn append vào form
        //    if(data[key] !== undefined && data[key] !== null && data[key] !== '') formData.append(key, data[key]);
        //}

        try {
            let res = null;

            if( !data.id ) {
                res = await groupManagementAction.CreateGroup(data);
            } else {
                res = await groupManagementAction.UpdateGroup(data);
            }

            if ( res && res.content ) {
                getListGroupManagement();
            }
            showLoading(false);
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setSelectedItem(null);
    }
    const handleConfirm = async () => {
        try {
            let res = null;

            if( isOpenDeleteDialog ) {
                res = await groupManagementAction.DeleteGroup(selectedItem.id);
            } else if (isOpenActiveDialog) {
                res = await groupManagementAction.ActiveGroup(selectedItem.id);
            } else if (isOpenDeactiveDialog) {
                res = await groupManagementAction.DeActiveGroup(selectedItem.id);
            }

            if ( res ) {
                getListGroupManagement();
            }
            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="input-name" className="col-form-label">Tên</label>
                                        <input id="input-name" placeholder="Tên" type="text" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 pl-0">
                                    <p className="text-center">
                                        <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                        <button className="btn btn-space btn-secondary">Xóa lọc</button>
                                        <button
                                            className="btn btn-space btn-warning"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openAddDialog();
                                            }}
                                        >Thêm mới</button>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <DataTableSarang
                    // button functions
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // head cells
                    headCells={headCellsListGroup}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    // pagination
                    totalItemCount={totalItemCount}
                    setRowsPerPage={setRowsPerPage}
                    pageIndex={page}
                    handleChangePage={handleChangePage}
                >
                    <tbody>
                        {
                            data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td><span>{row.name}</span></td>
                                    <td><span>{row.description}</span></td>
                                    <td align="center">
                                        <span>
                                            {row.isActived ? (
                                                <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick" onClick={() => {
                                                        setOpenDeactiveDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                                />
                                            ) : (
                                                <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../assets/icon/cancel.png")}
                                                        alt="Cancel" onClick={() => {
                                                            setOpenActiveDialog(true);
                                                            openConfirmDialog(row);
                                                        }}
                                                />
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Sửa"
                                                onClick={() => {openEditDialog(row)}}
                                            >
                                                <i className="far fa-edit"></i>
                                            </button>
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                title="Xóa"
                                                onClick={() => {
                                                    setOpenDeleteDialog(true);
                                                    openConfirmDialog(row);
                                                }}
                                            >
                                                <i className="far fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                        }
                    </tbody>
                </DataTableSarang>
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>
            <ModalSubmitForm
                title={!selectedItem ? "Thêm mới Bộ" : "Chỉnh sửa Bộ"}
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                <FormAddEditGroup
                    // ===
                    updateItem={selectedItem}
                    onSubmitAddEdit={handleSubmitAddEditSwat}
                />
            </ModalSubmitForm>

            <ModalConfirm 
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa bản ghi này không?":
                    isOpenActiveDialog ? "Bạn có chắc chắn muốn mở khóa bản ghi này không?":
                    isOpenDeactiveDialog ? "Bạn có chắc chắn muốn khóa bản ghi này không?": ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog }
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}