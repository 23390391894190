import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../../core/app.store";
import * as config from '../../../common/config'

import headCellsListTask from './head-cell-list-task';
import DataTableSarang from '../../../components/datatable-sarang';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import * as viVN from "../../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";

import * as taskCalendarManagementAction from "../../../redux/store-sarang/task-calendar/task-calendar-management.store";

import ModalSubmitForm from '../../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import Select from "react-select";
import { optionsCuisineOrderStatus, optionsCustomerStatus, optionsReceiptTime } from '../../../common/constant';
import ModalConfirm from '../../../components/sarang-modals/modal-confirm/modal-confirm';
import FormAddEditTask from '../add-edit-task/form-add-edit-task';
import { UrlCollectionSarang } from '../../../common/url-collection-sarang';
import { Link, useHistory } from 'react-router-dom';
import * as styles from '../../custome-style.module.scss'
import { VIEW_MODE_PARAM } from '..';
import history from "../../../common/history";
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../../components/datatable-sarang/datatable-pagination';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import MobileHeaderSarang from '../../../components/mobile-header/mobile-header-sarang';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "datingTime desc",
    orderBy: "datingTime",
    order: "desc",
}

export default function CalendarListViewMode(props) {
    const { customer, herdsman, herdsmanOwn, handleSubmitAddEditTask } = props;
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));
    const invisibleLinkRef = useRef(null);
    const [herdsmanId, setHerdsmanId] = useState(getUserInfo().herdsmanId);

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);
    const [searchData, setSearchData] = useState();
    const [openSearch, setOpenSearch] = useState(false);
    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();

    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
    const [isOpenChangeConfirmDialog, setOpenChangeConfirmDialog] = useState(false);
    const [isOpenHelpingToMeDialog, setOpenHelpingToMeDialog] = useState(false);

    useEffect(() => {
        getListTaskManagement();
    }, []);

    const getListTaskManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, data=undefined) => {
        showLoading(true);
        try {
            const res = await taskCalendarManagementAction.GetListTask(pageIndex, pageSize, sortExpression, data);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListTaskManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListTaskManagement(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListTaskManagement(1, event.target.value, sortExpression, searchData);
    };
     
    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenChangeStatusDialog(false);
        setOpenChangeConfirmDialog(false);
        setOpenHelpingToMeDialog(false);
        setSelectedItem(null);
    }

    const handleChangeStatus = async (id, status) => {
        showLoading(true);
        try {
            const res = await taskCalendarManagementAction.ChangeStatusTask({id: id, status: status});
            if (res && res.content && res.content.status) {
                ShowNotification(
                    viVN.Success["UpdateSuccess"],
                    NotificationMessageType.Success
                )
                let sortExpression = orderBy + ' ' + order;
                getListTaskManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }
    
    const handleConfirm = async () => {
        try {
            showLoading(true);
            let res = null;
            if( isOpenDeleteDialog ) {
                res = await taskCalendarManagementAction.DeleteTask(selectedItem.id);
            } else if (isOpenActiveDialog) {
                // res = await taskCalendarManagementAction.ActiveCuisineOrder(selectedItem.id);
            } else if (isOpenDeactiveDialog) {
                // res = await taskCalendarManagementAction.DeActiveCuisineOrder(selectedItem.id);
            } else if (isOpenChangeStatusDialog) {
                // res = await taskCalendarManagementAction.ChangeCuisineOrderStatus({ id: selectedItem.id, status: selectedItem.orderStatusChange });
            } else if (isOpenChangeConfirmDialog) {
                // res = await taskCalendarManagementAction.ChangeConfirm({ id: selectedItem.id, herdsmanId: user.herdsmanId }); //selectedItem.herdsManConfirmId
            } else if (isOpenHelpingToMeDialog && herdsmanId) {
                res = await taskCalendarManagementAction.HelpingCreateTask({ calendarId: selectedItem.id, herdsmanId: herdsmanId });
                if (res) {
                    ShowNotification(
                        viVN.Success["UpdateSuccess"],
                        NotificationMessageType.Success
                    )
                }
            }

            if (res) {
                let sortExpression = orderBy + ' ' + order;
                getListTaskManagement(page, rowsPerPage, sortExpression, searchData);
            }

            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    const onSubmit = (data) => {
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order;
        setPage(0);
        getListTaskManagement(1, rowsPerPage, sortExpression, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const renderFormSearch = () => {
        return <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="input-department-general">Swat</label>
                    <Select
                        isClearable
                        {...register("herdsmanId")}
                        placeholder="Swat"
                        onChange={(data) => setValue("herdsmanId", data ? data.value : null)}
                        options={herdsmanOwn.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Ta-lâng</label>
                    <Select
                        isClearable
                        {...register("customerId", {})}
                        placeholder="Chọn Ta-lâng"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("customerId", data ? data.value : null)}
                        options={customer.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không có ta-lâng nào"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label htmlFor="input-address" className="col-form-label">Địa chỉ</label>
                    <input id="input-address" name="address" placeholder="Địa chỉ" type="text" className="form-control" ref={register()} />
                </div>
                <div className="form-group col-md-8">
                    <label htmlFor="input-from-date">Ngày giờ</label>
                    <div className="row">
                        <div className="col-6">
                            <DatePicker
                                {...register("startDate")}
                                onChange={(data) => setValue("startDate", dayjs(data).format())}
                                className="form-control"
                                format='DD/MM/YYYY'
                                slotProps={{ textField: { size: 'small' } }}
                            //label={"Nhập Ngày nhận"}
                            />
                        </div>
                        <div className="col-6">
                            <DatePicker
                                {...register("endDate")}
                                onChange={(data) => setValue("endDate", dayjs(data).format())}
                                className="form-control"
                                format='DD/MM/YYYY'
                                slotProps={{ textField: { size: 'small' } }}
                            //label={"Nhập Ngày nhận"}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <FormSearchFunctionButtons>
                <div className="row">
                    <div className="col-sm-12 pl-0">
                        <p className="text-center">
                            <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                            <button className="btn btn-space btn-secondary">Xóa lọc</button>
                            <button
                                className="btn btn-space btn-warning"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openAddDialog();
                                }}
                            >Thêm mới</button>
                            <button type="submit" className="btn btn-space btn-primary"
                                onClick={() => invisibleLinkRef.current.click()}
                            >Xem chế độ lịch</button>
                            <Link className={`d-none`} ref={invisibleLinkRef}
                                to={`${UrlCollectionSarang.CalendarContact}?${VIEW_MODE_PARAM}=calendar`}>To Calendar</Link>
                        </p>
                    </div>
                </div>
            </FormSearchFunctionButtons>
        </form>
    }
    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <MobileHeaderSarang
                    headCell={headCellsListTask}
                    handleSortChange={(sortExpression) => getListTaskManagement(page + 1, rowsPerPage, sortExpression, searchData)}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                />
                <div id="accordion">
                    {/*
                         <div className="card">
                        <div className="d-flex align-items-center">
                            <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                data-toggle="collapse" aria-expanded="false" >
                                <div className="d-flex w-100">
                                    <label className='d-flex align-items-end justify-content-end' style={{ gap: "10px" }}>
                                        Xem
                                        <select
                                            className="custom-select custom-select-sm form-control form-control-sm"
                                            style={{ width: "50px" }}
                                            value={rowsPerPage}
                                            onChange={handleChangeRowsPerPage}
                                        >
                                            {config.Configs.DefaultPageSizeOption.map((pageSize, index) => (
                                                <option key={index} value={pageSize}>{pageSize}</option>
                                            ))}
                                        </select>
                                        mục
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                        */ }
                    {
                        data.length > 0 ?
                            data.map((item, index) => (
                                <div className="card" key={index}>
                                    <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start collapsed"
                                            data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}
                                            style={{ border: "none", backgroundColor: item.status===100 ? 'lightgreen' : item.status==10 ? 'yellow' : 'red'}}
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{dayjs(item.datingTime).format("DD/MM/YYYY HH:mm")} - {item.title} </h5>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='mt-0'>
                                                        <b>Swat: </b>
                                                        <span>
                                                            {item.herdsmanName}
                                                        </span>
                                                    </div>
                                                    <div className="mt-0"><b>Cần trợ giúp?</b>&nbsp;
                                                        <span>
                                                            {item.needHelp ? (
                                                                <img title="Cần trợ giúp"
                                                                    src={require("../../../assets/icon/tick.png")}
                                                                    alt="Tick"
                                                                />
                                                            ) : (
                                                                <img title=""
                                                                    src={require("../../../assets/icon/cancel.png")}
                                                                    alt="Cancel"
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='mt-2'><b>Ta-lâng: </b>
                                                        <span>
                                                            <b>{item.customerGender === "Nam" ? <li className="fas fa-male text-primary" title="Nam"></li> : <li className="fas fa-female text-secondary" title="Nữ"></li>}&nbsp;
                                                                {item.customerName}</b> ({item.customerYearOfBirth})
                                                        </span>
                                                    </div>
                                                    <div className='mt-2'><b>Ban: </b>
                                                        <span>
                                                            {item.customerDepartmentName}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-4'>
                                                    Trạng thái:
                                                </div>
                                                <div className='col-8'>
                                                    <div className="mt-0">
                                                        {
                                                            item.allowChangeStatus ? (<select className="form-control" name="gender" ref={register()} defaultValue={item?.status} onChange={(e) => { handleChangeStatus(item.id, e.target.value) }}>
                                                                <option value="10">Mới tạo</option>
                                                                <option value="100">Hoàn thành</option>
                                                                <option value="200">Hủy</option>
                                                            </select>) :
                                                                (<>
                                                                    {
                                                                        item.status === 100 ? <>Hoàn thành</> : item.status === 10 ? <>Mới tạo</> : <>Hủy</>
                                                                    }
                                                                </>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <i className="fas fa-angle-down p-2"></i>
                                    </div>
                                    <div id={`collapse-${item.id}`} className="collapse" aria-labelledby={`heading-${item.id}`} data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                        <b>BP giúp đỡ: </b>
                                                        <span>
                                                            {item?.departmentHelpingId ? <>
                                                                <b>Ban: </b>{item?.departmentHelpingName}</> : <></>
                                                            }
                                                            {
                                                            item?.herdsmanHelpingId1 ? <><br />- {item?.herdsmanHelpingName1} (<a href={`tel:${item?.herdsmanHelpingPhone1}`}>{item?.herdsmanHelpingPhone1}</a>)</> : <></>
                                                            }
                                                            {
                                                            item?.herdsmanHelpingId2 ? <><br />- {item?.herdsmanHelpingName2} (<a href={`tel:${item?.herdsmanHelpingPhone1}`}>{item?.herdsmanHelpingPhone1}</a>)</> : <></>
                                                            }
                                                        </span>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mt-0'><b>Địa điểm tiếp xúc: </b>
                                                        <span>{item.address}</span></div>
                                                </div>
                                            </div>
                                            
                                            <div className='mt-0'><b>Ghi chú:</b></div>
                                            <span>{item.description}</span>
                                            {
                                                item.allowEdit ? 
                                                    <div className='d-flex mt-2'>
                                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                            title="Sửa"
                                                            onClick={() => { openEditDialog(item) }}
                                                        >
                                                            <i className="far fa-edit"></i>
                                                        </button>
                                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                            title="Xóa"
                                                            onClick={() => {
                                                                setOpenDeleteDialog(true);
                                                                openConfirmDialog(item);
                                                            }}
                                                        >
                                                            <i className="far fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                    : <></>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                        <DataTablePagination
                            totalItemCount={totalItemCount}
                            rowsPerPage={rowsPerPage}
                            pageIndex={page + 1}
                            handleChangePage={handleChangePage}
                        /> :
                        <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsListTask}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}>
                                        <span>
                                            {row.needHelp ? (
                                                <img title="Cần trợ giúp"
                                                    src={require("../../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : (
                                                <img title=""
                                                    src={require("../../../assets/icon/cancel.png")}
                                                    alt="Cancel"
                                                />
                                            )}
                                        </span>
                                    </td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}><span>{dayjs(row.datingTime).format("DD/MM/YYYY HH:mm")}</span></td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}><span><b>{row.customerGender === "Nam" ? <li className="fas fa-male text-primary" title="Nam"></li> : <li className="fas fa-female text-secondary" title="Nữ"></li>}&nbsp;{row.customerName}</b> ({row.customerYearOfBirth})<br />Ban: <b>{row.customerDepartmentName}</b></span></td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}><span>{row.herdsmanName}</span></td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}><span>
                                        {row?.departmentHelpingId ? <>
                                            <b>Ban: </b>{row?.departmentHelpingName}</> : <></>
                                        }
                                        {
                                            row?.herdsmanHelpingId1 ? <><br />- {row?.herdsmanHelpingName1} (<a href={`tel:${row?.herdsmanHelpingPhone1}`}>{row?.herdsmanHelpingPhone1}</a>)</> : <></>
                                        }
                                        {
                                            row?.herdsmanHelpingId2 ? <><br />- {row?.herdsmanHelpingName2} (<a href={`tel:${row?.herdsmanHelpingPhone2}`}>{row?.herdsmanHelpingPhone2}</a>)</> : <></>
                                        }
                                    </span></td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}>
                                        {
                                            row.allowChangeStatus ? (<select className="form-control" name="gender" ref={register()} defaultValue={row?.status} onChange={(e) => { handleChangeStatus(row.id, e.target.value) }}>
                                                <option value="10">Mới tạo</option>
                                                <option value="100">Hoàn thành</option>
                                                <option value="200">Hủy</option>
                                            </select>) :
                                                (<>
                                                    {
                                                        row.status === 100 ? <>Hoàn thành</> : row.status === 10 ? <>Mới tạo</> : <>Hủy</>
                                                    }
                                                </>)
                                        }
                                        
                                    </td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}><span>{row.address}</span></td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}><span>{row.title}</span></td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}><span>{row.description}</span></td>
                                    <td style={row.status === 100 ? { backgroundColor: 'lightgreen' } : row.status === 10 ? { backgroundColor: 'yellow' } : { backgroundColor: 'red' }}>
                                        {
                                            row.allowEdit ?
                                                <div className='d-flex'>
                                                    <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                        title="Sửa"
                                                        onClick={() => { openEditDialog(row) }}
                                                    >
                                                        <i className="far fa-edit"></i>
                                                    </button>
                                                    <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                        title="Xóa"
                                                        onClick={() => {
                                                            setOpenDeleteDialog(true);
                                                            openConfirmDialog(row);
                                                        }}
                                                    >
                                                        <i className="far fa-trash-alt"></i>
                                                    </button>
                                                </div> :
                                                <></>
                                        }
                                    </td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>

            <ModalSubmitForm
                title={!selectedItem ? "Tạo lịch hẹn mới" : "Chỉnh sửa lịch hẹn"}
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                <FormAddEditTask
                    customer={customer}
                    herdsman={herdsman}
                    herdsmanOwn={herdsmanOwn}
                    // ===
                    updateItem={selectedItem}
                    handleSubmitAddEditTask={(data) => handleSubmitAddEditTask(data, getListTaskManagement)}
                />
            </ModalSubmitForm>

            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Ae/Ce có chắc chắn muốn xóa không?":
                    isOpenActiveDialog ? "Ae/Ce có chắc chắn muốn xác nhận không?":
                    isOpenDeactiveDialog ? "Ae/Ce có chắc chắn muốn hủy xác nhận không?": 
                    isOpenChangeStatusDialog ? `Ae/Ce có chắc chắn muốn thay đổi trạng thái thành <b>"${selectedItem?.orderStatusNameChange}"</b>?`:
                    isOpenChangeConfirmDialog ? `Ae/Ce có chắc chắn muốn bản ghi đăng ký suất này ăn được xác nhận bởi a/c/e <b>"${selectedItem?.herdsManConfirmName}"</b>?` :
                    isOpenHelpingToMeDialog ? `Ae/Ce có chắc chắn muốn hỗ trợ chia sẻ cho trường hợp này?"</b>?` : ""
                }
                open={isOpenDeleteDialog ||
                    isOpenActiveDialog ||
                    isOpenDeactiveDialog  ||
                    isOpenChangeStatusDialog ||
                    isOpenChangeConfirmDialog ||
                    isOpenHelpingToMeDialog
                }
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}