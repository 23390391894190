import React, { useEffect, useState } from 'react';
import * as examinationAction from "../../../redux/store-sarang/examination-report/examination-report-management.store";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
import MyDepartmentExaminationWidget from './my-department-examination-widget'
import ListHerdsmanRankWidget from './list-herdsman-rank-widget'
import ListDepartmentRankWidget from './list-department-rank-widget'
export default function MyExaminationWidget(props) {
    const { userInfo, week } = props;

    const [myRank, setMyRank] = useState();
    const [myPointThreeMonth, setMyPointThreeMonth] = useState();
    const [myDepartmentRank, setMyDepartmentRank] = useState();
    const [listHerdsmanRank, setListHerdsmanRank] = useState([]);
    const [listHerdsmanPointThreeMonth, setListHerdsmanPointThreeMonth] = useState([]);
    const [listDepartmentRank, setListDepartmentRank] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSwat, setLoadingSwat] = useState(false);

    useEffect(() => {
        if (userInfo && week)
            GetMyExaminationRank();
    }, [userInfo, week]);

    const GetMyExaminationRank = async () => {
        setLoading(true);
        setLoadingSwat(true);
        try {
            const res = await examinationAction.GetMyExaminationRank(week?.week, week?.month, week?.year);

            if (res && res.content) {
                setMyRank(res.content.myRank);
                setMyPointThreeMonth(res.content.myPointThreeMonth);
                setMyDepartmentRank(res.content.myDepartmentRank);
                setListHerdsmanRank(res.content.listHerdsmanRank);
                setListHerdsmanPointThreeMonth(res.content.listHerdsmanPointThreeMonth)
                setListDepartmentRank(res.content.listDepartmentRank);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
            setLoadingSwat(false);
        }
    };





    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card">
                        <h5 className="card-header">Cá nhân (tuần {week?.week}): {myRank?.herdsmanName}</h5>
                        <div className="row card-body text-center">
                            <div className="col-12">
                                <span>Điểm: <b>{Math.round((myRank?.totalPoint) * 100) / 100}</b></span>&nbsp;
                                <span>Xếp hạng: <b>{myRank?.rank}</b></span>
                            </div>
                            <div className="col-12">
                                <table className="table dataTable" id="datatable">
                                    <thead>
                                        <tr>
                                            <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                            <td style={{ minWidth: "50px" }}><b>#</b></td>
                                            <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            myRank?.rP_Examination_Report_Dtos && myRank?.rP_Examination_Report_Dtos.length > 0 && myRank?.rP_Examination_Report_Dtos.map((item, index) =>
                                                <tr>
                                                    <td>{optionsExamination.find(x => x.value == item.status)?.label}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{Math.round((item.quantity * item.point) * 100) / 100}</td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <LoadingViewAria loading={loading} />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card">
                        <h5 className="card-header">Điểm tích lũy cá nhân 3 tháng gần nhất</h5>
                        <div className="row card-body text-center">
                            <div className="col-12">
                                <span>Điểm: <b>{Math.round((myPointThreeMonth?.totalPoint) * 100) / 100}</b></span>&nbsp;
                            </div>
                            <div className="col-12">
                                <table className="table dataTable" id="datatable">
                                    <thead>
                                        <tr>
                                            <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                            <td style={{ minWidth: "50px" }}><b>#</b></td>
                                            <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            myPointThreeMonth?.rP_Examination_Report_Dtos && myPointThreeMonth?.rP_Examination_Report_Dtos.length > 0 && myPointThreeMonth?.rP_Examination_Report_Dtos.map((item, index) =>
                                                <tr>
                                                    <td>{optionsExamination.find(x => x.value == item.status)?.label}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{Math.round((item.quantity * item.point) * 100) / 100}</td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <LoadingViewAria loading={loading} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <MyDepartmentExaminationWidget
                        myDepartmentRank={myDepartmentRank}
                        week={week}
                        loading={loading}
                    />
                </div>
                <ListHerdsmanRankWidget
                    listHerdsmanRank={listHerdsmanPointThreeMonth}
                    loading={loading}
                />
            </div>
            <div className="row">
                <ListHerdsmanRankWidget
                    listHerdsmanRank={listHerdsmanRank}
                    week={week}
                    loading={loading }
                />
                <ListDepartmentRankWidget
                    listDepartmentRank={listDepartmentRank}
                    week={week}
                    loading={loading }
                />
            </div>
        </>   
    )
}