const fakeDataPotentialSheep = [
    {
        numberOfSheep: 1,
        type: "potential",
        createAt: 1705215900000
    },
    {
        numberOfSheep: 2,
        type: "potential",
        createAt: 1705302300000
    },
    {
        numberOfSheep: 4,
        type: "potential",
        createAt: 1705388700000
    },
    {
        numberOfSheep: 7,
        type: "potential",
        createAt: 1705475100000
    },
    {
        numberOfSheep: 3,
        type: "potential",
        createAt: 1705561500000
    },
    {
        numberOfSheep: 2,
        type: "potential",
        createAt: 1705647900000
    },
    {
        numberOfSheep: 5,
        type: "potential",
        createAt: 1705734300000
    },
]

export default fakeDataPotentialSheep;