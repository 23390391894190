import React, { useEffect, useState } from 'react';
import * as transportAction from "../../../redux/store-sarang/transport-order/transport-order-management.store";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
export default function DeliveryRankCupWidget(props) {
    const { userInfo, week, limit } = props;

    const [loading, setLoading] = useState(false);
    const [lisDeliveryRank, setLisDeliveryRank] = useState([]);
    const month = new Date().getDate() <= 5 ? new Date().getMonth() : new Date().getMonth()+1;

    useEffect(() => {
        DeliveryRank()
    }, [week]);

    const DeliveryRank = async () => {
        setLoading(true);
        try {
            const res = await transportAction.DeliveryRank(week?.fromDate, week?.toDate);

            if (res && res.content) {
                setLisDeliveryRank(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="card">
                <h5 className="card-header">Xếp hạng giao vận ({week ? <>tuần {week?.week}</> : <>tháng {month }</> })</h5>
                <div className="card-body">
                    <div className="row text-center">
                        <div className="col-4">
                            {
                                lisDeliveryRank.length > 1 &&
                                <div>
                                    <img src="assets/images/rank/rank_group_2.jpg" style={{width: "40px", paddingTop:"10px"}} />
                                        <div style={{ marginTop: "10px", fontWeight: 700 } }>{
                                            lisDeliveryRank[1].herdsmanName
                                    }</div>
                                        <div style={{ fontWeight: 900, color:"#0867c3" }}>{
                                            lisDeliveryRank[1].deliveryCount
                                    }</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                
                                lisDeliveryRank.length > 0 &&
                                <div>
                                    <img src="assets/images/rank/rank_group_1.png" style={{width: "50px"}}  />
                                    <div style={{marginTop: "10px", fontWeight:700} }>{
                                            lisDeliveryRank[0].herdsmanName
                                    }</div>
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                            lisDeliveryRank[0].deliveryCount
                                    }</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                lisDeliveryRank.length > 2 &&
                                <div>
                                    <img src="assets/images/rank/rank_group_3.jpg" style={{width: "35px", paddingTop:"15px"}}  />
                                        <div style={{ marginTop: "10px", fontWeight: 700 } }>{
                                            lisDeliveryRank[2].herdsmanName
                                    }</div>
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                            lisDeliveryRank[2].deliveryCount
                                    }</div>
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className="row text-center">
                        <table className="table dataTable table-hover " id="datatable">
                            <thead>
                                <tr>
                                    <td style={{ minWidth: "50px" }}><b>Hạng</b></td>
                                    <td style={{ minWidth: "100px" }}><b>Swat</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lisDeliveryRank.map((item, index) => 
                                        index > 2 && index <= (limit ? limit - 1 : 9) &&
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{item.herdsmanName}</td>
                                                <td>{item.deliveryCount}</td>
                                            </tr>
                                    
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <LoadingViewAria loading={loading} />
            </div>
        </>
    )
}