import React, { useEffect, useState } from 'react';
import * as examinationAction from "../../../redux/store-sarang/examination-report/examination-report-management.store";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
export default function ListDepartmentRankWidget(props) {
    const { listDepartmentRank, loading, userInfo, week } = props;

    const [loadingSwat, setLoadingSwat] = useState(loading);
    const [showIndexDept, setShowIndexDept] = useState(-1);
    const [departmentData, setDepartmentData] = useState();

    useEffect(() => {

    }, []);

    const GetDepartmentExaminationReport = async (departmentId) => {
        setLoadingSwat(true);
        try {
            const res = await examinationAction.GetDepartmentExaminationReport(departmentId, week?.month, week?.year, week?.week);

            if (res && res.content) {
                setDepartmentData(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoadingSwat(false);
        }
    };

    return (
        <>
            {
                listDepartmentRank && listDepartmentRank.length > 0 &&
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card">
                        <h5 className="card-header">Xếp hạng đội (tuần {week?.week})</h5>
                        <div className="row card-body text-center">
                            <table className="table dataTable table-hover " id="datatable">
                                <thead>
                                    <tr>
                                        <td style={{ minWidth: "50px" }}><b>Hạng</b></td>
                                        <td style={{ minWidth: "100px" }}><b>Đội</b></td>
                                        <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listDepartmentRank.map((item, index) =>
                                            <>
                                                <tr style={{ cursor: "pointer", fontWeight: "700" }} onClick={() => {
                                                    if (index == showIndexDept)
                                                        setShowIndexDept(-1);
                                                    else
                                                        setShowIndexDept(index);
                                                    setDepartmentData();
                                                    GetDepartmentExaminationReport(item.departmentId);
                                                }}>
                                                    <td>{item.rank}</td>
                                                    <td>{item.departmentName}</td>
                                                    <td>{Math.round(item.totalPoint*100)/100}</td>
                                                </tr>
                                                {
                                                    departmentData?.rP_Examination_Report_Dtos && departmentData?.rP_Examination_Report_Dtos.length > 0 &&
                                                    <tr style={{ display: showIndexDept == index ? "contents" : "none" }}>
                                                        <td colSpan={3}>
                                                            <table className="table dataTable">
                                                                <thead>
                                                                    <tr >
                                                                        <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                                                        <td style={{ minWidth: "50px" }}><b>#</b></td>
                                                                        <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        departmentData?.rP_Examination_Report_Dtos.map((item, index) =>
                                                                            <tr>
                                                                                <td>{optionsExamination.find(x => x.value == item.status)?.label}</td>
                                                                                <td>{item.quantity}</td>
                                                                                <td>{item.quantity * item.point}</td>
                                                                            </tr>)
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }

                                            </>)
                                    }
                                </tbody>
                            </table>
                        </div>

                        <LoadingViewAria loading={loadingSwat} />
                    </div>
                </div>
            }
        </>
    )
}