import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'
import Select from "react-select";
import Popover from '@mui/material/Popover';

import headCellsListHerdsmanThreeMonth from './head-cell-list-herdsman-three-month';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo, sendReactGaEvent } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as feedbackManagementAction from "../../redux/store-sarang/feedback/feedback-management.store";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";
import * as examinationReportManagementAction from "../../redux/store-sarang/examination-report/examination-report-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import ModalAlert from '../../components/sarang-modals/modal-alert/modal-alert';

import FormAddEditHerdsman from './components/form-add-edit-herdsman';
import FormSendMessage from './components/form-send-message';
import DepartmentHelper from '../list-department/helper/department-helper';
import cleanAccents from '../../common/replace';
import DepartmentTreeSelect from '../list-department/components/department-tree-select';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';
import { changeAlias } from '../../utils/configuration';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function HerdsmanListPointThreeMonth() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids
    const userInfo = getUserInfo();
    const [department, setDepartment] = useState([]);
    const [herdsman, setHerdsman] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [user, setUser] = useState();
    const [searchData, setSearchData] = useState();
    const [openSearch, setOpenSearch] = useState(false);

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);

    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const buttonOpenSendMessageRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [isOpenDowngradeDialog, setOpenDowngradeDialog] = useState(false);
    const [isOpenCreateAccountDialog, setOpenCreateAccountDialog] = useState(false);
    const [isOpenAlertUserName, setOpenAlertUserName] = useState(false);
    const [isOpenSendMessage, setOpenSendMessage] = useState(false);

    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                setHerdsman(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    };
    
    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };
    
   
   
    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupDepartment(),
                getLookupHerdsman()
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }

    // -- End Data to map with ids

    useEffect(() => {
        getListHerdsmanManagement();
        fetchData();
    }, []);

    const getListHerdsmanManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, searchData=undefined) => {
        showLoading(true);
        try {
            const res = await examinationReportManagementAction.GetPointThreeMonth(searchData?.herdsmanId, searchData?.departmentId, pageIndex, pageSize);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };
    
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        getListHerdsmanManagement(newPage, rowsPerPage, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getListHerdsmanManagement(1, event.target.value, searchData);
    };

    const handleSubmitSendMessage = async (data) => {
        showLoading(true);

        let formData = new FormData();
        //console.log(data);
        for (let key in data) {
            // false hoặc 0 vẫn append vào form
            if (data[key] !== undefined && data[key] !== null && data[key] !== '') formData.append(key, data[key]);
        }

        try {
            let res = null;
            res = await feedbackManagementAction.SendMessageToHerdsman(formData);
            if (res) {
                ShowNotification(
                    viVN.Success["SendMessSuccess"],
                    NotificationMessageType.Success
                );
            }
            showLoading(false);
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        }
    }
     
    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const openSendMessageDialog = (item) => {
        setOpenSendMessage(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeSendMessageDialog = (item) => {
        setOpenSendMessage(false);
        setSelectedItem(null);
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenUpgradeDialog(false);
        setOpenDowngradeDialog(false);
        setOpenCreateAccountDialog(false);
        setOpenAlertUserName(false);
        setSelectedItem(null);
    }

    const closeAlertDialog = () => {
        setOpenAlertUserName(false);
    }

    const handleCopy = async () => {
        if (user) {
            navigator.clipboard.writeText(user.user + "/" + user.pwd);
            ShowNotification(
                "Đã copy",
                NotificationMessageType.Success
            )
        }
        else
            ShowNotification(
                "không tồn tại",
                NotificationMessageType.Warning
            )
        
    }
    const handleConfirm = async () => {
        try {
            let userName = null;
            let res = null;

            if( isOpenDeleteDialog ) {
                res = await herdsmanManagementAction.DeleteHerdsman(selectedItem.id);
            } else if (isOpenActiveDialog) {
                res = await herdsmanManagementAction.ActiveHerdsman(selectedItem.id);
            } else if (isOpenDeactiveDialog) {
                res = await herdsmanManagementAction.DeActiveHerdsman(selectedItem.id);
            } else if (isOpenUpgradeDialog) {
                res = await herdsmanManagementAction.UpgradeToSwatHerdsman(selectedItem.id);
            } else if (isOpenDowngradeDialog) {
                res = await herdsmanManagementAction.DowngradeToTDHerdsman(selectedItem.id);
            }

            showLoading(false);
            closeConfirmDialog();

            if (res) {
                getListHerdsmanManagement(page + 1, rowsPerPage, searchData);
            }
            
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    const onSubmit = (data) => {
        setSearchData(data);
        setPage(0);
        getListHerdsmanManagement(1, rowsPerPage, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const handleRequestSort = (event, property) => {
        //const isAsc = orderBy === property && order === 'asc';
        //setOrder(isAsc ? 'desc' : 'asc');
        //setOrderBy(property);
        //let sort = isAsc ? 'desc' : 'asc';
        //let sortExpression = property + ' ' + sort;
        //getListHerdsmanManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };

    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog} hideNewButton={true }
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                handleRequestSort={handleRequestSort}
                headCells={headCellsListHerdsmanThreeMonth}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                        data.map((row, rowIndex) => (
                            <tr key={rowIndex} style={row.isCaptain ? { backgroundColor: 'lightgrey' } : {}}>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? {fontWeight:700} : {} }
                                ><span>{`${row["firstName"]} ${row["lastName"]}`}</span>
                                    {userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) && row.chatTelegramId &&
                                    <span>&nbsp;<i className="fab fa-telegram-plane mr-2 text-info" title="Gửi tin nhắn" style={{ cursor: 'pointer' }} onClick={() => {
                                        setOpenSendMessage(true);
                                        openSendMessageDialog(row);
                                    } }></i></span>}
                                </td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}>
                                    <span>SWAT</span>&nbsp;
                                    <span>{!row.isCaptain && (
                                        <img title="Hạ cấp" style={{ cursor: 'pointer', width: '15px' }}
                                            src={require("../../assets/icon/downgrade.png")}
                                        alt="Tick" onClick={() => {
                                            setOpenDowngradeDialog(true);
                                            openConfirmDialog(row);
                                        }}
                                        />)}</span>
                                </td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span>{row.departmentName}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.pointPrevThreeMonth}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.customerPrevThreeMonth}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.pointNowThreeMonth}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.customerNowThreeMonth}</span></td>
                            </tr>
                        )) :
                        <tr><td colSpan={7}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <div id="accordion">
                    <div className="card">
                        <div className="d-flex align-items-center">
                            <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                data-toggle="collapse" aria-expanded="false" >
                                <div className="d-flex w-100">
                                    <label className='d-flex align-items-end justify-content-end' style={{ gap: "10px" }}>
                                        Xem
                                        <select
                                            className="custom-select custom-select-sm form-control form-control-sm"
                                            style={{ width: "50px" }}
                                            value={rowsPerPage}
                                            onChange={handleChangeRowsPerPage}
                                        >
                                            {config.Configs.DefaultPageSizeOption.map((pageSize, index) => (
                                                <option key={index} value={pageSize}>{pageSize}</option>
                                            ))}
                                        </select>
                                        mục
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data.length > 0 ?
                        data.map((row, index) => (
                            <div className="card" key={index}>
                                <div className="d-flex align-items-center" id={`heading-${row.herdsmanId}`}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{border: "none"}}
                                        data-toggle="collapse" data-target={`#collapse-${row.herdsmanId}`} aria-expanded="false" aria-controls={`collapse-${row.herdsmanId}`}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{`${row["firstName"]} ${row["lastName"]}`}</h5>
                                            <small className='d-flex align-items-center'>
                                                (SWAT)&nbsp;
                                                {!row.isCaptain && (<img title="Hạ cấp" style={{ cursor: 'pointer', width: '15px' }}
                                                    src={require("../../assets/icon/downgrade.png")}
                                                    alt="Tick" onClick={() => {
                                                        setOpenDowngradeDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                                />)}
                                            </small>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='mt-0'><b>Biệt đội: </b><span>{row.departmentName}</span></div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='mt-0'>
                                                    Điểm 3 tháng {`${new Date().getMonth() - 2}, ${new Date().getMonth() - 1}, ${new Date().getMonth()}`}:&nbsp;
                                                    <span><b>
                                                        {row.pointPrevThreeMonth}
                                                        </b>
                                                    </span>
                                                </div>
                                                <div className='mt-0'>
                                                    SL Chiên 3 tháng {`${new Date().getMonth() - 2}, ${new Date().getMonth() - 1}, ${new Date().getMonth()}`}:&nbsp;
                                                    <span><b>
                                                        {row.customerPrevThreeMonth}</b>
                                                    </span>
                                                </div>
                                                <div className='mt-0'>
                                                    Điểm 3 tháng {`${new Date().getMonth() - 1}, ${new Date().getMonth()}, ${new Date().getMonth() + 1}`}: &nbsp;
                                                    <span><b>
                                                        {row.pointNowThreeMonth}</b>
                                                    </span>
                                                </div>
                                                <div className='mt-0'>
                                                    SL Chiên 3 tháng {`${new Date().getMonth() - 1}, ${new Date().getMonth()}, ${new Date().getMonth() + 1}`}:&nbsp;
                                                    <span><b>
                                                        {row.customerNowThreeMonth}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<i className="fas fa-angle-down p-2"></i>*/}
                                </div>
                            </div>
                        )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                    <DataTablePagination
                        totalItemCount={totalItemCount}
                        rowsPerPage={rowsPerPage}
                        pageIndex={page + 1}
                        handleChangePage={handleChangePage}
                    /> :
                    <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog} hideNewButton={true }
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderFormSearch = () => {
        return <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                   
                    {department ? <div className="form-group col-md-6 col-sm-6">
                        <label>Ban/ đội/ nhóm</label>
                        <DepartmentTreeSelect
                            isClearable
                            {...register("departmentId", {})}
                            onChange={(data) => setValue("departmentId", data ? data.value : null)}
                            placeholder="Chọn Ban"
                            options={DepartmentHelper.RenderOptions(department)}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                        :
                        <></>
                    }
                    {herdsman && (
                        <div className="form-group col-md-6 col-sm-6">
                            <label>Ae/Ce chăm sóc</label>
                            <Select
                                isClearable
                                {...register("herdsmanId")}
                                placeholder="Chọn Swat"
                                closeMenuOnSelect={true}
                                onChange={(data) => {
                                    setValue("herdsmanId", data ? data.value : null);
                                }}
                                options={herdsman}
                                noOptionsMessage={() => "Không tồn tại"}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                    )}
                   

                </div>
                <FormSearchFunctionButtons>
                    <div className="row">
                        <div className="col-sm-12 pl-0">
                            <p className="text-center">
                                <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                <button className="btn btn-space btn-secondary">Xóa lọc</button>
                            </p>
                        </div>
                    </div>
                </FormSearchFunctionButtons>
            </form>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>
            <ModalSubmitForm
                title={isOpenSendMessage ? "Nhắn tin cho swat" : (!selectedItem ? "Thêm mới swat" : "Chỉnh sửa swat")}
                open={isOpenAddEditDialog || isOpenSendMessage}
                onClose={!isOpenSendMessage ? closeAddEditDialog : closeSendMessageDialog}
            >
                {
                    !isOpenSendMessage ?
                        <FormAddEditHerdsman
                            // department={department}
                            department={DepartmentHelper.RenderOptions(department)}
                            herdsman={herdsman}
                            // ===
                            updateItem={selectedItem}
                        /> :
                        <FormSendMessage
                            // ===
                            herdsman={selectedItem}
                            onSubmitSendMessage={handleSubmitSendMessage}
                        />
                }
            </ModalSubmitForm>

            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Ae/Ce có chắc chắn muốn xóa bản ghi này không?" :
                        isOpenActiveDialog ? "Ae/Ce có chắc chắn muốn mở khóa bản ghi này không?" :
                            isOpenUpgradeDialog ? "Ae/Ce có chắc chắn muốn nâng cấp AE/CE này lên SWAT không?" :
                                isOpenDowngradeDialog ? "Khi hạ cấp xuống Thánh đồ, toàn bộ dữ liệu ta-lâng sẽ bị xóa và không khôi phục được. \n\rAe/Ce có chắc chắn muốn hạ cấp AE/CE này xuống Thánh Đồ không?" :
                                    isOpenDeactiveDialog ? "Ae/Ce có chắc chắn muốn khóa bản ghi này không?" :
                                        isOpenCreateAccountDialog ? "Ae/Ce có chắc chắn muốn tạo tài khoản cho SWAT này không?" : ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog || isOpenCreateAccountDialog || isOpenUpgradeDialog || isOpenDowngradeDialog}
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
            <ModalAlert
                title={"Thông báo"}
                prompt={
                    isOpenAlertUserName ? "Ae/Ce vui lòng gửi tài khoản cho Ace vừa tạo là {" + user?.user + "/" + user?.pwd + "}" :
                        ""
                }
                open={isOpenAlertUserName}
                onClose={closeAlertDialog}
                onCopy={handleCopy}
            />
        </div>
        
    )
}