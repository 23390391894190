const headCellsListTaskNeedHelp = [
        {
            id: 'datingTime',
            numeric: false,
            disablePadding: false,
            label: 'Ngày giờ hẹn',
            className: 'pt-3 pb-3',
            style: { minWidth: "100px" }
        },
        {
            id: 'customerName',
            numeric: false,
            disablePadding: false,
            label: 'Ta-lâng',
            className: 'pt-3 pb-3',
            style: { minWidth: "120px" }
        },
        {
            id: 'needHelp',
            numeric: false,
            disablePadding: true,
            label: 'Cần giúp đỡ',
            className: 'pt-3 pb-3',
            style: { minWidth: "80px" }
        },
        {
            id: 'helpingCount',
            numeric: false,
            disablePadding: true,
            label: 'Ace giúp đỡ',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
        
        {
            id: 'Herdsman.LastName',
            numeric: false,
            disablePadding: false,
            label: 'Swat',
            className: 'pt-3 pb-3',
            style: { minWidth: "90px" }
        },
        {
            id: 'address',
            numeric: false,
            disablePadding: true,
            label: 'Địa điểm tiếp xúc',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: 'Chủ đề tiếp xúc',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
        {
            id: 'content',
            numeric: false,
            disablePadding: true,
            label: 'Nội dung tiếp xúc',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: 'Ghi chú',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
];

export default headCellsListTaskNeedHelp;