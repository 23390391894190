import React, { useEffect, useState, useRef } from 'react';
import { getUserInfo, ShowXepHang } from '../../utils/configuration';
import ReactPlayer from 'react-player/lazy';
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import MyExaminationWidget from './components/my-examination-widget';
import HerdsmanRankCupWidget from './components/herdsman-rank-cup-widget';
import SwatTrundoHerdsmanRankCupWidget from './swat-trundo/swat-trundo-herdsman-rank-cup-widget';
import SwatTrundoDepartmentRankCupWidget from './swat-trundo/swat-trundo-department-rank-cup-widget';
import SwatTrundoZionAreaRankCupWidget from './swat-trundo/swat-trundo-zion-area-rank-cup-widget';
import HerdsmanNiniveRankCupWidget from './components/herdsman-ninive-rank-cup-widget';
import DepartmentRankCupWidget from './components/department-rank-cup-widget';
import DeliveryRankCupWidget from './components/delivery-rank-cup-widget';
;export default function ExamDashboard() {
    //var userRole: string;
    const userInfo = getUserInfo();
    const [week, setWeek] = useState();
    const dayOfWeek = new Date().getDay();
    const hour = new Date().getHours();
    const limit = 10;
    useEffect(() => {
        //if (!(ShowXepHang ||
        //    (userInfo && userInfo.email === "tranvanhao@gmail.com") ||
        //    (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")))))

            if (!ShowXepHang)
            window.location.replace("/");

        //setTimeout(() => {
        //    const elements = document.getElementsByClassName("highcharts-credits");
        //    const elementsArray = Array.from(elements);

        //    elementsArray.forEach(element => {
        //        element.style.display = "none";
        //    });
        //}, 100);
        GetCurrentWeek();
    }, [])

    const GetCurrentWeek = async () => {
        try {
             const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
            }
        } catch (err) {
            ;
            
        } finally {
            ;
        }
    };

    return (
        <>
            {/* Tạm thời tắt và bật sau*/
                //(userInfo && userInfo.email === "tranvanhao@gmail.com") ||
                //(userInfo && userInfo.userRoles &&
                //    (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN") || (dayOfWeek == 6 && hour >= 6))) ?
                <>
                
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <HerdsmanRankCupWidget
                            week={week}
                            limit={limit}
                        />
                    </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                        <HerdsmanRankCupWidget
                            week={{ ...week, month: new Date().getDate() <= 5 ? new Date().getMonth() : new Date().getMonth() + 1, week: undefined }}
                            limit={limit}
                        />
                        
                    </div>
                </div>
                </>
                //<HerdsmanNiniveRankCupWidget
                //    week={{ ...week, month: new Date().getMonth() < 8 ? 8 : new Date().getMonth(), week: undefined }}
                //    limit={limit}
                ///>

                //    :
                //<div className="card card-body">
                //        <div className="text-danger text-center"><b>Kết quả xếp hạng tuần sẽ hiển thị vào thứ 7 hàng tuần</b></div>
                //</div>
                
            }

            {
                //(userInfo && userInfo.email === "tranvanhao@gmail.com") ||
                //    ((new Date().getMonth() == 8 || new Date().getMonth() == 9) && new Date().getDate() <= 5) ||
                //    (userInfo && userInfo.userRoles &&
                //        (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN"))) ?
                //((new Date().getMonth() == 7 || new Date().getMonth() == 8 || new Date().getMonth() == 9)) ?
                    <div className="row">
                        {/*<div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                        {/*    <DepartmentRankCupWidget*/}
                        {/*        week={week}*/}
                        {/*        limit={5}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                        {/*    <DepartmentRankCupWidget*/}
                        {/*    week={{ ...week, month: new Date().getDate() <= 5 ? new Date().getMonth() : new Date().getMonth()+1, week: undefined }}*/}
                        {/*        limit={5}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className="col-xl-4 col-lg-6 col-md-6 col-12">*/}
                        {/*    <DeliveryRankCupWidget*/}
                        {/*        limit={limit}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                    //:
                    //<div className="card card-body">
                    //    <div className="text-danger text-center"><b>Kết quả xếp hạng tháng sẽ hiển thị vào đầu tháng sau</b></div>
                    //</div>

            }
            
            <MyExaminationWidget
                userInfo={userInfo}
                week={week}
            />
            
            {/*<SheepNumberChart/>*/}

            {
                //userInfo && userInfo.userRoles && (userInfo.userRoles.includes("KHUVUCTRUONG") || userInfo.userRoles.includes("DIAVUCTRUONG") || userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) ?
                //    <SheepAnuallyReport /> : <></>
            }
        </>
    )
}