const headCellsListTransportOrder = [
    {
        id: '',
        numeric: false,
        disablePadding: true,
        label: 'Mã vận đơn',
        className: 'pt-3 pb-3',
    },
    {
        id: 'orderDate',
        numeric: false,
        disablePadding: false,
        label: 'Ngày',
        className: 'pt-3 pb-3',
        style: { minWidth: "60px" }
    },
    {
        id: 'receiptTime',
        numeric: false,
        disablePadding: false,
        label: 'Thời gian',
        className: 'pt-3 pb-3',
    },
    {
        id: 'District.Name',
        numeric: false,
        disablePadding: false,
        label: 'Quận/Huyện',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Commune.Name',
        numeric: false,
        disablePadding: false,
        label: 'Phường/xã',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Address',
        numeric: false,
        disablePadding: true,
        label: 'Địa chỉ',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'HerdsmanReceipt.Name',
        numeric: false,
        disablePadding: false,
        label: 'Người nhận',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'HerdsmanReceipt.PhoneNumber',
        numeric: false,
        disablePadding: true,
        label: 'Điện thoại',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'Quatity',
        numeric: false,
        disablePadding: false,
        label: 'S/lượng',
        className: 'pt-3 pb-3',
        style: { minWidth: "30px" }
    },
    {
        id: 'Status',
        numeric: false,
        disablePadding: false,
        label: 'Tình trạng',
        className: 'pt-3 pb-3',
        style: { minWidth: "70px" }
    },
    {
        id: 'HerdsManDelivery.Name',
        numeric: false,
        disablePadding: true,
        label: 'Ace giao',
        className: 'pt-3 pb-3',
        style: { minWidth: "300px" }
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: true,
        label: 'Ghi chú',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
];

export default headCellsListTransportOrder;