import React, { useEffect, useState } from 'react';
import CuisineOrderNumberWidget from './components/cuisine-order-number-widget';
import { getUserInfo } from '../../utils/configuration';

export default function DashboardCuisineOrder() {
    //var userRole: string;
    useEffect(() => {
        
    }, [])

    return (
        <>
            <CuisineOrderNumberWidget />
            {/*<SheepNumberChart/>*/}
        </>
    )
}