import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import Select from "react-select";
import { patternNumber } from '../../../common/validatePhoneNumber';
import { optionsCuisineOrderStatus, optionsReceiptTime } from '../../../common/constant';
import * as communeManagementAction from "../../../redux/store/commune-management/commune.store"
import { getUserInfo } from '../../../utils/configuration';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function FormAddEditAcademyPoint(props) {
    const now = new Date();
    const isNoCookingDate = (date) => {
        const day = date.day();
        const dateNumber = date.date();
        return day === 2 || day === 4 || day === 6 || dateNumber === 19 || dateNumber === 20;
    };
    
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, herdsmanName, 
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    const [currentLoggedInHerdsman] = useState({ label: getUserInfo()?.fullName, value: getUserInfo()?.herdsmanId });
    const [expanded, setExpanded] = React.useState(false);
    const [valueEA, setValueEA] = React.useState(0);
    const [valuePA, setValuePA] = React.useState(0);

    const handleChangeEA = (event, newValue) => {
        setValueEA(newValue);
    };
    const handleChangePA = (event, newValue) => {
        setValuePA(newValue);
    };

    useEffect(() => {
        //setValue("orderCode", autoGenOrderCode);

        
    }, [])

    const { register, handleSubmit, errors, setValue, setError } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }
        
        onSubmitAddEdit(updateItem);
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])


    const onChangeDateValue = (data) => {
        setValue("orderDate", dayjs(data).format());
        setError("orderDate", {
            type: "manual",
            message: "Sai ngày tháng rồi!",
        })
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const renderAcademyStepChild = (academyTypeId, step) => {
        return <table className="table dataTable table-hover " id={`datatable-${academyTypeId}-${step}`}>
            <thead>
                <tr>
                    <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Giai đoạn</b></th>
                    <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Số lần giáo dục</b></th>
                    <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Số lần phát biểu</b></th>
                </tr>
            </thead>
            <tbody>
                {
                    updateItem && updateItem?.details && updateItem?.details.length > 0 && updateItem.details.filter(x => x.academyTypeId === academyTypeId && x.wmcAcademyStep.startsWith(step.toString())).map((itemDetail, index) =>
                        <tr key={index}>
                            <td>{itemDetail.wmcAcademyStep}&nbsp;{itemDetail.wmcAcademyStepName}</td>
                            <td><input
                                className="form-control"
                                type="text"
                                name={`details[${50 * (academyTypeId - 1) + 10*(step-1) + index}][nmQuantity]`}
                                defaultValue={itemDetail?.nmQuantity || null}
                                placeholder="Số lần giáo dục"
                                ref={register({

                                })}
                                onChange={(e) => {
                                    setValue(
                                        `details[${50 * (academyTypeId - 1) + 10 * (step - 1) + index}][nmQuantity]`, //"details[" + 50 * (academyTypeId-1) + index + "][nmQuantity]"
                                        e.target.value.replace(patternNumber, "")
                                    );
                                    itemDetail.nmQuantity = e.target.value.replace(patternNumber, "");
                                    if (itemDetail.nmQuantity === "") itemDetail.nmQuantity = 0
                                }
                                }
                            /></td>
                            <td><input
                                className="form-control"
                                type="text"
                                name={`details[${50 * (academyTypeId - 1) + 10 * (step - 1) + index}][omQuantity]`}
                                defaultValue={itemDetail?.omQuantity || null}
                                placeholder="Số lần phát biểu"
                                ref={register({

                                })}
                                onChange={(e) => {
                                    setValue(
                                        `details[${50 * (academyTypeId - 1) + 10 * (step - 1) + index}][omQuantity]`,
                                        e.target.value.replace(patternNumber, "")
                                    );
                                    itemDetail.omQuantity = e.target.value.replace(patternNumber, "");
                                    if (itemDetail.omQuantity === "") itemDetail.omQuantity = 0
                                }
                                }
                            /></td>
                        </tr>)
                }
            </tbody>
        </table>
    }

    const renderAcademyStep = (academyTypeId) => {
        return <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={academyTypeId === 1 ? valueEA : valuePA} onChange={academyTypeId === 1 ? handleChangeEA : handleChangePA}
                    aria-label="scrollable auto tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile={true }>
                    <Tab label="Giai đoạn 1" {...a11yProps(0)} />
                    <Tab label="Giai đoạn 2" {...a11yProps(1)} />
                    <Tab label="Giai đoạn 3" {...a11yProps(2)} />
                    <Tab label="Giai đoạn 4" {...a11yProps(3)} />
                    <Tab label="Giai đoạn 5" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={academyTypeId === 1 ? valueEA : valuePA} index={0}>
                {
                    renderAcademyStepChild(academyTypeId, 1)
                }
            </CustomTabPanel>
            <CustomTabPanel value={academyTypeId === 1 ? valueEA : valuePA} index={1}>
                {
                    renderAcademyStepChild(academyTypeId, 2)
                }
            </CustomTabPanel>
            <CustomTabPanel value={academyTypeId === 1 ? valueEA : valuePA} index={2}>
                {
                    renderAcademyStepChild(academyTypeId, 3)
                }
            </CustomTabPanel>
            <CustomTabPanel value={academyTypeId === 1 ? valueEA : valuePA} index={3}>
                {
                    renderAcademyStepChild(academyTypeId, 4)
                }
            </CustomTabPanel>
            <CustomTabPanel value={academyTypeId === 1 ? valueEA : valuePA} index={4}>
                {
                    renderAcademyStepChild(academyTypeId, 5)
                }
            </CustomTabPanel>
            {/*<table className="table dataTable table-hover " id={`datatable-${academyTypeId}`}>
                <thead>
                    <tr>
                        <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Giai đoạn</b></th>
                        <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Số lần giáo dục</b></th>
                        <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Số lần phát biểu</b></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        updateItem && updateItem?.details && updateItem?.details.length > 0 && updateItem.details.filter(x => x.academyTypeId === academyTypeId).map((itemDetail, index) =>
                            <tr key={index}>
                                <td>{itemDetail.wmcAcademyStep}&nbsp;{itemDetail.wmcAcademyStepName}</td>
                                <td><input
                                    className="form-control"
                                    type="text"
                                    name={`details[${50 * (academyTypeId - 1) + index}][nmQuantity]`}
                                    defaultValue={itemDetail?.nmQuantity || null}
                                    placeholder="Số lần giáo dục"
                                    ref={register({

                                    })}
                                    onChange={(e) => {
                                        setValue(
                                            `details[${50 * (academyTypeId - 1) + index}][nmQuantity]`, //"details[" + 50 * (academyTypeId-1) + index + "][nmQuantity]"
                                            e.target.value.replace(patternNumber, "")
                                        );
                                        itemDetail.nmQuantity = e.target.value.replace(patternNumber, "");
                                        if (itemDetail.nmQuantity === "") itemDetail.nmQuantity = 0
                                    }
                                    }
                                /></td>
                                <td><input
                                    className="form-control"
                                    type="text"
                                    name={`details[${50 * (academyTypeId - 1) + index}][omQuantity]`}
                                    defaultValue={itemDetail?.omQuantity || null}
                                    placeholder="Số lần phát biểu"
                                    ref={register({

                                    })}
                                    onChange={(e) => {
                                        setValue(
                                            `details[${50 * (academyTypeId - 1) + index}][omQuantity]`,
                                            e.target.value.replace(patternNumber, "")
                                        );
                                        itemDetail.omQuantity = e.target.value.replace(patternNumber, "");
                                        if (itemDetail.omQuantity === "") itemDetail.omQuantity = 0
                                    }
                                    }
                                /></td>
                            </tr>)
                    }
                </tbody>
            </table>*/ }
        </>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="text-center row">
                <div className="col-6">Họ tên ACE: { herdsmanName}</div>
                <div className="col-6">Ngày phát biểu: {dayjs(updateItem?.reportDate).format("DD/MM/YYYY")}</div>
            </div>
            <div className="row">
                <div className="form-group col-12">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Phát biểu lẽ thật (EA)
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                renderAcademyStep(1)
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            Hãy chăn chiên Ta (PA)
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                renderAcademyStep(2)
                            }
                        </AccordionDetails>
                    </Accordion>
                    {/*
                    <table className="table dataTable table-hover " id="datatable1">
                        <thead>
                            <tr>
                                <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Giai đoạn</b></th>
                                <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Số lần giáo dục</b></th>
                                <th style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Số lần phát biểu</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                updateItem && updateItem?.details && updateItem?.details.length > 0 && updateItem.details.map((itemDetail, index) =>
                                    <tr key={index }>
                                        <td>{itemDetail.wmcAcademyStep}&nbsp;{itemDetail.wmcAcademyStepName}</td>
                                        <td><input
                                            className="form-control"
                                            type="text"
                                            name={"details[" + index + "][nmQuantity]"}
                                            defaultValue={itemDetail?.nmQuantity || null}
                                            placeholder="Số lần giáo dục"
                                            ref={register({
                                                
                                            })}
                                            onChange={(e) => {
                                                setValue(
                                                    "details[" + index + "][nmQuantity]",
                                                    e.target.value.replace(patternNumber, "")
                                                );
                                                itemDetail.nmQuantity = e.target.value.replace(patternNumber, "");
                                                if (itemDetail.nmQuantity === "") itemDetail.nmQuantity=0
                                            }
                                            }
                                        /></td>
                                        <td><input
                                            className="form-control"
                                            type="text"
                                            name={"details[" + index + "][omQuantity]"}
                                            defaultValue={itemDetail?.omQuantity || null}
                                            placeholder="Số lần phát biểu"
                                            ref={register({
                                                
                                            })}
                                            onChange={(e) => {
                                                setValue(
                                                    "details[" + index + "][omQuantity]",
                                                    e.target.value.replace(patternNumber, "")
                                                );
                                                itemDetail.omQuantity = e.target.value.replace(patternNumber, "");
                                                if (itemDetail.omQuantity === "") itemDetail.omQuantity = 0
                                            }
                                            }
                                        /></td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                    */ }
                </div>
                {/*<div className="form-group col-md-6">*/}
                {/*    <label>Người nhận <span className='required'>*</span></label>*/}
                {/*    <Select*/}
                {/*        isClearable*/}
                {/*        {...register("herdsManOrderId", { required: true })}*/}
                {/*        //defaultValue={updateItem?.herdsManOrderId ? { label: updateItem?.herdsManOrderName, value: updateItem?.herdsManOrderId } : null}*/}
                {/*        //defaultValue={updateItem?.herdsmanId ? { label: updateItem?.herdsmanName, value: updateItem?.herdsmanId } : (currentLoggedInHerdsman ? currentLoggedInHerdsman : null)}*/}
                {/*        defaultValue={updateItem?.herdsManOrderId ? optionsHerdsman.find(item => item.value == updateItem?.herdsManOrderId) : (currentLoggedInHerdsman ? optionsHerdsman.find(item => item.value == currentLoggedInHerdsman.value) : null)}*/}
                {/*        placeholder="Chọn Người nhận"*/}
                {/*        closeMenuOnSelect={true}*/}
                {/*        onChange={(data) => setValue("herdsManOrderId", data ? data.value : null)}*/}
                {/*        options={optionsHerdsman}*/}
                {/*        noOptionsMessage={() => "Không tồn tại"}*/}
                {/*    />*/}
                {/*    {errors.herdsManOrderId && errors.herdsManOrderId.type === "required" && (*/}
                {/*        <span className="error">Trường này là bắt buộc</span>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
            
            
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}