import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import { patternNumber } from '../../../common/validatePhoneNumber';
import { optionsCustomerStatus } from '../../../common/constant';
import DepartmentHelper from '../../list-department/helper/department-helper';
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';
import { getUserInfo } from '../../../utils/configuration';

export default function FormAddEditHerdsman(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        department, departmentHelping, district, herdsman,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    const [checkActive, setCheckActive] = useState(true);
    const [checkNeedHelp, setCheckNeedHelp] = useState(false);
    const [optionsHerdsman, setOptionsHerdsman] = useState(herdsman.map(item => {return {label: item.name, value: item.id}}));
    const [selectedHerdsman, setSelectedHerdsman] = useState([]);
    const [currentLoggedInHerdsman] = useState({ label: getUserInfo()?.fullName, value: getUserInfo()?.herdsmanId });
    
    useEffect(() => {
        setCheckActive(updateItem?.isActived);
        setCheckNeedHelp(updateItem?.needHelp);
        setValue("fullName", updateItem?.fullName);
        setValue("yearOfBirth", updateItem?.yearOfBirth);
        setValue("departmentId", updateItem?.departmentId);
        setValue("departmentHelpingId", updateItem?.departmentHelpingId);
        setValue("districtId", updateItem?.districtId);
        // setValue("herdsmanId1", updateItem?.);
        // setValue("herdsmanId2", updateItem?.);
        // setValue("herdsmanId3", updateItem?.);
        setValue("status", updateItem?.status);
        setValue("customerType", updateItem?.customerType);
        setValue("description", updateItem?.description);
        setValue("career", updateItem?.career);

        const selectedHerdsmanTemp = [];
        
        if (!updateItem) {
            const filtered = optionsHerdsman.find(item => item.value == currentLoggedInHerdsman.value);
            if (filtered) selectedHerdsmanTemp.push(filtered);
        }
        else {
            if (updateItem?.herdsmanId1) {
                const filtered = optionsHerdsman.find(item => item.value === updateItem.herdsmanId1);
                if (filtered) selectedHerdsmanTemp.push(filtered);
            }
            if (updateItem?.herdsmanId2) {
                const filtered = optionsHerdsman.find(item => item.value === updateItem.herdsmanId2);
                if (filtered) selectedHerdsmanTemp.push(filtered);
            }
            if (updateItem?.herdsmanId3) {
                const filtered = optionsHerdsman.find(item => item.value === updateItem.herdsmanId3);
                if (filtered) selectedHerdsmanTemp.push(filtered);
            }
        }
        
        setSelectedHerdsman(selectedHerdsmanTemp);
        setValue("selectedHerdsman", selectedHerdsmanTemp);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }
        
        const dataInput = { ...data, IsCreateUser: false, id: updateItem?.id, status: updateItem ? updateItem.status : optionsCustomerStatus[0].value, buoc1_actual: updateItem ? updateItem.buoc1_actual : true, buoc1_virtual: updateItem? updateItem.buoc1_virtual : true };
        if ( Array.isArray(optionsHerdsman) ) {
            selectedHerdsman.map((item, index) => {
                dataInput[`herdsmanId${index + 1}`] = item.value;
            })
        }
        //if(!dataInput.status) dataInput.status = optionsCustomerStatus[0].value;
        onSubmitAddEdit(dataInput);
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Họ và tên đệm <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="fullName"
                        defaultValue={updateItem?.fullName}
                        placeholder="Nhập họ và tên"
                        maxLength={180 }
                        ref={register({ required: true })}
                    />
                    {errors.fullName && errors.fullName.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-3 col-sm-6">
                    <label>Năm sinh <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="yearOfBirth"
                        defaultValue={updateItem?.yearOfBirth}
                        placeholder="Năm sinh"
                        ref={register({ required: true, maxLength: 4, minLength: 4 })}
                        onChange={(e) =>
                            setValue(
                              "yearOfBirth",
                              e.target.value.replace(patternNumber, "")
                            )
                        }
                    />
                    {errors.yearOfBirth && errors.yearOfBirth.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.yearOfBirth && (errors.yearOfBirth.type === "minLength" || errors.yearOfBirth.type === "maxLength") && (
                      <span className="error">Vui lòng nhập năm sinh hợp lệ</span>
                    )}
                </div>
                <div className="form-group col-md-3  col-sm-6">
                    <label>Giới tính</label>
                    <select className="form-control" name="gender" ref={register()} defaultValue={updateItem?.gender}>
                        <option value="1">Nam</option>
                        <option value="0">Nữ</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Số điện thoại</label>
                    <input
                        className="form-control"
                        type="text"
                        name="phoneNumber"
                        defaultValue={updateItem?.phoneNumber}
                        placeholder="Số điện thoại"
                        ref={register()}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Quận/ huyện</label>
                    <Select
                        isClearable
                        {...register("districtId")}
                        defaultValue={updateItem?.districtId ? {label: updateItem?.districtName, value: updateItem?.districtId} : null}
                        placeholder="Chọn Quận/ huyện"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("districtId", data ? data.value : null)}
                        options={district.map(item => {return {label: item.name, value: item.id}})}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                </div>
            </div>
            <div className="row">
                
                    {
                        <div className="form-group col-md-6">
                        <label>Độ tuổi<span className='required'>*</span></label>
                    {/*<DepartmentTreeSelect*/}
                    {/*    {...register("departmentId", { required: true })}*/}
                    {/*    onChange={(data) => setValue("departmentId", data ? data.value : null)}*/}
                    {/*    selected={updateItem?.departmentId ? { label: updateItem?.departmentName, value: updateItem?.departmentId } : null}*/}
                    {/*    placeholder="Chọn Ban"*/}
                    {/*    options={department}*/}
                    {/*    />*/}
                        <Select
                            isClearable
                            {...register("departmentId")}
                            defaultValue={updateItem?.departmentId ? { label: updateItem?.departmentName, value: updateItem?.departmentId } : null}
                            placeholder="Chọn độ tuổi"
                            closeMenuOnSelect={true}
                            onChange={(data) => setValue("departmentId", data ? data.value : null)}
                            options={department.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                        />
                    {errors.departmentId && errors.departmentId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}</div>
                        }
                
                <div className="form-group col-md-6">
                    <label>Nghề nghiệp</label>
                    <input
                        className="form-control"
                        type="text"
                        maxLength={140 }
                        name="career"
                        defaultValue={updateItem?.career}
                        placeholder="Nghề nghiệp"
                        ref={register()}
                    />
                </div>
                {/*
                    <div className="form-group col-md-6">
                    <label>Trạng thái</label>
                    <Select
                        {...register("status")}
                        defaultValue={updateItem?.status ? { value: updateItem?.status, label: updateItem?.statusName } : null}
                        // value={optionsCustomerStatus[0].value}
                        placeholder="Chọn Trạng thái"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("status", data ? data.value : null)}
                        options={optionsCustomerStatus}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                </div>
                    */ }
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Loại<span className='required'>*</span></label>
                    <select className="form-control" name="customerType" ref={register({ required: true })} defaultValue={updateItem?.customerType}>
                        <option value="0">Chiên</option>
                        <option value="1">La-xa-rơ</option>
                    </select>
                    {errors.customerType && errors.customerType.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>ACE dẫn dắt <span className="text-success">(tối đa 3 Ace)</span> <span className='required'>*</span></label>
                    <Select
                        {...register("selectedHerdsman", {
                            required: true,
                            validate: (data)=>{
                                if (data.length <= 0)
                                    return "inValid";
                                return true;
                            }
                        })}
                        isClearable
                        isMulti
                        value={selectedHerdsman}
                        placeholder="Chọn ACE dẫn dắt"
                        onChange={(data) => {
                            if(Array.isArray(data) && data.length > 3) return;
                            setSelectedHerdsman(data);
                            setValue("selectedHerdsman", data);
                        }}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {errors.selectedHerdsman && errors.selectedHerdsman.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.selectedHerdsman && errors.selectedHerdsman.type === "validate" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Ghi chú</label>
                    <textarea
                        className="form-control"
                        rows={4 }
                        type="text"
                        maxLength={540 }
                        name="description"
                        defaultValue={updateItem?.description}
                        placeholder="Nhập Mô tả"
                        ref={register()}
                    />
                </div>
                <div className="form-group col-md-6">
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label>Gửi giúp đỡ</label>
                            <div className="switch-button switch-button-yesno ml-5 switch-button-xs">
                                <input
                                    type="checkbox"
                                    checked={checkNeedHelp}
                                    name="needHelp"
                                    id="needHelp"
                                    ref={register()}
                                    onChange={() => setCheckNeedHelp(prev => !prev)}
                                />
                                <span><label htmlFor="needHelp"></label></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label>Gửi đến ban/đội/địa vực</label>
                            <DepartmentTreeSelect
                                {...register("departmentHelpingId", {  })}
                                onChange={(data) => setValue("departmentHelpingId", data ? data.value : null)}
                                selected={updateItem?.departmentHelpingId ? { label: updateItem?.departmentHelpingName, value: updateItem?.departmentHelpingId } : null}
                                placeholder="Chọn Ban"
                                options={departmentHelping}
                                isDisabled={!checkNeedHelp }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label>Trạng thái hoạt động</label>
                            <div className="switch-button switch-button-xs switch-button-yesno ml-5">
                                <input
                                    type="checkbox"
                                    checked={checkActive}
                                    name="isActived"
                                    id="isActived"
                                    ref={register()}
                                    onChange={() => setCheckActive(prev => !prev)}
                                />
                                <span><label htmlFor="isActived"></label></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           
            
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}