import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListMedia = (pageIndex = 1, pageSize = 10, sortExpression = "", title = undefined, description = undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    title && params.append("title", title);
    description && params.append("description", description);
    return service.get(ApiUrl.GetListMedia, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailMedia = (id) => {
    return service.get(ApiUrl.GetDetailMedia.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateMedia = (data) => {
    return service.post(ApiUrl.CreateMedia, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateMedia = (data) => {
    return service.put(ApiUrl.UpdateMedia, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteMedia = (id) => {
    return service.delete(ApiUrl.DeleteMedia.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentMedia = (id) => {
    return service.delete(ApiUrl.DeletePermanentMedia.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveMedia = (id) => {
    return service.put(ApiUrl.ActiveMedia.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveMedia = (id) => {
    return service.put(ApiUrl.DeActiveMedia.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}