import React, { useEffect, useState, useRef } from 'react';
import { getUserInfo, ShowXepHang } from '../../utils/configuration';
import ReactPlayer from 'react-player/lazy';
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import HerdsmanRankCupAcademy from './components/herdsman-rank-cup-academy';
import ZionRankCupAcademy from './components/zion-rank-cup-academy';
import * as reportAcademyAction from "../../redux/store-sarang/report-academy/report-academy-management.store";
;export default function ExamAcademyDashboard() {
    //var userRole: string;
    const userInfo = getUserInfo();
    const [week, setWeek] = useState();
    const dayOfWeek = new Date().getDay();
    const hour = new Date().getHours();
    const limit = 10;
    useEffect(() => {
        //if (!(ShowXepHang ||
        //    (userInfo && userInfo.email === "tranvanhao@gmail.com") ||
        //    (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")))))

        if (!ShowXepHang)
            window.location.replace("/");
        else {
            if (dayOfWeek !== 6)
                reportAcademyAction.SumaryPoint(1);
            GetCurrentWeek();
        }
        
    }, [])

    const GetCurrentWeek = async () => {
        try {
             const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
            }
        } catch (err) {
            ;
            
        } finally {
            ;
        }
    };

    return (
        <>
            { 
                (userInfo && userInfo.email === "tranvanhao@gmail.com") ||
                    (userInfo && userInfo.userName === "dong0022") ||
                (userInfo && userInfo.userRoles &&
                    (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN") || (dayOfWeek == 6 && hour >= 6))) ? 
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <HerdsmanRankCupAcademy
                            week={week}
                            limit={limit}
                        />
                    </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <ZionRankCupAcademy
                                week={week}
                                weekNumber={week?.week}
                            />
                        </div>
                </div> :
                <div className="card card-body">
                        <div className="text-danger text-center"><b>Kết quả xếp hạng tuần sẽ hiển thị vào thứ 7 hàng tuần</b></div>
                </div>
            }
        </>
    )
}