import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListZion = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    searchData?.name && params.append("name", searchData.name);
    searchData?.districtId && params.append("districtId", searchData.districtId);
    searchData?.address && params.append("address", searchData.address);
    searchData?.isDeleted!=undefined && params.append("isDeleted", searchData.isDeleted);
    searchData?.isActived != undefined && params.append("isActived", searchData.isActived);
    return service.get(ApiUrl.GetListZion, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailZion = (id) => {
    return service.get(ApiUrl.GetDetailZion.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateZion = (data) => {
    return service.post(ApiUrl.CreateZion, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateZion = (data) => {
    return service.put(ApiUrl.UpdateZion, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteZion = (id) => {
    return service.delete(ApiUrl.DeleteZion.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentZion = (id) => {
    return service.delete(ApiUrl.DeletePermanentZion.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveZion = (id) => {
    return service.put(ApiUrl.ActiveZion.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveZion = (id) => {
    return service.put(ApiUrl.DeActiveZion.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const GetLookupZion = () => {
    return service.get(ApiUrl.GetLookupZion,).then((res) => { return res }).catch((err) => { throw err });
}