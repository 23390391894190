import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UrlCollectionSarang } from '../../../common/url-collection-sarang';
import * as cuisineOrderManagementAction from "../../../redux/store-sarang/cuisine-order/cuisine-order-management.store";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import CookingRegisterChart from '../../../pages/dashboard-home/component/dashboard-cooking-register/cooking-register-chart';
import * as viVN from "../../../language/vi-VN.json";
import dayjs from 'dayjs';

export default function CuisineOrderNumberWidget() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const now = new Date();
    const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    useEffect(() => {
        getSumaryByDateCuisineOrder();
    }, []);

    const getSumaryByDateCuisineOrder = async () => {
        setLoading(true);
        try {
            const res = await cuisineOrderManagementAction.SumaryByDateCuisineOrder(dayjs(now).format(), dayjs(now).format());

            if (res && res.content) {
                setData(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="card row">
                <h5 className="card-header">Suất ăn ngày {dayjs(now).format("DD/MM/YYYY")}</h5>
                <div className="row card-body">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-inline-block">
                                    <h5 className="text-muted">Tổng</h5>
                                    <h2 className="mb-0"><b><Link to={UrlCollectionSarang.CuisineOrder}>{data.length > 0 ? data[0].numberMealSetLunch + data[0].numberMealSetDinner : 0}</Link></b></h2>
                                </div>
                                <div className="float-right icon-circle-medium  icon-box-lg  bg-info-light mt-1">
                                    <i className="fas fa-birthday-cake fa-fw fa-sm text-info"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-inline-block">
                                    <h5 className="text-muted">Trưa</h5>
                                    <h2 className="mb-0"><b><Link to={UrlCollectionSarang.CuisineOrder}>{data.length > 0 ? data[0].numberMealSetLunch : 0}</Link></b></h2>
                                </div>
                                <div className="float-right icon-circle-medium  icon-box-lg  bg-primary-light mt-1">
                                    <i className="fas fa-birthday-cake fa-fw fa-sm text-primary">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-inline-block">
                                    <h5 className="text-muted">Chiều</h5>
                                    <h2 className="mb-0"><b><Link to={UrlCollectionSarang.CuisineOrder}>{data.length > 0 ? data[0].numberMealSetDinner : 0}</Link></b></h2>
                                </div>
                                <div className="float-right icon-circle-medium  icon-box-lg  bg-secondary-light mt-1">
                                    <i className="fas fa-birthday-cake fa-fw fa-sm text-secondary"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingViewAria loading={loading} />
            </div>
            <div className="card row">
                <CookingRegisterChart listMonth={listMonth} />
            </div>
        </>   
    )
}