const headCellsListPosition = [
    {
        id: 'Herdsman.LastName',
        numeric: false,
        disablePadding: false,
        label: 'Người gửi',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'Title',
        numeric: false,
        disablePadding: false,
        label: 'Tiêu đề',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'Content',
        numeric: false,
        disablePadding: false,
        label: 'Nội dung',
        className: 'pt-3 pb-3',
    },
    {
        id: '',
        numeric: false,
        disablePadding: true,
        label: 'Trả lời',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'CreatedDate',
        numeric: false,
        disablePadding: false,
        label: 'Ngày gửi',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
        style: { width: "60px" }
    },
];

export default headCellsListPosition;