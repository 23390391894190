import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as taskCalendarManagementAction from "../../redux/store-sarang/task-calendar/task-calendar-management.store";
import { useHistory } from 'react-router-dom';
import CalendarViewMode from './calendar-contact/calendar-contact';
import CalendarListViewMode from './list-task/list-task';

export const VIEW_MODE_PARAM = "view-mode";

export default function CalendarContact() {
    const history = useHistory();
    const [viewMode, setViewMode] = useState(null);

    useEffect(() => {
        fetchData();

        const params = new URLSearchParams(history.location.search);
        const viewModeTemp = params.get(VIEW_MODE_PARAM);
        if ( viewModeTemp != "calendar" ) {
            setViewMode(null)
            history.push({
                pathname: history.location.pathname
            })
        } else {
            setViewMode(viewModeTemp);
        }
    }, [history.location.search])


    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));
    
    const [herdsman, setHerdsman] = useState([]);
    const [herdsmanOwn, setHerdsmanOwn] = useState([]);
    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman(false);
            if (res && res.content) {
                setHerdsman(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const getLookupHerdsmanOwn = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                setHerdsmanOwn(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const [customer, setCustomer] = useState([]);
    const getLookupCustomer = async () => {
        try {
            const res = await customerManagementAction.GetLookupCustomer();
            if (res && res.content) {
                setCustomer(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupHerdsman(),
                getLookupHerdsmanOwn(),
                getLookupCustomer(),
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }

    const handleSubmitAddEditTask = async (data, onSuccessCallback) => {
        showLoading(true);

        try {
            let res = null;

            if( !data.id ) {
                res = await taskCalendarManagementAction.CreateTask(data);
            } else {
                res = await taskCalendarManagementAction.UpdateTask(data);
            }

            if ( res && res.content ) {
                onSuccessCallback();
            }
        } catch (err) {
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }
    return (
        <>
            {
                !viewMode || viewMode !== "calendar" ? (
                    <CalendarListViewMode 
                        customer={customer}
                        herdsman={herdsman}
                        herdsmanOwn={herdsmanOwn }
                        handleSubmitAddEditTask={handleSubmitAddEditTask}
                    />
                ) : (
                    <CalendarViewMode
                        customer={customer}
                        herdsman={herdsman}
                        handleSubmitAddEditTask={handleSubmitAddEditTask}
                    />
                )
            }
        </>
    )
}