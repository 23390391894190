import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListTransportOrder = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    searchData?.orderFromDate && params.append("orderFromDate", searchData?.orderFromDate);
    searchData?.orderToDate && params.append("orderToDate", searchData?.orderToDate);
    searchData?.status && params.append("status", searchData?.status);
    searchData?.departmentId && params.append("departmentId", searchData?.departmentId);
    searchData?.herdsmanReceiptId && params.append("herdsmanReceiptId", searchData?.herdsmanReceiptId);
    searchData?.address && params.append("address", searchData?.address);
    searchData?.districtId && params.append("districtId", searchData?.districtId);
    searchData?.communeId && params.append("communeId", searchData?.communeId);
    searchData?.receiptTime && params.append("receiptTime", searchData?.receiptTime);
    sortExpression && params.append("sorting", sortExpression);
    return service.get(ApiUrl.GetListTransportOrder, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetSumaryDelivery = (searchData = undefined) => {
    const params = new URLSearchParams();
    searchData?.deliveryFromDate && params.append("deliveryFromDate", searchData?.deliveryFromDate);
    searchData?.deliveryToDate && params.append("deliveryToDate", searchData?.deliveryToDate);
    searchData?.receiptTime && params.append("receiptTime", searchData?.receiptTime);
    searchData?.herdsmanDeliveryId && params.append("herdsmanDeliveryId", searchData?.herdsmanDeliveryId);
    searchData?.transportStatus && params.append("transportStatus", searchData?.transportStatus);
    return service.get(ApiUrl.SumaryDelivery, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailTransportOrder = (id) => {
    return service.get(ApiUrl.GetDetailTransportOrder.replace("{id}",id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateTransportOrder = (data) => {
    return service.post(ApiUrl.CreateTransportOrder, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateTransportOrder = (data) => {
    return service.put(ApiUrl.UpdateTransportOrder, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteTransportOrder = (id) => {
    return service.delete(ApiUrl.DeleteTransportOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentTransportOrder = (id) => {
    return service.delete(ApiUrl.DeletePermanentTransportOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveTransportOrder = (id) => {
    return service.put(ApiUrl.ActiveTransportOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveTransportOrder = (id) => {
    return service.put(ApiUrl.DeActiveTransportOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ChangeTransportOrderStatus = (data) => {
    return service.put(ApiUrl.ChangeTransportOrderStatus,data).then((res) => { return res }).catch(err => { throw err });
}
export const AssignDeliver = (data) => {
    return service.post(ApiUrl.AssignDeliver, data).then((res) => { return res }).catch(err => { throw err });
}
export const ChangeDeliver = (data) => {
    return service.put(ApiUrl.ChangeDeliver, data).then((res) => { return res }).catch(err => { throw err });
}
export const ChangeDeliveryStatus = (data) => {
    return service.put(ApiUrl.ChangeDeliveryStatus, data).then((res) => { return res }).catch(err => { throw err });
}

export const SumaryByDateTransportOrder = (fromDate = undefined, toDate = undefined) => {
    const params = new URLSearchParams();
    fromDate && params.append("fromDate", fromDate);
    toDate && params.append("toDate", toDate);
    return service.get(ApiUrl.SumaryByDateTransportOrder, params).then((res) => { return res }).catch((err) => { throw err });
}
export const SetHadSendToShipper = (data) => {
    return service.put(ApiUrl.SetHadSendToShipper, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeliveryRank = (fromDate, toDate) => {
    const params = new URLSearchParams();
    fromDate && params.append("fromDate", fromDate);
    toDate && params.append("toDate", toDate);
    return service.get(ApiUrl.DeliveryRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const ClearDelivery = (cuisineOrderId) => {
    const params = new URLSearchParams();
    params.append("cuisineOrderId", cuisineOrderId);
    return service.put(ApiUrl.ClearDelivery, params).then((res) => { return res }).catch(err => { throw err });
}