import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";
export default function LoadingViewAria(props) {
    const { loading } = props;


    return (
        <Backdrop open={loading} style={{ zIndex: 999999, color: "#fff", position: "absolute" }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}