import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListReportAcademy = (herdsmanId=undefined, week=undefined, fromDate=undefined, toDate=undefined) => {
    const params = new URLSearchParams();
    herdsmanId && params.append("herdsmanId", herdsmanId);
    week && params.append("week", week);
    fromDate && params.append("fromDate", fromDate);
    toDate && params.append("toDate", toDate);
    return service.get(ApiUrl.GetListReportAcademy, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetAcademyPoint = (week = undefined, month = undefined, year = undefined, departmentId=undefined, herdsmanId=undefined) => {
    const params = new URLSearchParams();
    week && params.append("week", week);
    month && params.append("month", month);
    year && params.append("year", year);
    departmentId && params.append("departmentId", departmentId);
    herdsmanId && params.append("herdsmanId", herdsmanId);
    return service.get(ApiUrl.GetAcademyPoint, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetListHerdsmanRank = (week = undefined, month = undefined, year = undefined, zionId = undefined, herdsmanId = undefined, pageIndex = undefined, pageSize = undefined) => {
    const params = new URLSearchParams();
    week && params.append("week", week);
    month && params.append("month", month);
    year && params.append("year", year);
    zionId && params.append("zionId", zionId);
    pageIndex && params.append("pageIndex", pageIndex);
    pageSize && params.append("pageSize", pageSize);
    params.append("isPermission", false);
    return service.get(ApiUrl.GetListHerdsmanRankAcademy, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetListZionWeeklyRank = (festivalCOGId = undefined, week = undefined, month = undefined, year = undefined, zionId = undefined, isPermission = undefined) => {
    const params = new URLSearchParams();
    festivalCOGId && params.append("festivalCOGId", festivalCOGId);
    week && params.append("week", week);
    month && params.append("month", month);
    year && params.append("year", year);
    zionId && params.append("zionId", zionId);
    params.append("isPermission", isPermission);
    return service.get(ApiUrl.GetListZionWeeklyRank, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetListZionTotallyRank = (festivalCOGId = undefined, zionId = undefined, isPermission = undefined) => {
    const params = new URLSearchParams();
    festivalCOGId && params.append("festivalCOGId", festivalCOGId);
    zionId && params.append("zionId", zionId);
    params.append("isPermission", isPermission);
    return service.get(ApiUrl.GetListZionTotallyRank, params).then((res) => { return res }).catch((err) => { throw err });
}

export const SumaryPoint = (festivalCOGId = undefined, week = undefined, month = undefined, year = undefined, herdsmanId = undefined, departmentId=undefined) => {
    const params = new URLSearchParams();
    festivalCOGId && params.append("festivalCOGId", festivalCOGId);
    week && params.append("week", week);
    month && params.append("month", month);
    year && params.append("year", year);
    herdsmanId && params.append("herdsmanId", herdsmanId);
    departmentId && params.append("departmentId", departmentId);
    return service.get(ApiUrl.SumaryPoint, params).then((res) => { return res }).catch((err) => { throw err });
}

export const CreateReportAcademy = (data) => {
    return service.post(ApiUrl.CreateReportAcademy, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateReportAcademy = (data) => {
    return service.put(ApiUrl.UpdateReportAcademy, data).then((res) => { return res }).catch(err => { throw err });
}
export const CompleteBasicPoint = (data) => {
    return service.put(ApiUrl.CompleteBasicPoint, data).then((res) => { return res }).catch(err => { throw err });
}
export const ClearBasicPoint = (data) => {
    return service.put(ApiUrl.ClearBasicPoint, data).then((res) => { return res }).catch(err => { throw err });
}
export const GetDetailAcademyPoint = (id) => {
    return service.get(ApiUrl.GetDetailAcademyPoint.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const GetDetailAcademyPointByWeek = (herdsmanId, week) => {
    const params = new URLSearchParams();
    herdsmanId && params.append("herdsmanId", herdsmanId);
    week && params.append("week", week);
    return service.get(ApiUrl.GetDetailAcademyPointByWeek, params).then(res => { return res }).catch((err) => { throw err });
}