import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListTransportOrder from './head-cell-list-transport-order';
import DataTableSarang from '../../components/datatable-sarang';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as transportOrderManagementAction from "../../redux/store-sarang/transport-order/transport-order-management.store";
import * as cuisineOrderManagementAction from "../../redux/store-sarang/cuisine-order/cuisine-order-management.store";
import * as zionManagementAction from "../../redux/store-sarang/zion/zion-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as groupManagementAction from "../../redux/store-sarang/group/group-management.store";
import * as districtManagementAction from "../../redux/store/district-management/district.store"
import * as positionManagementAction from "../../redux/store-sarang/position/position-management.store";
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as communeManagementAction from "../../redux/store/commune-management/commune.store"

//import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import Select from "react-select";
import { optionsTransportOrderStatus, optionsReceiptTime } from '../../common/constant';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import ModalConfirmCreateCuisine from '../../components/sarang-modals/modal-confirm/modal-confirm-create-cuisine';
import * as styles from './custome-style.module.scss'
import FormChangeDelivery from './components/form-change-delivery';
import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import DepartmentTreeSelect from '../list-department/components/department-tree-select';
import DepartmentHelper from '../list-department/helper/department-helper';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';
import MobileHeaderSarang from '../../components/mobile-header/mobile-header-sarang';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "orderDate desc" + (new Date().getHours() <= 13 ? ', receiptTime asc, commune.District.Name asc, commune.Name asc' : ', receiptTime desc, commune.District.Name asc, commune.Name asc'),
    orderBy: "orderDate",
    order: "desc",
}

export default function TransportOrderList() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));
    const now = new Date();

    // -- Data to map with ids
    const [herdsman, setHerdsman] = useState([]);
    const [herdsmanAll, setHerdsmanAll] = useState([]);
    const [district, setDistrict] = useState([]);
    const [commune, setCommune] = useState([]);
    const [department, setDepartment] = useState([]);
    const [searchData, setSearchData] = useState();
    const [currentUser] = useState(getUserInfo());
    const [data, setData] = useState([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [sumaryCuisine, setSumaryCuisine] = useState();
    const [openSearch, setOpenSearch] = useState(false);


    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const buttonOpenConfirmCreateCuisineRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
    const [isOpenAssignToMeDialog, setOpenAssignToMeDialog] = useState(false);
    const [isOpenCreateCusineDialog, setOpenCreateCusineDialog] = useState(false);
    const [isOpenAssignDeliveryDialog, setOpenAssignDeliveryDialog] = useState(false);
    const [isOpenCancelDeliveryDialog, setOpenCancelDeliveryDialog] = useState(false);

    const getLookupCommune = async (districtId) => {
        try {
            const res = await communeManagementAction.GetLookupCommune(districtId);
            if (res && res.content) {
                setCommune(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupDistrict = async () => {
        try {
            const res = await districtManagementAction.GetLookupDistrict();
            if (res && res.content) {
                setDistrict(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupHerdsman = async () => {
        showLoading(true);

        try {
            const res = await herdsmanManagementAction.GetLookupHerdsmanGV();
            if (res &&
                res.content &&
                Array.isArray(res.content)
            ) {
                setHerdsman(res.content.filter(item => item.id != currentUser.herdsmanId));
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }

    const getLookupHerdsmanAll = async () => {
        showLoading(true);

        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman(false);
            if (res &&
                res.content &&
                Array.isArray(res.content)
            ) {
                setHerdsmanAll(res.content.filter(item => item.id != currentUser.herdsmanId));
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetListNoPermiss();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };

    // -- End Data to map with ids

    useEffect(() => {
        setValue("orderFromDate", dayjs(now).format());
        setSearchData({ orderFromDate: dayjs(now).format() });
        getListTransportOrderManagement(1, configLocal.defaultPageSize, configLocal.sortExpression, { orderFromDate: dayjs(now).format() });
        getLookupHerdsman();
        getLookupHerdsmanAll();
        getLookupDistrict();
        getLookupDepartment();
    }, []);

    useEffect(() => {
        if (!selectedDistrict) return;
        getLookupCommune(selectedDistrict.value)
    }, [selectedDistrict])

    const getListTransportOrderManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, data) => {
        showLoading(true);

        try {
            const res = await transportOrderManagementAction.GetListTransportOrder(pageIndex, pageSize, sortExpression, data);
            if (res &&
                res.content &&
                res.content.items
            ) {
                setData(res.content.items)
                setTotalItemCount(res.content.totalItemCount)
            }
            if (res) {
                setSumaryCuisine({
                    sumaryLunch: res.sumaryLunch,
                    sumaryDinner: res.sumaryDinner,
                    sumaryMorning: res.sumaryMorning,
                    sumaryCuisineQuatity: res.sumaryCuisine,
                    sumaryMorningOrder: res.sumaryMorningOrder,
                    sumaryLunchOrder: res.sumaryLunchOrder,
                    sumaryDinnerOrder: res.sumaryDinnerOrder,
                    sumaryCuisineOrder: res.sumaryCuisineOrder,
                    sumaryShipperLunch: res.sumaryShipperLunch,
                    sumaryShipperDinner: res.sumaryShipperDinner,
                    sumaryShipperMorning: res.sumaryShipperMorning,
                    sumaryShipperCuisine: res.sumaryShipperCuisine,
                });
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort + ((property && property != "receiptTime") ? ', receiptTime asc' : '') + ', commune.District.Name asc, commune.Name asc';
        getListTransportOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') +', commune.District.Name asc, commune.Name asc';
        getListTransportOrderManagement(newPage, rowsPerPage, sortExpression, searchData);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') +', commune.District.Name asc, commune.Name asc';
        getListTransportOrderManagement(1, event.target.value, sortExpression, searchData);
    };

    const handleSubmitChangeDelivery = async (data) => {
        showLoading(true);

        try {
            let res = await transportOrderManagementAction.ChangeDeliver(data);

            if (res && res.content) {
                let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') +', commune.District.Name asc, commune.Name asc';
                getListTransportOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        }
    }

    const [selectedItem, setSelectedItem] = useState(null);

    const openChangeDeliveryDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }

    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }

    const openConfirmCreateCuisineDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmCreateCuisineRef.current.click();
    }

    const closeConfirmDialog = () => {
        if (!isOpenAssignToMeDialog && !isOpenAssignDeliveryDialog) {
            setOpenCreateCusineDialog(false);
            setSelectedItem(null);
        }
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenChangeStatusDialog(false);
        setOpenAssignToMeDialog(false);
        setOpenAssignDeliveryDialog(false);
        setOpenCancelDeliveryDialog(false);
    }

    const closeConfirmCreateCuisineDialog = async () => {
        setOpenAssignToMeDialog(false);
        setOpenAssignDeliveryDialog(false);
        setOpenCreateCusineDialog(false);

        let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') + ', commune.District.Name asc, commune.Name asc';
        getListTransportOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
    }

    const assignDelivery = async (createCuisine) => {
        let res = null;
        res = await transportOrderManagementAction.AssignDeliver({
            transportOrderId: selectedItem.id,
            herdsmanDeliverId: selectedItem.herdsmanDeliverId,  //currentUser.herdsmanId,
            createCuisineForShipper: createCuisine
        });

        if (res) {
            let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') + ', commune.District.Name asc, commune.Name asc';
            getListTransportOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
        }

        return res;
    }

    const handleConfirm = async () => {
        try {
            let res = null;
            if (isOpenDeleteDialog) {
                res = await transportOrderManagementAction.DeleteTransportOrder(selectedItem.id);
            } else if (isOpenActiveDialog) {
                res = await transportOrderManagementAction.ActiveTransportOrder(selectedItem.id);
            } else if (isOpenDeactiveDialog) {
                res = await transportOrderManagementAction.DeActiveTransportOrder(selectedItem.id);
            } else if (isOpenChangeStatusDialog) {
                res = await transportOrderManagementAction.ChangeTransportOrderStatus({
                    id: selectedItem.id,
                    status: selectedItem.orderStatusChange
                });
            } else if (isOpenCancelDeliveryDialog) {
                res = await transportOrderManagementAction.ClearDelivery(selectedItem.cuisineOrderID);
            } else if (isOpenAssignToMeDialog || isOpenAssignDeliveryDialog) {
                //const result = await cuisineOrderManagementAction.GetListCuisineOrder(1, 10, "CreatedDate desc", { orderFromDate: selectedItem.orderDate, orderToDate: selectedItem.orderDate, herdsmanId: selectedItem.herdsmanDeliverId });
                const result = await cuisineOrderManagementAction.CheckExistsCuisine(selectedItem.herdsmanDeliverId, selectedItem.orderDate);
                if (result && result.content) {
                    res = await transportOrderManagementAction.AssignDeliver({
                        transportOrderId: selectedItem.id,
                        herdsmanDeliverId: isOpenAssignToMeDialog ? currentUser.herdsmanId : selectedItem.herdsmanDeliverId,
                        createCuisineForShipper: false
                    });
                }
                else {
                    setOpenCreateCusineDialog(true);
                    openConfirmCreateCuisineDialog(selectedItem);
                    return;
                }
            }


            if (res) {
                let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') +', commune.District.Name asc, commune.Name asc';
                getListTransportOrderManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }

            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        }
    }

    const onSubmit = (data) => {
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') +', commune.District.Name asc, commune.Name asc';
        setPage(0);
        getListTransportOrderManagement(1, rowsPerPage, sortExpression, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} hideNewButton={true}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                sumary={sumaryCuisine}
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsListTransportOrder}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>{row.transportCode}</td>
                                    <td><span>{dayjs(row.orderDate).format("DD/MM/YYYY")}</span></td>
                                    <td><span>{row.receiptTime === 1 ? "Sáng" : (row.receiptTime === 2 ? "Trưa" : "Chiều")}</span></td>
                                    <td><span>{row.districtName}</span></td>
                                    <td><span>{row.communeName}</span></td>
                                    <td><span>{row.address}</span></td>
                                    <td><span>{row.herdsmanReceiptName}</span></td>
                                    <td><span><a href={`tel:${row.herdsmanReceiptPhone}`}>{row.herdsmanReceiptPhone}</a></span></td>
                                    <td><span>{row.quatity}</span></td>
                                    <td>
                                        {
                                            //row?.status !== 100 && row?.status !== 200 ?
                                            row.allowChangeStatus ?
                                                <span>
                                                    <Select
                                                        value={row?.status ? { label: row.statusName, value: row.status } : null}
                                                        placeholder="Chọn Trạng thái"
                                                        onChange={(data) => {
                                                            setOpenChangeStatusDialog(true);
                                                            openConfirmDialog({ ...row, orderStatusChange: data.value, orderStatusNameChange: data.label });
                                                        }}
                                                        options={optionsTransportOrderStatus.filter(x => x.value >= row?.status)} //&& x.value !== row?.status
                                                        noOptionsMessage={() => "Không tồn tại"}
                                                        // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    />
                                                </span> :
                                                <spam>{row?.statusName}</spam>
                                        }
                                    </td>
                                    <td>
                                        {
                                            (row.deliveries && Array.isArray(row.deliveries) && row.deliveries.length > 0) ?
                                                //sửa
                                                <div style={{ gap: '10px', display: 'flex', alignItems: 'center' }}>
                                                    {
                                                        (row?.status === 40 || row?.status === 45) ?
                                                        <>
                                                                <i className="far fa-edit text-primary" title="Đổi người giao" style={{ cursor: 'pointer' }} onClick={() => {
                                                                    openChangeDeliveryDialog(row)
                                                                }}></i>
                                                                <li title="Hủy" className="far fa-times-circle text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setOpenCancelDeliveryDialog(true);
                                                                    openConfirmDialog(row);
                                                                }}></li>
                                                            </>:
                                                            <></>
                                                    }
                                                    <span>{row.deliveries[0].herdsmanDeliverName}
                                                        {
                                                            row.deliveries[0].herdsmanDeliverPhone ?
                                                                <>
                                                                    <a href={`tel:${row.deliveries[0].herdsmanDeliverPhone}`}> - {row.deliveries[0].herdsmanDeliverPhone}</a>
                                                                    <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table phone ml-2" data-toggle="tooltip" data-placement="top"
                                                                        title="Gọi"
                                                                        onClick={() => {
                                                                            window.location.href = `tel:${row.deliveries[0].herdsmanDeliverPhone}`;
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-phone"></i>
                                                                    </button>
                                                                </>
                                                                : <></>
                                                        }
                                                    </span>
                                                </div> :
                                                <>
                                                    {
                                                        (row?.status === 40 || row?.status === 50)
                                                            ?
                                                            <div className={styles.delivery_container}>
                                                                <span className={styles.assign_to_me} onClick={() => {
                                                                    setOpenAssignToMeDialog(true);
                                                                    openConfirmDialog({ ...row, herdsmanDeliverId: currentUser.herdsmanId });
                                                                }}>Nhận giao</span>
                                                                {
                                                                    (row.allowAssign) ?
                                                                        <Select
                                                                            placeholder="Chọn người giao"
                                                                            onChange={(data) => {
                                                                                setOpenAssignDeliveryDialog(true);
                                                                                openConfirmDialog({ ...row, herdsmanDeliverId: data.value, herdsmanDeliverName: data.label });
                                                                            }}
                                                                            options={herdsman.map(item => { return { label: item.name, value: item.id } })}
                                                                            noOptionsMessage={() => "Không tồn tại"}
                                                                            // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                                            menuPortalTarget={document.body}
                                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        /> : <></>
                                                                }
                                                            </div> : <></>
                                                    }
                                                </>
                                        }
                                    </td>
                                    <td><span>{row.description}</span></td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <MobileHeaderSarang
                    headCell={headCellsListTransportOrder}
                    handleSortChange={(sortExpression) => getListTransportOrderManagement(page + 1, rowsPerPage, sortExpression, searchData)}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                />
                <div id="accordion">
                    <div className="card">
                        <div className="d-flex align-items-center">
                            <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                data-toggle="collapse" aria-expanded="false" >
                                <div className="d-flex w-100">
                                    {
                                        sumaryCuisine?.sumaryShipperCuisine ?
                                            <div>Đã nhận giao: <b>{sumaryCuisine?.sumaryShipperCuisine}</b> - Trưa: <b>{sumaryCuisine?.sumaryShipperLunch}</b> - Chiều: <b>{sumaryCuisine?.sumaryShipperDinner}</b></div> :
                                            (sumaryCuisine?.sumaryCuisineOrder ? <div>Tổng SL đơn: <b>{sumaryCuisine?.sumaryCuisineOrder}</b> - Trưa: <b>{sumaryCuisine?.sumaryLunchOrder}</b> - Chiều: <b>{sumaryCuisine?.sumaryDinnerOrder}</b></div> : <></>)
                                    }
                                    {/*<div>Tổng SL đơn: <b>{sumaryCuisine?.sumaryCuisineOrder}</b> - Trưa: <b>{sumaryCuisine?.sumaryLunchOrder}</b> - Chiều: <b>{sumaryCuisine?.sumaryDinnerOrder}</b></div>*/}
                                    <hr />
                                    <div>Tổng suất ăn: <b>{sumaryCuisine?.sumaryCuisineQuatity}</b> - Trưa: <b>{sumaryCuisine?.sumaryLunch}</b> - Chiều: <b>{sumaryCuisine?.sumaryDinner}</b></div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data.length > 0 ?
                            data.map((item, index) => (
                                <div className="card" key={index}>
                                    <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                            data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-1">{item.transportCode}</h5>
                                                <small className='d-flex align-items-center'>
                                                    {item.receiptTime === 1 ? "Sáng" : (item.receiptTime === 2 ? "Trưa" : "Chiều")}/
                                                    {dayjs(item.orderDate).format("DD/MM/YYYY")}
                                                </small>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='mt-0'><b>Quận/ huyện:</b></div>
                                                    <span>{item.districtName}</span>
                                                    <div className='mt-0'><b>Phường/xã:</b></div>
                                                    <span>{item.communeName}</span>
                                                    <div className='mt-0'><b>Địa chỉ:</b></div>
                                                    <span>{item.address}</span>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='mt-2'><b>Số lượng: </b> {item.quatity}</div>
                                                    <div className='mt-0'>
                                                        <b>Tình trạng: </b>
                                                        {
                                                            //item?.status !== 100 && item?.status !== 200 ?
                                                            item?.allowChangeStatus ?
                                                                <span>
                                                                    <Select
                                                                        value={item?.status ? { label: item.statusName, value: item.status } : null}
                                                                        placeholder="Chọn Trạng thái"
                                                                        onChange={(data) => {
                                                                            setOpenChangeStatusDialog(true);
                                                                            openConfirmDialog({ ...item, orderStatusChange: data.value, orderStatusNameChange: data.label });
                                                                        }}
                                                                        options={optionsTransportOrderStatus.filter(x => x.value >= item?.status && x.value !== item?.status)}
                                                                        noOptionsMessage={() => "Không tồn tại"}
                                                                        // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                                        menuPortalTarget={document.body}
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    />
                                                                </span> :
                                                                <spam>{item?.statusName}</spam>
                                                        }
                                                    </div>
                                                    <div className='mt-2'>
                                                        <b>Ace giao: </b>
                                                        {
                                                            (item.deliveries && Array.isArray(item.deliveries) && item.deliveries.length > 0) ?
                                                                //sửa
                                                                <div style={{ gap: '10px', display: 'flex', alignItems: 'center' }}>
                                                                    {
                                                                        (item?.status === 40 || item?.status === 45) ?
                                                                            <>
                                                                                <i className="far fa-edit text-primary" title="Sửa" onClick={() => { openChangeDeliveryDialog(item) }}></i>
                                                                                <li title="Hủy" className="far fa-times-circle text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setOpenCancelDeliveryDialog(true);
                                                                                    openConfirmDialog(item);
                                                                                }}></li>
                                                                            </>
                                                                             :
                                                                            <></>
                                                                    }
                                                                    <span>{item.deliveries[0].herdsmanDeliverName}
                                                                        {
                                                                            item.deliveries[0].herdsmanDeliverPhone ?
                                                                                <>
                                                                                    <a href={`tel:${item.deliveries[0].herdsmanDeliverPhone}`}> - {item.deliveries[0].herdsmanDeliverPhone}</a>
                                                                                    <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table phone ml-2" data-toggle="tooltip" data-placement="top"
                                                                                        title="Gọi"
                                                                                        onClick={() => {
                                                                                            window.location.href = `tel:${item.deliveries[0].herdsmanDeliverPhone}`;
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-phone"></i>
                                                                                    </button>
                                                                                </> : <></>
                                                                        }
                                                                    </span>
                                                                </div> :
                                                                <>
                                                                    {
                                                                        (item?.status === 40 || item?.status === 50)
                                                                            ?
                                                                            <div className={styles.delivery_container_mb}>
                                                                                <div className={styles.assign_to_me_mb} onClick={() => {
                                                                                    setOpenAssignToMeDialog(true);
                                                                                    openConfirmDialog({ ...item, herdsmanDeliverId: currentUser.herdsmanId});
                                                                                }}>Nhận giao</div>
                                                                                {
                                                                                    (item.allowAssign) ?
                                                                                        <Select
                                                                                            placeholder="Ae/Ce giao"
                                                                                            onChange={(data) => {
                                                                                                setOpenAssignDeliveryDialog(true);
                                                                                                openConfirmDialog({ ...item, herdsmanDeliverId: data.value, herdsmanDeliverName: data.label });
                                                                                            }}
                                                                                            options={herdsman.map(item => { return { label: item.name, value: item.id } })}
                                                                                            noOptionsMessage={() => "Không tồn tại"}
                                                                                            // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                                                            menuPortalTarget={document.body}
                                                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                        /> : <></>
                                                                                }
                                                                            </div> : <></>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <i className="fas fa-angle-down p-2"></i>
                                    </div>
                                    <div id={`collapse-${item.id}`} className="collapse" aria-labelledby={`heading-${item.id}`} data-parent="#accordion">
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='mt-0'><b>Người nhận:</b></div>
                                                    <span>{item.herdsmanReceiptName}</span>
                                                    <div className='mt-2'><b>Điện thoại:</b></div>
                                                    {
                                                        item.herdsmanReceiptPhone ?
                                                            <span>
                                                                <a href={`tel:${item.herdsmanReceiptPhone}`}>{item.herdsmanReceiptPhone}</a>
                                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table phone ml-2" data-toggle="tooltip" data-placement="top"
                                                                    title="Gọi"
                                                                    onClick={() => {
                                                                        window.location.href = `tel:${item.herdsmanReceiptPhone}`;
                                                                    }}
                                                                >
                                                                    <i className="fas fa-phone"></i>
                                                                </button>
                                                            </span> :
                                                            <br />
                                                    }
                                                </div>
                                            </div>

                                            <div className='mt-0'><b>Ghi chú:</b></div>
                                            <span>{item.description}</span>
                                        </div>
                                    </div>
                                </div>
                            )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                        <DataTablePagination
                            totalItemCount={totalItemCount}
                            rowsPerPage={rowsPerPage}
                            pageIndex={page + 1}
                            handleChangePage={handleChangePage}
                        /> :
                        <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} hideNewButton={true}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderFormSearch = () => {
        return <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                    <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                        <label>Từ ngày</label>
                        <DatePicker
                            {...register("orderFromDate")}
                            onChange={(data) => setValue("orderFromDate", dayjs(data).format())}
                            defaultValue={dayjs(now)}
                            className="form-control"
                            format='DD/MM/YYYY'
                            slotProps={{ textField: { size: 'small' } }}
                        //label={"Nhập Ngày nhận"}
                        />
                    </div>

                    <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                        <label>Từ ngày</label>
                        <DatePicker
                            {...register("orderToDate")}
                            onChange={(data) => setValue("orderToDate", dayjs(data).format())}
                            className="form-control"
                            format='DD/MM/YYYY'
                            slotProps={{ textField: { size: 'small' } }}
                        //label={"Nhập Ngày nhận"}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label>Thời gian <span className='required'>*</span></label>
                        <Select
                            {...register("receiptTime", { })}
                            placeholder="Thời gian nhận"
                            closeMenuOnSelect={true}
                            onChange={(data) => setValue("receiptTime", data ? data.value : null)}
                            options={optionsReceiptTime}
                            noOptionsMessage={() => "Không tồn tại"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="input-department-general">Ban/Đội/Nhóm</label>
                        <DepartmentTreeSelect
                            {...register("departmentId", {})}
                            onChange={(data) => setValue("departmentId", data ? data.value : null)}
                            placeholder="Chọn Ban"
                            options={DepartmentHelper.RenderOptions(department)}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="input-department-general">Swat</label>
                        <Select
                            isClearable
                            {...register("herdsmanReceiptId", {})}
                            placeholder="Swat"
                            onChange={(data) => setValue("herdsmanReceiptId", data ? data.value : null)}
                            options={herdsmanAll.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="input-department-general">Trạng thái</label>
                        <Select
                            isClearable
                            placeholder="Trạng thái"
                            {...register("status", {})}
                            onChange={(data) => {
                                setValue("status", data ? data.value : null);
                            }}
                            options={optionsTransportOrderStatus}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label htmlFor="input-department-general">Quận/huyện</label>
                        <Select
                            {...register("districtId", {})}
                            isClearable
                            placeholder="Chọn quận/huyện"
                            onChange={(data) => {
                                setValue("districtId", data ? data.value : null);
                                setSelectedDistrict(data || null);
                            }}
                            options={district.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label>Phường/ Xã <span className='required'>*</span></label>
                        <Select
                            {...register("communeId", {})}
                            isDisabled={commune.length == 0}
                            isClearable
                            placeholder="Chọn Phường/ Xã"
                            closeMenuOnSelect={true}
                            onChange={(data) => setValue("communeId", data ? data.value : null)}
                            options={commune.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="input-address" className="col-form-label">Địa chỉ</label>
                        <input
                            className="form-control"
                            type="text"
                            name="address"
                            placeholder="Địa chỉ"
                            ref={register()}
                        />
                    </div>
                </div>
                <FormSearchFunctionButtons>
                    <div className="row">
                        <div className="col-sm-12 pl-0">
                            <p className="text-center">
                                <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                <button type="button" className="btn btn-space btn-secondary" onClick={() => { }}>Xóa lọc</button>
                            </p>
                        </div>
                    </div>
                </FormSearchFunctionButtons>
            </form>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>

            <button ref={buttonOpenConfirmCreateCuisineRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirmCreateCuisine">
                Launch modal confirm
            </button>

            <ModalSubmitForm
                title={"Thay đổi người giao"}
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                <FormChangeDelivery
                    herdsman={herdsman}
                    // ===
                    updateItem={selectedItem}
                    onSubmitChanges={handleSubmitChangeDelivery}
                />
            </ModalSubmitForm>

            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Ae/Ce có chắc chắn muốn xóa không?" :
                        isOpenActiveDialog ? "Ae/Ce có chắc chắn muốn xác nhận không?" :
                            isOpenDeactiveDialog ? "Ae/Ce có chắc chắn muốn hủy xác nhận không?" :
                                isOpenChangeStatusDialog ? `Ae/Ce có chắc chắn muốn thay đổi trạng thái thành <b>"${selectedItem?.orderStatusNameChange}"</b>?` :
                                    isOpenAssignToMeDialog ? "Ae/Ce có chắc chắn muốn nhận giao đơn hàng này không?" :
                                        isOpenAssignDeliveryDialog ? `Ae/Ce có chắc chắn muốn cho a/c/e <b>"${selectedItem?.herdsmanDeliverName}"</b> giao đơn hàng này không?` :
                                            isOpenCancelDeliveryDialog ? "Ae/Ce có chắc chắn hủy giao đơn hàng này không?" :                                            ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog || isOpenChangeStatusDialog || isOpenAssignToMeDialog || isOpenAssignDeliveryDialog || isOpenCancelDeliveryDialog}
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />

            <ModalConfirmCreateCuisine
                title={"Xác nhận"}
                prompt={
                    isOpenCreateCusineDialog ? "Ae/Ce có muốn đăng ký suất ăn không?" : ""
                }
                open={isOpenCreateCusineDialog}
                onClose={closeConfirmCreateCuisineDialog}
                onConfirm={handleConfirm}
                assignDelivery={assignDelivery }
            />
        </div>
    )
}