import React, { useEffect, useState, useRef } from 'react';
import Select from "react-select";

import { NotificationMessageType, formatNumber } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";

import * as swatTrundoManagementAction from "../../../redux/store-sarang/swat-trundo/swat-trundo-management.store";
import * as departmentManagementAction from "../../../redux/store-sarang/department/department-management.store";
import * as festivalManagementAction from "../../../redux/store-sarang/festival-cog/festival-cog-management.store";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import DepartmentHelper from '../../list-department/helper/department-helper';
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';
import BodyReport from './body-report';

export default function SwatTrundoSumaryByDateReport() {
    //const userInfo = getUserInfo();
    const [loadingSwat, setLoadingSwat] = useState();

    const [sumaryByDate, setSumaryByDate] = useState();
    const [data, setData] = useState();
    const [dateString, setDateString] = useState();
    const [department, setDepartment] = useState([]);
    const [festivalCog, setFestivalCog] = useState([]);
    const [departmentId, setDepartmentId] = useState();
    const [festivalId, setFestivalId] = useState();
    const [totalItem, setTotalItem] = useState(0);
    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(100);

    useEffect(() => {
        getLookupDepartment();
        getLookupFestival();
        getSumaryByDate();
    }, [])

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem]);

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupFestival = async () => {
        try {
            const res = await festivalManagementAction.GetLookupFestivalCog();
            if (res && res.content) {
                setFestivalCog(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    };

    const getSumaryByDate = async (festivalCogId, departmentId, zionId) => {
        setLoadingSwat(true);
        try {
            const res = await swatTrundoManagementAction.GetSumaryHerdsmanByDate(festivalCogId, departmentId, zionId);

            if (res && res.content) {
                setSumaryByDate(res.content.groupSwat);
                setData(res.content.groupSwat);
                setDateString(res.content.dateString);
                setTotalItem(res.content.dateString.length);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoadingSwat(false);
        }
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    {festivalCog ? <div className="form-group  col-12 col-md-6 col-lg-6  col-xl-6">
                        <label>Lễ hội trundo</label>
                        <Select
                            isClearable
                            placeholder="Chọn lễ hội trundo"
                            closeMenuOnSelect={true}
                            onChange={(data) => {
                                setFestivalId(data ? data.value : null);
                                getSumaryByDate(data ? data.value : null, departmentId, null);
                            }}
                            options={festivalCog}
                            noOptionsMessage={() => "Không tồn tại"}
                        />
                    </div>
                        :
                        <></>
                    }
                    {department ? <div className="form-group  col-12 col-md-6 col-lg-6  col-xl-6">
                        <label>Ban/ đội/ nhóm</label>
                        <DepartmentTreeSelect
                            isClearable
                            //{...register("departmentId", {})}
                            onChange={(data) => {
                                setDepartmentId(data ? data.value : null);
                                getSumaryByDate(festivalId, data ? data.value : null, null);
                            }}
                            placeholder="Chọn Ban"
                            options={DepartmentHelper.RenderOptions(department)}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                        :
                        <></>
                    }
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="dataTables freeze-column" ref={scrollRoot}>
                        {data && data.length > 0 &&
                            <table className="table table-bordered table-hover dataTable" ref={scrollTable} id="datatable">
                                <thead>
                                    <tr>
                                        <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', padding: '2px', minWidth: '50px', fontSize: '10px' }}>Họ tên</th>
                                        <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', padding: '2px', minWidth: '20px', fontSize: '10px' }} colSpan={2}>Loại</th>
                                        {
                                            dateString && dateString.length > 0 && dateString.map((item, index) => (<>
                                                <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', padding: '2px', minWidth: '10px', fontSize: '10px' }}>{item}</th>
                                            </>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.map((item, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td rowSpan={item.statusQuantities.length * 2} style={{ fontSize: '10px', verticalAlign: 'middle', padding: '2px' }}>{item["herdsmanName"]}</td>
                                                    <td rowSpan={2} style={{ fontSize: '10px', verticalAlign: 'middle', padding: '2px', minWidth: '20px' }}>{item.statusQuantities.length > 0 ? item.statusQuantities[0].statusName : ""}</td>
                                                    <td style={{ fontSize: '10px', verticalAlign: 'middle' }}>SL</td>
                                                    {
                                                        dateString && dateString.length > 0 && dateString.map((itemDate, itemIndex) => (<>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle', fontSize: '10px', padding: '2px' }}>
                                                                {
                                                                    item.statusQuantities[0].dateQuantities.find(x => x.dateName == itemDate)?.quantity || null
                                                                }
                                                            </td>
                                                        </>
                                                        ))
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '10px', verticalAlign: 'middle', padding: '2px' }}>Điểm</td>
                                                    {
                                                        dateString && dateString.length > 0 && dateString.map((itemDate, itemIndex) => (<>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle', fontSize: '10px', padding: '2px' }}>
                                                                {
                                                                    formatNumber(item.statusQuantities[0].dateQuantities.find(x => x.dateName == itemDate)?.point) || null
                                                                }
                                                            </td>
                                                        </>
                                                        ))
                                                    }
                                                </tr>
                                                {
                                                    item.statusQuantities.slice(1).map((itemStatus, itemIndex) => (
                                                        <>
                                                            <tr>
                                                                <td rowSpan={2} style={{ fontSize: '10px', verticalAlign: 'middle', padding: '2px', minWidth: '20px' }}>{itemStatus.statusName}</td>
                                                                <td style={{ fontSize: '10px', verticalAlign: 'middle', padding: '2px' }}>SL</td>
                                                                {
                                                                    dateString && dateString.length > 0 && dateString.map((itemDate, itemDateIndex) => (<>
                                                                        <td style={{ textAlign: 'right', verticalAlign: 'middle', fontSize: '10px', padding: '2px' }}>
                                                                            {
                                                                                itemStatus.dateQuantities.find(x => x.dateName == itemDate)?.quantity || null
                                                                            }
                                                                        </td>
                                                                    </>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: '10px', verticalAlign: 'middle', padding: '2px' }}>Điểm</td>
                                                                {
                                                                    dateString && dateString.length > 0 && dateString.map((itemDate, itemDateIndex) => (<>
                                                                        <td style={{ textAlign: 'right', verticalAlign: 'middle', padding: '2px', fontSize: '10px' }}>
                                                                            {
                                                                                formatNumber(itemStatus.dateQuantities.find(x => x.dateName == itemDate)?.point) || null
                                                                            }
                                                                        </td>
                                                                    </>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </>
                                                    ))
                                                }

                                            </>
                                        ))
                                    }
                                </tbody>
                            </table>
                        }
                    </div>

                    <LoadingViewAria loading={loadingSwat} />
                </div>
                {
                    scrollTableWidth ?
                        <div className='scrollRoot'
                            onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                        >
                            <div style={{ width: `${scrollTableWidth}px` }}>
                                &nbsp;
                            </div>
                        </div> : <></>
                }
            </div>
        </div>
    )
}