import React, { useEffect, useState, useRef } from 'react';
import Select from "react-select";

import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";

import * as swatTrundoManagementAction from "../../../redux/store-sarang/swat-trundo/swat-trundo-management.store";
import * as festivalManagementAction from "../../../redux/store-sarang/festival-cog/festival-cog-management.store";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import BodyReport from './body-report';

export default function SwatTrundoSumaryZionReport() {
    //const userInfo = getUserInfo();
    const [loadingSwat, setLoadingSwat] = useState();

    const [zionSumary, setZionSumary] = useState();
    const [festivalCog, setFestivalCog] = useState([]);
    const [festivalId, setFestivalId] = useState();
    const [totalItem, setTotalItem] = useState(0);
    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(100);

    useEffect(() => {
        getLookupFestival();
        GetSumaryZion();
    }, [])

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem]);

    const getLookupFestival = async () => {
        try {
            const res = await festivalManagementAction.GetLookupFestivalCog();
            if (res && res.content) {
                setFestivalCog(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    };

    const GetSumaryZion = async (festivalCogId) => {
        setLoadingSwat(true);
        try {
            const res = await swatTrundoManagementAction.GetSumaryZion(festivalCogId);

            if (res && res.content) {
                setZionSumary(res.content);
                setTotalItem(res.content.length);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoadingSwat(false);
        }
    };

    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    {festivalCog ? <div className="form-group col-12 col-md-6 col-lg-6  col-xl-6">
                        <label>Lễ hội trundo</label>
                        <Select
                            isClearable
                            placeholder="Chọn lễ hội trundo"
                            closeMenuOnSelect={true}
                            onChange={(data) => {
                                setFestivalId(data ? data.value : null);
                                GetSumaryZion(data ? data.value : null);
                            }}
                            options={festivalCog}
                            noOptionsMessage={() => "Không tồn tại"}
                        />
                    </div>
                        :
                        <></>
                    }
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <BodyReport
                        scrollRoot={scrollRoot}
                        scrollTable={scrollTable}
                        nameString="Địa vực"
                        data={zionSumary}
                        columnName="zionName"
                    />

                    <LoadingViewAria loading={loadingSwat} />
                </div>
                {
                    scrollTableWidth ?
                        <div className='scrollRoot'
                            onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                        >
                            <div style={{ width: `${scrollTableWidth}px` }}>
                                &nbsp;
                            </div>
                        </div> : <></>
                }
            </div>
        </div>
    )
}