const DepartmentHelper = {
    PrepareSortData: (data = []) => {
        data.forEach((item, _) => {
            item.children = [];
        });
        
        data.forEach((item, _) => {
            if( item.parentId ) {
                const parent = data.find(parent => parent.id === item.parentId);
                if ( !parent ) {
                    item.level --;
                    item.parentId = null;
                    return;
                };
                parent.children.push(item);
            }
        });
        return data.filter((item,_) => !item.parentId);
    },
    RenderOptions : (options) => {
        const flattenOptions = [];
    
        const flatten = (option) => {
            const currentOption = {
                label: option.name,
                value: option.id,
                level: option.level,
                code: option.code,
                parentId: option.parentId
            };
            flattenOptions.push(currentOption);
    
            if (option.children && option.children.length > 0) {
                option.children.forEach(child => flatten(child));
            }
        };
    
        options.forEach(option => flatten(option));
    
        return flattenOptions;
    }
}

export default DepartmentHelper; 