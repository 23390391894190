import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListPosition = (pageIndex = 1, pageSize = 10, sortExpression = "", code = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    // code && params.append("code", code);
    // name && params.append("name", name);
    return service.get(ApiUrl.GetListPosition, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailPosition = (id) => {
    return service.get(ApiUrl.GetDetailPosition.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreatePosition = (data) => {
    return service.post(ApiUrl.CreatePosition, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdatePosition = (data) => {
    return service.put(ApiUrl.UpdatePosition, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePosition = (id) => {
    return service.delete(ApiUrl.DeletePosition.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentPosition = (id) => {
    return service.delete(ApiUrl.DeletePermanentPosition.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActivePosition = (id) => {
    return service.put(ApiUrl.ActivePosition.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActivePosition = (id) => {
    return service.put(ApiUrl.DeActivePosition.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}

export const GetLookupPosition = () => {
    return service.get(ApiUrl.GetLookupPosition).then((res) => { return res }).catch((err) => { throw err });
}