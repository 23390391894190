import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import * as appActions from "../../core/app.store";
import * as config from '../../common/config'
import Select from "react-select";

import { headCellsHerdsmanPoint, headCellsHerdsmanPointDaily } from './head-cell-list';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, sendReactGaEvent, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import dayjs from 'dayjs';

import * as reportAcademyAction from "../../redux/store-sarang/report-academy/report-academy-management.store";
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import * as zionManagementAction from "../../redux/store-sarang/zion/zion-management.store";
import ReportZionSumaryAcademy from "./components/report-zion-sumaries-academy";
import ReportHerdsmanSumaryAcademy from "./components/report-herdsman-sumaries-academy";
import { useMediaQuery } from 'react-responsive';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function ReportSumaryAcademy() {
    const { register, handleSubmit, errors, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    //const userInfo = getUserInfo();
    // -- Data to map with ids
    const [sumaryPoint, setSumaryPoint] = useState([]);
    const [dailyPoint, setDailyPoint] = useState([]);
    const [showIndexHerdsman, setShowIndexHerdsman] = useState(-1);
    const [selectedHerdsman, setSelectedHerdsman] = useState(null);
    const [week, setWeek] = useState();
    const [weekNumber, setWeekNumber] = useState();

    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(0);
    const [totalItem, setTotalItem] = useState();
    const [tabValue, setTabValue] = React.useState(0);
    const [zion, setZion] = useState([]);
    const [zionId, setZionId] = useState([]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const GetCurrentWeek = async () => {
        try {
            const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
                setWeekNumber(res.content.week);
            }
        } catch (err) {
            ;

        } finally {
            ;
        }
    };

    const getLookupZion = async () => {
        try {
            const res = await zionManagementAction.GetLookupZion();
            if (res && res.content) {
                setZion(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        getLookupZion();
        reportAcademyAction.SumaryPoint(1);
        GetCurrentWeek();
    }, []);

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem]);

    const handleWeekChange = async (event) => {
        setWeekNumber(event.target.value);
        //GetAllListHerdsmanRank(event.target.value);
    }

    const handleZionChange = async (zionValue) => {
        setZionId(zionValue);
        //GetAllListHerdsmanRank(event.target.value);
    }

    const renderTableForDesktop = () => {
        return <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            <select className="form-control" name="tuan" onChange={(e) => handleWeekChange(e)}>
                                <option value={2} selected={week?.week == 2}>Tuần 2</option>
                                <option value={3} selected={week?.week == 3}>Tuần 3</option>
                                <option value={4} selected={week?.week == 4}>Tuần 4</option>
                                <option value={5} selected={week?.week == 5}>Tuần 5</option>
                                <option value={10}>Tổng hợp lễ hội</option>
                            </select>
                        </div>
                        <div className="col-6">
                            <Select
                                isClearable
                                {...register("zionId", { required: true })}
                                placeholder="Chọn địa vực"
                                closeMenuOnSelect={true}
                                onChange={(data) => handleZionChange(data?.value)}
                                options={zion.map(item => { return { label: item.name, value: item.id } })}
                                noOptionsMessage={() => "Không tồn tại"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label="Cá nhân" {...a11yProps(0)} />
                        <Tab label="Địa vực" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <ReportHerdsmanSumaryAcademy
                        zionId={zionId}
                        week={week}
                        weekNumber={weekNumber}
                    />
                    {/*
                    <div className="row text-center">
                        <div className="col-12 mb-2 dataTables" ref={scrollRoot}>
                            <table className="table dataTable table-hover " id="datatable" ref={scrollTable} >
                                <thead>
                                    <tr>
                                        {
                                            headCellsHerdsmanPoint.map((item) =>
                                                <td key={item.id}
                                                    style={item.style ? { ...item.style, fontWeight: 'bold', textAlign: 'center' } : { fontWeight: 'bold', textAlign: 'center' }}
                                                ><b>{item.label}</b></td>)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.map((item, index) => <>
                                            {
                                                renderRow(item, index)
                                            }
                                        </>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                    {
                        scrollTableWidth ?
                            <div className='scrollRoot'
                                onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                            >
                                <div style={{ width: `${scrollTableWidth}px` }}>
                                    &nbsp;
                                </div>
                            </div> : <></>
                    }
                    */}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <ReportZionSumaryAcademy
                        zionId={zionId}
                        week={week}
                        weekNumber={weekNumber }
                    />
                </CustomTabPanel>
            </div>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    renderTableForDesktop()
                }
            </div>
        </div>
    )
}