import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsSendToShipper from './head-cell-send-to-shipper';
import DataTableSarang from '../../components/datatable-sarang';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Popover from '@mui/material/Popover';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as transportOrderManagementAction from "../../redux/store-sarang/transport-order/transport-order-management.store";
import * as cuisineOrderManagementAction from "../../redux/store-sarang/cuisine-order/cuisine-order-management.store";
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";

//import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import Select from "react-select";
import { optionsTransportOrderStatus, optionsReceiptTime } from '../../common/constant';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import * as styles from './custome-style.module.scss'
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import { useMediaQuery } from 'react-responsive';
import MobileHeaderSarang from '../../components/mobile-header/mobile-header-sarang';
import Typography from '@mui/material/Typography';
const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "orderDate desc" + (new Date().getHours() <= 13 ? ', receiptTime asc, commune.District.Name asc, commune.Name asc' : ', receiptTime desc, commune.District.Name asc, commune.Name asc'),
    orderBy: "orderDate",
    order: "desc",
}

export default function SendCuisineToShipper() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));
    const now = new Date();

    // -- Data to map with ids
    const [herdsmanAll, setHerdsmanAll] = useState([]);
    const [searchData, setSearchData] = useState();
    const [cuisineData, setCuisineData] = useState([]);

    const getLookupHerdsmanAll = async () => {
        showLoading(true);

        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman(false);
            if (res &&
                res.content &&
                Array.isArray(res.content)
            ) {
                setHerdsmanAll(res.content.filter(item => item.id != currentUser.herdsmanId));
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }

    // -- End Data to map with ids

    const [currentUser] = useState(getUserInfo());
    const [data, setData] = useState([]);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);
    const [openSearch, setOpenSearch] = useState(false);
    const [sumaryCuisine, setSumaryCuisine] = useState();

    useEffect(() => {
        setValue("deliveryFromDate", dayjs(now).format());
        setSearchData({ deliveryFromDate: dayjs(now).format() });
        GetSumaryDelivery({ deliveryFromDate: dayjs(now).format() });
        getLookupHerdsmanAll();
    }, []);

    const GetSumaryDelivery = async (data=undefined) => {
        showLoading(true);
        try {
            const res = await transportOrderManagementAction.GetSumaryDelivery(data);
            if (res &&
                res.content &&
                res.content
            ) {
                setData(res.content);
                let sumaryLunch = 0;
                let sumaryDinner = 0;
                let sumaryQuantity = 0;
                res.content.map((item, index) => {
                    if (item.receiptTime===2)
                        sumaryLunch = sumaryLunch + item.quantity;
                    else if (item.receiptTime === 3)
                        sumaryDinner = sumaryDinner + item.quantity;
                    sumaryQuantity = sumaryQuantity + item.quantity;
                });
                setSumaryCuisine({
                    sumaryLunch: sumaryLunch,
                    sumaryDinner: sumaryDinner,
                    sumaryMorning: 0,
                    sumaryCuisineQuatity: sumaryQuantity,
                });
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    }

    const getListCuisineOrderManagement = async (data) => {
        try {
            const res = await cuisineOrderManagementAction.GetListCuisineOrderByShipper(1, 50, "orderDate desc" + (new Date().getHours() <= 13 ? ', receiptTime asc' : ', receiptTime desc'), data);

            if (res && res.content) {
                setCuisineData(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        }
    };

    const handleClosePopover = () => {
        setCuisineData([]);
        setAnchorEl(null);
    }
    const handleDetailClick = (event, item) => {
        if (item.type==="CUISINE")
            getListCuisineOrderManagement({ orderFromDate: item.deliveryDate, herdsmanId: item.herdsmanDeliveryId, receiptTime: item.receiptTime });
        else
            getListCuisineOrderManagement({ orderFromDate: item.deliveryDate, deliveryHerdsmanId: item.herdsmanDeliveryId, receiptTime: item.receiptTime });
        setAnchorEl(event.currentTarget);
    }

    const handleRequestSort = (event, property) => {
        //const isAsc = orderBy === property && order === 'asc';
        //setOrder(isAsc ? 'desc' : 'asc');
        //setOrderBy(property);
        //let sort = isAsc ? 'desc' : 'asc';
        //let sortExpression = property + ' ' + sort + ((property && property != "receiptTime") ? ', receiptTime asc' : '') + ', commune.District.Name asc, commune.Name asc';
        //GetSumaryDelivery(page + 1, rowsPerPage, sortExpression, searchData);
    };

    const handleChangePage = (newPage) => {
        //setPage(newPage - 1);
        //let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') +', commune.District.Name asc, commune.Name asc';
        //GetSumaryDelivery(newPage, rowsPerPage, sortExpression, searchData);
    };

    const handleChangeRowsPerPage = (event) => {
        //setRowsPerPage(parseInt(event.target.value, 10));
        //setPage(0);
        //let sortExpression = orderBy + ' ' + order + ((orderBy && orderBy != "receiptTime") ? ', receiptTime asc' : '') +', commune.District.Name asc, commune.Name asc';
        //GetSumaryDelivery(1, event.target.value, sortExpression, searchData);
    };

    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [selectedItem, setSelectedItem] = useState(null);

    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }

    const closeConfirmDialog = () => {
        setOpenActiveDialog(false);
        setSelectedItem(null);
    }

    const handleConfirm = async () => {
        try {
            let res = null;
            if (isOpenActiveDialog) {
                res = await transportOrderManagementAction.SetHadSendToShipper({
                    deliveryDate: selectedItem.deliveryDate,
                    receiptTime: selectedItem.receiptTime,
                    herdsmanDeliveryId: selectedItem.herdsmanDeliveryId,
                    type: selectedItem.type,
                });
            }

            // AssignDeliver

            if (res) {
                GetSumaryDelivery(searchData);
            }

            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        }
    }

    const onSubmit = (data) => {
        setSearchData(data);
        GetSumaryDelivery(data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const renderTableForDesktop = () => {
        return <>
            
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} hideNewButton={true}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                sumary={sumaryCuisine}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsSendToShipper}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                        handleDetailClick(event, row);
                                    }}><span>{dayjs(row.deliveryDate).format("DD/MM/YYYY")}</span></td>
                                    <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                        handleDetailClick(event, row);
                                    }}><span>{row.receiptTime === 1 ? "Sáng" : (row.receiptTime === 2 ? "Trưa" : "Chiều")}</span></td>
                                    <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                        handleDetailClick(event, row);
                                    }}><span>{row.herdsmanDeliveryName}</span>
                                        {
                                            row.herdsmanDeliveryPhone &&
                                            <span>&nbsp;(<a href={`tel:${row.herdsmanDeliveryPhone}`}>{row.herdsmanDeliveryPhone}</a>)</span>
                                        }</td>
                                    <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                        handleDetailClick(event, row);
                                    }}><span>{row.quantity}</span> </td>
                                    <td><span>{row.transportStatusName}</span>&nbsp;&nbsp;
                                        <span>
                                            {
                                                row.transportStatus >= 50 ?
                                                    (
                                                        <img title="Đã giao Shipper"
                                                            src={require("../../assets/icon/tick.png")}
                                                            alt="Tick"
                                                        />
                                                    ) : (
                                                        <img title="Chưa giao Shipper, bấm để xác nhận giao" style={{ cursor: 'pointer' }}
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel" onClick={() => {
                                                                setOpenActiveDialog(true);
                                                                openConfirmDialog(row);
                                                            }}
                                                        />
                                                    )
                                            }
                                        </span></td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <MobileHeaderSarang
                    headCell={headCellsSendToShipper}
                    handleSortChange={(sortExpression) => GetSumaryDelivery(searchData)}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                />
                <div id="accordion">
                    {
                        data.length > 0 ?
                            data.map((item, index) => (
                                <div className="card" key={index}>
                                    <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                            data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                                            <div className="d-flex">
                                                <h5 className="mb-1">{item.receiptTime === 1 ? "Sáng" : (item.receiptTime === 2 ? "Trưa" : "Chiều")}&nbsp;
                                                    {dayjs(item.deliveryDate).format("DD/MM/YYYY")}</h5>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='mt-0'><b>Ace Shipper: </b>
                                                        <span>{item.herdsmanDeliveryName}</span>
                                                        {
                                                            item.herdsmanDeliveryPhone && 
                                                            <span>&nbsp;(<a href={`tel:${item.herdsmanDeliveryPhone}`}>{item.herdsmanDeliveryPhone}</a>)</span>
                                                        }
                                                    </div>
                                                    <div className='mt-0'><b>Số lượng: </b>
                                                        <span>{item.quantity}</span>
                                                        <button className="d-inline-block btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="top"
                                                            title="Xem chi tiết"
                                                            onClick={(event) => {
                                                                handleDetailClick(event, item);
                                                            }}
                                                        >
                                                            <i className="fas fa-list-alt"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='mt-2'><b>Trạng Thái: </b> {item.transportStatusName}</div>
                                                    <div className='mt-0'>
                                                        {
                                                            item.transportStatus >= 50 ?
                                                                (
                                                                    <img title="Đã giao Shipper"
                                                                        src={require("../../assets/icon/tick.png")}
                                                                        alt="Tick"
                                                                    />
                                                                ) : (
                                                                    <img title="Chưa giao Shipper" style={{ cursor: 'pointer' }}
                                                                        src={require("../../assets/icon/cancel.png")}
                                                                        alt="Cancel" onClick={() => {
                                                                            setOpenActiveDialog(true);
                                                                            openConfirmDialog(item);

                                                                        }}
                                                                    />
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} hideNewButton={true}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderFormSearch = () => {
        return <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                    <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                        <label>Từ ngày</label>
                        <DatePicker
                            {...register("deliveryFromDate")}
                            onChange={(data) => setValue("deliveryFromDate", dayjs(data).format())}
                            defaultValue={dayjs(now)}
                            className="form-control"
                            format='DD/MM/YYYY'
                            slotProps={{ textField: { size: 'small' } }}
                        //label={"Nhập Ngày nhận"}
                        />
                    </div>

                    <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                        <label>Từ ngày</label>
                        <DatePicker
                            {...register("deliveryToDate")}
                            onChange={(data) => setValue("deliveryToDate", dayjs(data).format())}
                            className="form-control"
                            format='DD/MM/YYYY'
                            slotProps={{ textField: { size: 'small' } }}
                        //label={"Nhập Ngày nhận"}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label>Thời gian <span className='required'>*</span></label>
                        <Select
                            {...register("receiptTime", {})}
                            placeholder="Thời gian nhận"
                            closeMenuOnSelect={true}
                            onChange={(data) => setValue("receiptTime", data ? data.value : null)}
                            options={optionsReceiptTime}
                            noOptionsMessage={() => "Không tồn tại"}
                            isClearable
                            isSearchable={false }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label htmlFor="input-department-general">Swat</label>
                        <Select
                            isClearable
                            {...register("herdsmanDeliveryId", {})}
                            placeholder="Swat"
                            onChange={(data) => setValue("herdsmanDeliveryId", data ? data.value : null)}
                            options={herdsmanAll.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="input-department-general">Trạng thái</label>
                        <Select
                            isClearable
                            placeholder="Trạng thái"
                            {...register("transportStatus", {})}
                            onChange={(data) => {
                                setValue("transportStatus", data ? data.value : null);
                            }}
                            isSearchable={false}
                            options={optionsTransportOrderStatus.filter(x=>x.value===45 || x.value===50)}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>

                </div>
                <FormSearchFunctionButtons>
                    <div className="row">
                        <div className="col-sm-12 pl-0">
                            <p className="text-center">
                                <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                <button type="button" className="btn btn-space btn-secondary" onClick={() => { }}>Xóa lọc</button>
                            </p>
                        </div>
                    </div>
                </FormSearchFunctionButtons>
            </form>
        </>
    }

    return (
        <><Popover
                id='buoc1'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="mt-2 mb-2 ml-2 mr-2">
                {
                    cuisineData.length > 0 ? cuisineData.map((row, index) => (
                        <div>{index + 1}. {row.orderCode} - SL: <b>{row.quatity}</b> {row.deliveryType == 0 ? <>- {row.address} - {row.communeName}, {row.districtName}</> : <>-Tự lấy</>} </div>
                    )) : <></>
                    }
                </div>
            </Popover>
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>


            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenActiveDialog ? "Ae/Ce có chắc chắn muốn xác nhận đã giao cho Ae Shipper: " +
                        selectedItem.herdsmanDeliveryName + " " + (selectedItem.receiptTime === 1 ? "Sáng " : (selectedItem.receiptTime === 2 ? "Trưa " : "Chiều ")) +
                        dayjs(selectedItem.deliveryDate).format("DD/MM/YYYY") + " Số lượng: <b>" + selectedItem.quantity + "</b>" : ""
                }
                open={isOpenActiveDialog }
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
            </div>
        </>
    )
}