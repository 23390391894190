import React, { useCallback, useEffect, useRef, useState } from 'react';
import { formatDate } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS, INITIAL_TASKS, copyTaskToEvent, createEventId } from './event-utils';
import viLocale from '@fullcalendar/core/locales/vi';
import './calendar-contact.css'
import ModalAddEditTask from '../add-edit-task/modal-add-edit-task';
import FormAddEditTask from '../add-edit-task/form-add-edit-task';
import { Link } from 'react-router-dom';
import { UrlCollectionSarang } from '../../../common/url-collection-sarang';
import * as taskCalendarManagementAction from "../../../redux/store-sarang/task-calendar/task-calendar-management.store";
import { useDispatch } from 'react-redux';
import * as appActions from "../../../core/app.store";
import * as config from '../../../common/config'
import { NotificationMessageType, getUserInfo } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";

const locale = 'vi';

const configLocal = {
    defaultPageSize: 100,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

const CalendarContact = (props) => {
  const { customer, herdsman, handleSubmitAddEditTask } = props;

  const invisibleLinkRef = useRef(null);
  const [isOpenAddEditTask, setIsOpenAddEditTask] = useState(false);
  const buttonOpenAddEditTask = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [currentEvents, setCurrentEvents] = useState([]);//INITIAL_EVENTS
  const currentEventsRef = useRef([]);
  useEffect(() => {currentEventsRef.current = currentEvents}, [currentEvents])

    const [updateItem, setUpdateItem] = useState(null);
    const [taskDate, setTaskDate] = useState(new Date());
  const updateItemRef = useRef(null);

    useEffect(() => { updateItemRef.current = updateItem }, [updateItem]);
    useEffect(() => { getListTaskManagement(); }, []);

    const calendarRef = useRef(null);
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    const getListTaskManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, code = "", name = "") => {
        showLoading(true);
        try {
            const res = await taskCalendarManagementAction.GetListTask(pageIndex, pageSize, sortExpression, code.trim(), name.trim());

            if (res && res.content && res.content.items) {
                setCurrentEvents(res.content.items);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

  const handleDateSelect = (selectInfo) => {
    // let title = prompt('Please enter a new title for your event');
    // let calendarApi = selectInfo.view.calendar;

    // calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay,
    //   });
      // }
      var selectDate = new Date(selectInfo.startStr);
      if (selectDate >= new Date(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate())) {
          setTaskDate(selectDate);
          buttonOpenAddEditTask.current.click();
      }
          
  };

  const handleEventClick = ({event}) => {
    const evtObj = event.toPlainObject();
    //console.log("sadsadasdsa", evtObj);

    // const clickedTask = currentTasksRef.current.filter(item => item.id == evtObj.id)[0];
      setUpdateItem({
          ...evtObj.extendedProps, title: evtObj.title, id: evtObj.id });

    buttonOpenAddEditTask.current.click();
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const handleChangeEvents = useCallback(async ({event}) => {
    try {
        const evtObj = event.toPlainObject();
        console.log("adasdasdas", evtObj);
    //   const beingChangedTask = currentTasksRef.current.filter(item => item.id == evtObj.id)[0];
    //   const appliedChangesTask = copyEventToTask(evtObj, beingChangedTask);
    //   await ToDoListApiService.UpdateTask(appliedChangesTask);
      
    //   setCurrentEvents(prevEvents => {
    //     return prevEvents.map(item => {
    //       if (item.id == evtObj.id) {
    //         return { ...evtObj, "durationEditable": !evtObj.allDay };
    //       }
    //       return item;
    //     });
    //   });

    //   setCurrentTasks(prevTasks => {
    //     return prevTasks.map(item => {
    //       if (item.id == evtObj.id) {
    //         return appliedChangesTask;
    //       }
    //       return item;
    //     });
    //   })
    } catch (err) {
      console.log("handleChangeEvents", err);
    }
  }, [setCurrentEvents])
  
  // const renderSidebarEvent = (event) => (
  //   <li key={event.id}>
  //     <b>{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
  //     <i>{event.title}</i>
  //   </li>
  // );

  // const renderSidebar = () => (
  //   <div className='demo-app-sidebar'>
  //     <div className='demo-app-sidebar-section'>
  //       <button ref={buttonOpenAddEditTask} type="button" className="d-none btn btn-primary" data-toggle="modal" data-target="#modalAddEditTask" onClick={openAddEditTaskModal}>
  //           Launch modal
  //       </button>
  //       <h2>Tất cả công việc ({currentEvents.length})</h2>
  //       <ul>{currentEvents.map(renderSidebarEvent)}</ul>
  //     </div>
  //   </div>
  // );

  // SUBMIT CREATE
  const onSubmitAddTask = useCallback((task) => {
    try {
      const tempTask = {...task, id: createEventId()}
      console.log("tempTask", tempTask);
      // setCurrentTasks(prevTasks => [...prevTasks, tempTask]);
      setCurrentEvents(prevEvents => [...prevEvents, copyTaskToEvent(tempTask)]);
    } catch (err) {
      console.log("onSubmitAddTask from modal", err);
    }
  }, [setCurrentEvents])

  const openAddEditTaskModal = () => {
    setIsOpenAddEditTask(true);
  }

  const closeAddEditTaskModal = () => {
    setIsOpenAddEditTask(false);
    setUpdateItem(null);
  }

  return (
    <div className='demo-app'>
      <div className='demo-app-main'>
        <FullCalendar
          ref={calendarRef}
          locale={viLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          eventDurationEditable={true}
          slotDuration={'00:15:00'}
          firstDay={0}
          // feed data to calendar
          events={[...currentEvents]}

          // calendar actions
          eventContent={renderEventContent}
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventChange={handleChangeEvents}
          
          // Custom button
          customButtons={{
            customButton: {
              text: 'Danh sách',
              click: () => {invisibleLinkRef.current.click();}
            }
          }}
          // Add the custom button to the toolbar
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay customButton',
          }}
        />
      </div>
      
      {/* {renderSidebar()} */}
      <button ref={buttonOpenAddEditTask} type="button" className="d-none btn btn-primary" data-toggle="modal" data-target="#modalAddEditTask" onClick={openAddEditTaskModal}>
           Launch modal
      </button>
      <ModalAddEditTask 
        open={isOpenAddEditTask}
        onClose={closeAddEditTaskModal}
        updateItem={updateItem}
      >
        <FormAddEditTask
          customer={customer}
          herdsman={herdsman}
          taskDate={taskDate}
          // ===
          updateItem={selectedItem}
          handleSubmitAddEditTask={(data) => handleSubmitAddEditTask(data, getListTaskManagement)}
        />
      </ModalAddEditTask>

      <Link className={`d-none`} ref={invisibleLinkRef} to={UrlCollectionSarang.CalendarContact}>To List</Link>
    </div>
  );
};

const renderEventContent = (eventInfo) => (
  <>
    <b>{eventInfo.timeText}</b>
    <i>{eventInfo.event.title}</i>
  </>
);

export default CalendarContact;
