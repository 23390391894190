import React, { useEffect, useState } from 'react';
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
export default function MyDepartmentExaminationWidget(props) {
    const { myDepartmentRank, loading, userInfo, week } = props;

    useEffect(() => {

    }, []);


    return (
        <>
            {myDepartmentRank &&
                <div className="card">
                    <h5 className="card-header">Đội (tuần {week?.week}): {myDepartmentRank?.departmentName}</h5>
                    <div className="row card-body text-center">
                        <div className="col-12">
                            <span>Điểm: <b>{Math.round((myDepartmentRank?.totalPoint) * 100) / 100}</b></span>&nbsp;
                            <span>Xếp hạng: <b>{myDepartmentRank?.rank}</b></span>
                        </div>
                        <div className="col-12">
                            <table className="table dataTable" id="datatable">
                                <thead>
                                    <tr>
                                        <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                        <td style={{ minWidth: "50px" }}><b>#</b></td>
                                        <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        myDepartmentRank?.rP_Examination_Report_Dtos && myDepartmentRank?.rP_Examination_Report_Dtos.length > 0 && myDepartmentRank?.rP_Examination_Report_Dtos.map((item, index) =>
                                            <tr>
                                                <td style={{ minWidth: "100px" }}>{optionsExamination.find(x => x.value == item.status)?.label}</td>
                                                <td style={{ minWidth: "50px" }}>{item.quantity}</td>
                                                <td style={{ minWidth: "50px" }}>{Math.round((item.quantity * item.point) * 100) / 100}</td>
                                            </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <LoadingViewAria loading={loading} />
                </div>
            }
        </>
    )
}