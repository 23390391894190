const headCellsListZion = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: false,
        label: 'Tên địa vực',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'memberCount',
        numeric: false,
        disablePadding: false,
        label: 'SL t/đồ',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: false,
        label: 'Ghi chú',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'IsActived',
        numeric: false,
        disablePadding: false,
        label: 'Trạng thái',
        className: 'pt-3 pb-3',
        style: { width: "70px" }
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
        style: { width: "60px" }
    },
];

export default headCellsListZion;