const headCellsSendToShipper = [
    {
        id: 'DeliveryDate',
        numeric: false,
        disablePadding: true,
        label: 'Ngày',
        className: 'pt-3 pb-3',
    },
    {
        id: 'ReceiptTime',
        numeric: false,
        disablePadding: true,
        label: 'Thời gian',
        className: 'pt-3 pb-3',
        style: { minWidth: "60px" }
    },
    {
        id: 'HerdsmanDeliveryName',
        numeric: false,
        disablePadding: true,
        label: 'AE Shipper',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Quantity',
        numeric: false,
        disablePadding: true,
        label: 'Số lượng',
        className: 'pt-3 pb-3',
    },
    {
        id: 'TransportStatusName',
        numeric: false,
        disablePadding: true,
        label: 'Trạng Thái',
        className: 'pt-3 pb-3',
    },
];

export default headCellsSendToShipper;