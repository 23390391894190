import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";

export default function DepartmentTreeSelect(props) {
    const { onChange, selected, options, placeholder, menuPortalTarget, isDisabled } = props;

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
            paddingLeft: `${state.data.level * 20}px`, // Adjust padding based on the level
            zIndex: 9999,
        }),
    };

    return (
        <Select
            isClearable
            defaultValue={selected}
            placeholder={placeholder || "Để trống nếu là ban/ngành gốc"}
            closeMenuOnSelect={true}
            onChange={onChange}
            options={options}
            noOptionsMessage={() => "Không tồn tại"}
            styles={customStyles}
            menuPortalTarget={menuPortalTarget}
            isDisabled={isDisabled }
        />
    )
}