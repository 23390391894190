import React, { useEffect, useState, useRef } from 'react';
import { getUserInfo } from '../../utils/configuration';
import ReactPlayer from 'react-player/lazy';
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import HerdsmanRankCupWidget from '../examination-dashboard/components/herdsman-rank-cup-widget';
import {
    ShowXepHang
} from "../../utils/configuration";
;export default function ExamHerdsmanReport() {
    //var userRole: string;
    const userInfo = getUserInfo();
    const [week, setWeek] = useState();
    const [month, setMonth] = useState();
    const dayOfWeek = new Date().getDay();
    const hour = new Date().getHours();
    const limit = 500;
    useEffect(() => {
        //setTimeout(() => {
        //    const elements = document.getElementsByClassName("highcharts-credits");
        //    const elementsArray = Array.from(elements);

        //    elementsArray.forEach(element => {
        //        element.style.display = "none";
        //    });
        //}, 100);
        GetCurrentWeek();
    }, [])

    const GetCurrentWeek = async () => {
        try {
            const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
                if (res.content.month == 9)
                    setMonth({ month: 8, year: res.content.year, week: undefined });
                else
                    setMonth({ ...res.content, week: undefined });
            }
        } catch (err) {
            ;
            
        } finally {
            ;
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <HerdsmanRankCupWidget
                        week={week}
                        limit={limit}
                    />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <HerdsmanRankCupWidget
                        week={month}
                        limit={1000}
                    />
                </div>
            </div>
           
        </>
    )
}