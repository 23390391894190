import React, { useEffect, useState } from 'react';
import "./datatable.scss";
import * as config from '../../common/config'


export default function DataTableButtonsFunction(props) {
    const {
        rowsPerPage,
        handleChangeRowsPerPage,
        sumary
    } = props;
    return (
        <div className="row">
            <div className="col-12 col-md-9 mb-2">
                {sumary ?
                    <div className="dt-buttons d-flex" style={{ gap: "5px" }}>
                        <table className="table table-bordered table-hover">
                            <tbody>
                                <tr>
                                    {
                                        sumary?.sumaryShipperCuisine ?
                                            <td>Đã nhận giao: <b>{sumary?.sumaryShipperCuisine}</b> - Trưa: <b>{sumary?.sumaryShipperLunch}</b> - Chiều: <b>{sumary?.sumaryShipperDinner}</b></td> :
                                            (sumary?.sumaryCuisineOrder ? <td>Tổng SL đơn: <b>{sumary?.sumaryCuisineOrder}</b> - Trưa: <b>{sumary?.sumaryLunchOrder}</b> - Chiều: <b>{sumary?.sumaryDinnerOrder}</b></td> : <></>)
                                    }
                                    <td>Tổng suất ăn: <b>{sumary?.sumaryCuisineQuatity}</b> - Trưa: <b>{sumary?.sumaryLunch}</b> - Chiều: <b>{sumary?.sumaryDinner}</b></td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div> : 
                    <div className="dt-buttons d-flex" style={{ gap: "5px" }}>
                        <button className="btn btn-outline-light buttons-copy" tabIndex="0" aria-controls="table_buttons_extension" type="button">
                            <span>Copy</span>
                        </button>
                        <button className="btn btn-outline-light buttons-excel" tabIndex="0" aria-controls="table_buttons_extension" type="button">
                            <span>Excel</span>
                        </button>
                        <button className="btn btn-outline-light buttons-pdf" tabIndex="0" aria-controls="table_buttons_extension" type="button">
                            <span>PDF</span>
                        </button>
                        <button className="btn btn-outline-light buttons-print" tabIndex="0" aria-controls="table_buttons_extension" type="button">
                            <span>In</span>
                        </button>
                        <button className="btn btn-outline-light buttons-collection dropdown-toggle buttons-colvis" tabIndex="0" aria-controls="table_buttons_extension" type="button" aria-haspopup="true">
                            <span>Ẩn/ hiện cột</span>
                        </button>
                    </div>}
                
            </div>
            <div className="col-12 col-md-3 mb-2 ">
                <label className='d-flex align-items-end justify-content-end' style={{ gap: "10px" }}>
                    Xem 
                    <select
                        className="custom-select custom-select-sm form-control form-control-sm"
                        style={{width: "50px"}}
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                    >
                        {config.Configs.DefaultPageSizeOption.map((pageSize, index) => (
                            <option key={index} value={pageSize}>{pageSize}</option>
                        ))}
                    </select> 
                    mục
                </label>
            </div>
        </div>
    )
}