import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';

export default function FormSendMessage(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, herdsman, 
        onSubmitSendMessage } = props;
    const buttonSubmitRef = useRef(null);

    useEffect(() => {
        setValue("herdsmanId", herdsman?.id);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }

        if (data.Image && data.Image.length > 0) //Gán file vào data
            data.Image = data.Image[0];
        else
            data.Image = null;
        onSubmitSendMessage({ ...data, herdsmanId: herdsman?.id});
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                    <div className="form-group col-12">
                        <label><b>Nhắn tin đến: </b>{herdsman?.fullName }</label>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <label><b>Nội dung</b><span className='required'>*</span></label>
                        <textarea
                            rows="6"
                            className="form-control"
                            type="text"
                            name="content"
                            placeholder="Nội dung"
                            maxLength={1500}
                            ref={register({ required: true })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-12">
                        <label>Ảnh đính kèm</label>
                        <input
                            className="note-image-input form-control-file note-form-control note-input"
                            type="file"
                            name="Image"
                            ref={register()}
                            accept="image/*"
                        />
                    </div>
                </div>


                <button ref={buttonSubmitRef} style={{ display: "none" }} type="submit" className="btn btn-primary">
                    Lưu
                </button>
            </form>
        </>
    )
}