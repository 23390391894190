/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {
  DomainAdminSide,
  TokenKey,
  getUserInfo,
  removeCookies,
  APIUrlDefault,
  setCookies,
} from "../../utils/configuration";
import { Link } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
import * as clientSettingAction from "../../redux/store/client_setting/client_setting.store";
import * as accAction from "../../redux/store/account/account.store";
import * as appActions from "../../core/app.store";
import { modules } from "../../common/profileModules";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: getUserInfo() ? true : false,
      user: getUserInfo(),
      screenAllow: [],
    };
    this.interval = 0;
  }

  componentDidMount() {
    this.GetScreenAllow();
  }

  GetScreenAllow() {
    accAction.GetScreenAllow().then(res => {
      this.setState({
        screenAllow: modules.filter(item => {
          if (res.content.some(x => x.code === item.code))
            return item
        })
      })
    }).catch(error => console.log(error))
  }

  UNSAFE_componentWillMount() {
    const { isLogin, user } = this.state;
    if (isLogin && user && user.userRole) {
      if (user.email.toLowerCase() === "xinykien_sonla@gmail.com")
        window.location.replace(DomainAdminSide + "/dang-nhap");
      else return;
    } else {
      removeCookies("screenAllow");
      removeCookies("isShowDialog");
      removeCookies("isLockScreen");
      removeCookies(TokenKey.token);
      removeCookies(TokenKey.refreshToken);
      removeCookies(TokenKey.returnUrl);
      setCookies(TokenKey.returnUrl, window.location.href);
      window.location.replace(DomainAdminSide + "/dang-nhap");
    }
  }

  onLogout() {
    removeCookies("screenAllow");
    removeCookies("isShowDialog");
    removeCookies("isLockScreen");
    removeCookies(TokenKey.token);
    removeCookies(TokenKey.refreshToken);
    removeCookies(TokenKey.returnUrl);
    window.location.replace(DomainAdminSide);
  }

  render() {
    const { user, screenAllow } = this.state;
    const { settings, setToggle, expandSidebar, collapseSidebar, isCollapsed, reactMediaQuery } = this.props;

    return (
      <div className="dashboard-header">
        <nav className="navbar navbar-expand-lg bg-white fixed-top">
                <a className="navbar-brand" href="/">SSM LVQ 2024</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto navbar-right-top">
                    <li className="nav-item">
                        <div id="custom-search" className="top-search-bar">
                            <input className="form-control" type="text" placeholder="Search.."/>
                        </div>
                    </li>
                    <li className="nav-item dropdown notification">
                        <a className="nav-link nav-icons" href="#" id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-fw fa-bell"></i> <span className="indicator"></span></a>
                        <ul className="dropdown-menu dropdown-menu-right notification-dropdown">
                            <li>
                                <div className="notification-title"> Notification</div>
                                <div className="notification-list">
                                    <div className="list-group">
                                        <a href="#" className="list-group-item list-group-item-action active">
                                            <div className="notification-info">
                                                <div className="notification-list-user-img"><img src="assets/images/avatar-2.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
                                                <div className="notification-list-user-block"><span className="notification-list-user-name">Jeremy Rakestraw</span>accepted your invitation to join the team.
                                                    <div className="notification-date">2 min ago</div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="#" className="list-group-item list-group-item-action">
                                            <div className="notification-info">
                                                <div className="notification-list-user-img"><img src="assets/images/avatar-3.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
                                                <div className="notification-list-user-block"><span className="notification-list-user-name">John Abraham </span>is now following you
                                                    <div className="notification-date">2 days ago</div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="#" className="list-group-item list-group-item-action">
                                            <div className="notification-info">
                                                <div className="notification-list-user-img"><img src="assets/images/avatar-4.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
                                                <div className="notification-list-user-block"><span className="notification-list-user-name">Monaan Pechi</span> is watching your main repository
                                                    <div className="notification-date">2 min ago</div>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="#" className="list-group-item list-group-item-action">
                                            <div className="notification-info">
                                                <div className="notification-list-user-img"><img src="assets/images/avatar-5.jpg" alt="" className="user-avatar-md rounded-circle"/></div>
                                                <div className="notification-list-user-block"><span className="notification-list-user-name">Jessica Caruso</span>accepted your invitation to join the team.
                                                    <div className="notification-date">2 min ago</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="list-footer"> <a href="#">View all notifications</a></div>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown connection">
                        <a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="fas fa-fw fa-th"></i> </a>
                        <ul className="dropdown-menu dropdown-menu-right connection-dropdown">
                            <li className="connection-list">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                        <a href="#" className="connection-item"><img src="assets/images/github.png" alt="" /> <span>Github</span></a>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                        <a href="#" className="connection-item"><img src="assets/images/dribbble.png" alt="" /> <span>Dribbble</span></a>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                        <a href="#" className="connection-item"><img src="assets/images/dropbox.png" alt="" /> <span>Dropbox</span></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                        <a href="#" className="connection-item"><img src="assets/images/bitbucket.png" alt=""/> <span>Bitbucket</span></a>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                        <a href="#" className="connection-item"><img src="assets/images/mail_chimp.png" alt="" /><span>Mail chimp</span></a>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                                        <a href="#" className="connection-item"><img src="assets/images/slack.png" alt="" /> <span>Slack</span></a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="conntection-footer"><a href="#">More</a></div>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown nav-user">
                        <a className="nav-link nav-user-img" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="assets/images/avatar-1.jpg" alt="" className="user-avatar-md rounded-circle"/></a>
                        <div className="dropdown-menu dropdown-menu-right nav-user-dropdown" aria-labelledby="navbarDropdownMenuLink2">
                            <div className="nav-user-info">
                                <h5 className="mb-0 text-white nav-user-name">John Abraham </h5>
                                <span className="status"></span><span className="ml-2">Available</span>
                            </div>
                            <a className="dropdown-item" href="#"><i className="fas fa-user mr-2"></i>Account</a>
                            <a className="dropdown-item" href="#"><i className="fas fa-cog mr-2"></i>Setting</a>
                            <a className="dropdown-item" href="#"><i className="fas fa-power-off mr-2"></i>Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.clientSetting.clientSetting,
  isCollapsed: state.app.isCollapsed,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSettings: clientSettingAction.getSettings,
      expandSidebar: appActions.ExpandSidebar,
      collapseSidebar: appActions.CollapseSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
