import React, { useEffect, useState, useRef } from 'react';
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import DeliveryRankCupWidget from '../examination-dashboard/components/delivery-rank-cup-widget';
;export default function ExamDeliveryReport() {
    //const userInfo = getUserInfo();
    const [week, setWeek] = useState();
    const [month, setMonth] = useState();
    const limit = 10;
    useEffect(() => {
        GetCurrentWeek();
    }, [])

    const GetCurrentWeek = async () => {
        try {
            const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
            }
        } catch (err) {
            ;
            
        } finally {
            ;
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <DeliveryRankCupWidget
                        week={week}
                        limit={limit}
                    />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <DeliveryRankCupWidget
                        limit={1000}
                    />
                </div>
            </div>
           
        </>
    )
}