import React, { useEffect, useState } from 'react';

export default function BlankPage() {


    return (
        <>

        </>
    )
}