import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListGroup = (pageIndex = 1, pageSize = 10, sortExpression = "", code = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    // code && params.append("code", code);
    // name && params.append("name", name);
    return service.get(ApiUrl.GetListGroup, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailGroup = (id) => {
    return service.get(ApiUrl.GetDetailGroup.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateGroup = (data) => {
    return service.post(ApiUrl.CreateGroup, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateGroup = (data) => {
    return service.put(ApiUrl.UpdateGroup, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteGroup = (id) => {
    return service.delete(ApiUrl.DeleteGroup.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentGroup = (id) => {
    return service.delete(ApiUrl.DeletePermanentGroup.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveGroup = (id) => {
    return service.put(ApiUrl.ActiveGroup.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveGroup = (id) => {
    return service.put(ApiUrl.DeActiveGroup.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const GetLookupGroup = () => {
    return service.get(ApiUrl.GetLookupGroup,).then((res) => { return res }).catch((err) => { throw err });
}