// active
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UrlCollectionSarang } from '../../../common/url-collection-sarang';
import * as accountManagementAction from "../../../redux/store/account/account.store";
import * as festivalCogManagementAction from "../../../redux/store-sarang/festival-cog/festival-cog-management.store";
import {
    getCookies, setCookies, getUserInfo, ShowXepHang, setCurrentFestival
} from "../../../utils/configuration";

export default function SideBarSarang() {
    const [screen, setScreen] = useState([]);
    const [showSwatMenu, setShowSwatMenu] = useState();
    const [showCuisineMenu, setShowCuisineMenu] = useState();
    const [showTransportMenu, setShowTransportMenu] = useState();
    const [showCommonMenu, setShowCommonMenu] = useState();
    const [showHerdsmanMenu, setShowHerdsmanMenu] = useState();
    const [showHerdsmanPointThreeMonthMenu, setShowHerdsmanPointThreeMonthMenu] = useState();
    const [showCustomerMenu, setShowCustomerMenu] = useState();
    const [showCustomerNeedHelpMenu, setShowCustomerNeedHelpMenu] = useState();
    const [showCalendarMenu, setShowCalendarMenu] = useState();
    const [showCalendarNeedHelpMenu, setShowCalendarNeedHelpMenu] = useState();
    const [showSwatReportMenu, setShowSwatReportMenu] = useState();

    const [showTransportOrderMenu, setShowTransportOrderMenu] = useState();
    const [showTransportReportMenu, setShowTransportReportMenu] = useState();
    const [showCuisineOrderMenu, setShowCuisineOrderMenu] = useState();
    const [showCuisineReportMenu, setShowCuisineReportMenu] = useState();
    const [showSendCuisineShipperMenu, setShowSendCuisineShipperMenu] = useState();

    const [showReportMenu, setShowReportMenu] = useState();
    const [showCustomerCountStepReportMenu, setShowCustomerCountStepReportMenuMenu] = useState();
    const [user, setUser] = useState(getUserInfo());

    const getFestivalCog = async () => {
        festivalCogManagementAction.GetCurrentFestivalCog()
            .then(
                (resfes) => {

                    if (resfes && resfes.content) {
                        setCurrentFestival(resfes.content);
                        //alert(result.content.length);
                    }
                },
                (err) => {
                    ;
                }
            );
    }

    const getScreen = async () => {
        try {
            let res = await getCookies("screenAllow");
            if (!res) {
                let menus = await accountManagementAction.GetScreenAllow();
                if (menus && menus.content) {
                    res = menus.content;
                    setCookies("screenAllow", menus.content);
                }
            }

            if (res) {
                setScreen(res);
                setShowSwatMenu(res.filter(x => x.code === "SWAT").length > 0);
                setShowReportMenu(res.filter(x => x.code === "REPORT").length > 0);
                setShowCuisineMenu(res.filter(x => x.code === "CUISINE").length > 0);
                setShowTransportMenu(res.filter(x => x.code === "TRANSPORT").length > 0);
                setShowCommonMenu(res.filter(x => x.code === "COMMON").length > 0);
                let Swat = res.filter(x => x.code === "SWAT");
                if (Swat.length > 0 && Swat[0].childrent.length>0) {
                    setShowHerdsmanMenu(Swat[0].childrent.filter(x => x.code === "HERDSMAN").length > 0);
                    setShowCustomerMenu(Swat[0].childrent.filter(x => x.code === "CUSTOMER").length > 0);
                    setShowCustomerNeedHelpMenu(Swat[0].childrent.filter(x => x.code === "CUSTOMERNEEDHELP").length > 0);
                    setShowCalendarMenu(Swat[0].childrent.filter(x => x.code === "CALENDAR").length > 0);
                    setShowCalendarNeedHelpMenu(Swat[0].childrent.filter(x => x.code === "CALENDARNEEDHELP").length > 0);
                    setShowSwatReportMenu(Swat[0].childrent.filter(x => x.code === "SWATREPORT").length > 0);
                    setShowHerdsmanPointThreeMonthMenu(Swat[0].childrent.filter(x => x.code === "HERDSMANPOINTTHREEMONTH").length > 0);
                }

                let Cuisine = res.filter(x => x.code === "CUISINE");
                if (Cuisine.length > 0 && Cuisine[0].childrent.length > 0) {
                    setShowCuisineOrderMenu(Cuisine[0].childrent.filter(x => x.code === "CUISINEORDER").length > 0);
                    setShowCuisineReportMenu(Cuisine[0].childrent.filter(x => x.code === "CUISINE_REPORT").length > 0);
                }

                let Transport = res.filter(x => x.code === "TRANSPORT");
                if (Transport.length > 0 && Transport[0].childrent.length>0) {
                    setShowTransportOrderMenu(Transport[0].childrent.filter(x => x.code === "TRANSPORTORDER").length > 0);
                    setShowTransportReportMenu(Transport[0].childrent.filter(x => x.code === "TRANSPORT_REPORT").length > 0);
                    setShowSendCuisineShipperMenu(Transport[0].childrent.filter(x => x.code === "SEND_SHIPPER").length > 0);
                }

                let Report = res.filter(x => x.code === "REPORT");
                if (Report.length > 0 && Report[0].childrent.length > 0) {
                    setShowCustomerCountStepReportMenuMenu(Report[0].childrent.filter(x => x.code === "CUSTOMERCOUNTSTEPREPORT").length > 0);
                }
            }
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        getScreen();
        getFestivalCog();
    }, []);

    const currentLocation = useLocation();

    const isDashboardHome = currentLocation.pathname === UrlCollectionSarang.DashBoardHome;
    const isExamDashboard = currentLocation.pathname === UrlCollectionSarang.ExamDashboard;

    const isHerdsmanList = currentLocation.pathname === UrlCollectionSarang.HerdsmanList;
    const isCustomerList = currentLocation.pathname === UrlCollectionSarang.CustomerList;
    const isCustomerNeedHelpList = currentLocation.pathname === UrlCollectionSarang.CustomerNeedHelpList;
    const isCalendarContact = currentLocation.pathname === UrlCollectionSarang.CalendarContact;
    const isCalendarNeedHelp = currentLocation.pathname === UrlCollectionSarang.CalendarNeedHelp;
    const isSwatReportList = currentLocation.pathname === UrlCollectionSarang.SwatReportList;
    const isHerdsmanListPointThreeMonth = currentLocation.pathname === UrlCollectionSarang.HerdsmanListPointThreeMonth;
    const isSwatTrundoInput = currentLocation.pathname === UrlCollectionSarang.SwatTrundoInput;
    const isList = isHerdsmanList ||
        isCustomerList ||
        isCalendarContact ||
        isCalendarNeedHelp ||
        isSwatReportList ||
        isCustomerNeedHelpList ||
        isHerdsmanListPointThreeMonth;

    const isSendCuisineShipper = currentLocation.pathname === UrlCollectionSarang.SendCuisineToShipper;
    const isCuisineOrder = currentLocation.pathname === UrlCollectionSarang.CuisineOrder;
    const isReportActivityShipping = currentLocation.pathname === UrlCollectionSarang.ReportActivityShipping;
    const isDashboardCooking = currentLocation.pathname === UrlCollectionSarang.DashboardCooking;
    const isDashboardShipping = currentLocation.pathname === UrlCollectionSarang.DashboardShipping;
    const isReportActivity = //isSwatReportList ||
                    //isCuisineOrder ||
                    isReportActivityShipping ||
                    //isDashboardCooking ||
                    isDashboardShipping;

    const isListCooking = isCuisineOrder || 
        isDashboardCooking;

    const isListShiping = isReportActivityShipping || isSendCuisineShipper ||
        isDashboardShipping;

    const isDepartment = currentLocation.pathname === UrlCollectionSarang.Department;
    const isDepartmentRank = currentLocation.pathname === UrlCollectionSarang.DepartmentListConfirmRank;
    const isOrganization = currentLocation.pathname === UrlCollectionSarang.Organization;
    const isGroup = currentLocation.pathname === UrlCollectionSarang.Group;
    const isZion = currentLocation.pathname === UrlCollectionSarang.Zion;
    const isPosition = currentLocation.pathname === UrlCollectionSarang.Position;
    const isFeedback = currentLocation.pathname === UrlCollectionSarang.Feedback;
    const isSystem = isOrganization || isGroup || isPosition || isZion;
    const isRptCustomerStepDepartment = currentLocation.pathname === UrlCollectionSarang.RptCustomerStepDepartment;
    const isExamHerdsmanReport = currentLocation.pathname === UrlCollectionSarang.ExamHerdsmanReport;
    const isExamDepartmentReport = currentLocation.pathname === UrlCollectionSarang.ExamDepartmentReport;
    const isExamDeliveryReport = currentLocation.pathname === UrlCollectionSarang.ExamDeliveryReport;
    const isExamSumaryDepartmentReport = currentLocation.pathname === UrlCollectionSarang.ExamSumaryDepartmentReport;
    const isHerdsmanTalangSumaryReport = currentLocation.pathname === UrlCollectionSarang.HerdsmanTalangSumaryReport;
    
    const isReport = isRptCustomerStepDepartment || isExamHerdsmanReport || isExamDepartmentReport || isExamDeliveryReport || isExamSumaryDepartmentReport || isHerdsmanTalangSumaryReport;
    const isMyReportAcademy = currentLocation.pathname === UrlCollectionSarang.MyReportAcademy;
    const isExamRankAcademy = currentLocation.pathname === UrlCollectionSarang.ExamAcademyDashboard;
    const isReportSumaryAcademy = currentLocation.pathname === UrlCollectionSarang.ReportSumaryAcademy;
    const isAcademySpeaking = isMyReportAcademy || isExamRankAcademy || isReportSumaryAcademy;

    const isDepartmentGroup = isDepartment || isDepartmentRank;

    const isSwatTrundoSumaryHerdsmanReport = currentLocation.pathname === UrlCollectionSarang.SwatTrundoSumaryHerdsmanReport;
    const isSwatTrundoSumaryDepartmentReport = currentLocation.pathname === UrlCollectionSarang.SwatTrundoSumaryDepartmentReport;
    const isSwatTrundoSumaryZionReport = currentLocation.pathname === UrlCollectionSarang.SwatTrundoSumaryZionReport;
    const isSwatTrundoSumaryByDateReport = currentLocation.pathname === UrlCollectionSarang.SwatTrundoSumaryByDateReport;
    const isSwatTrundo = isSwatTrundoInput ||
        isSwatTrundoSumaryHerdsmanReport ||
        isSwatTrundoSumaryDepartmentReport ||
        isSwatTrundoSumaryZionReport ||
        isSwatTrundoSumaryByDateReport;
    return (
        <div className="nav-left-sidebar sidebar-dark">
            <div className="menu-list">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <a className="d-xl-none d-lg-none" href="#"></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-column">
                            
                            <li className={`nav-item`}>
                                <Link className={`nav-link ${ isDashboardHome ? 'active' :''}`} to={UrlCollectionSarang.DashBoardHome}><i className="fas fa-fw fa-home"></i>Trang chủ</Link>
                            </li>
                            {
                                /* Thi đua lễ hộ truyền đạo*/
                                (ShowXepHang ||
                                    (user && user.email === "tranvanhao@gmail.com") ||
                                    (user && user.userRoles && (user.userRoles.includes("CHAPSU") || user.userRoles.includes("ADMIN")))) && 
                                ShowXepHang &&
                                <li className={`nav-item`}>
                                    <Link className={`nav-link ${isExamDashboard ? 'active' : ''}`} to={UrlCollectionSarang.ExamDashboard}><i className="fas fa-align-justify"></i>Xếp hạng thi đua</Link>
                                </li>
                                }
                            {
                                /**
                                <li className={`nav-item`}>
                                    <a className={`nav-link ${isAcademySpeaking ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isAcademySpeaking} data-target="#submenu-7" aria-controls="submenu-7"><i className="fas fa-fw fa-birthday-cake"></i>Lễ hội lời<span className="badge badge-success">6</span></a>
                                    <div id="submenu-7" className={`collapse submenu ${isAcademySpeaking ? 'show' : ''}`}>
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <Link className={`nav-link ${isMyReportAcademy ? 'active' : ''}`} to={UrlCollectionSarang.MyReportAcademy}>Báo cáo phát biểu</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${isExamRankAcademy ? 'active' : ''}`} to={UrlCollectionSarang.ExamAcademyDashboard}>Vinh danh</Link>
                                            </li>
                                            {
                                                (ShowXepHang && (
                                                    (user && user.email === "tranvanhao@gmail.com") ||
                                                    (user && user.userName === "dong0022") ||
                                                    (user && user.userRoles && (user.userRoles.includes("CHAPSU") || user.userRoles.includes("ADMIN"))))) && 

                                                <li className="nav-item">
                                                        <Link className={`nav-link ${isReportSumaryAcademy ? 'active' : ''}`} to={UrlCollectionSarang.ReportSumaryAcademy}>Thống kê phát biểu</Link>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                 */
                            }
                            {
                                showSwatMenu ? 
                                    <li className={`nav-item`}>
                                        <a className={`nav-link ${isList ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isList} data-target="#submenu-1" aria-controls="submenu-1"><i className="fas fa-fw fa-user-secret"></i>SWAT<span className="badge badge-success">6</span></a>
                                        <div id="submenu-1" className={`collapse submenu ${isList ? 'show' : ''}`}>
                                            <ul className="nav flex-column">
                                                {showHerdsmanMenu?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isHerdsmanList ? 'active' : ''}`} to={UrlCollectionSarang.HerdsmanList}>Danh sách Swat</Link>
                                                    </li> : <></>
                                                }
                                                {showHerdsmanPointThreeMonthMenu && (new Date().getMonth()+1>=11 || new Date().getYear()>2024) ?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isHerdsmanListPointThreeMonth ? 'active' : ''}`} to={UrlCollectionSarang.HerdsmanListPointThreeMonth}>Điểm tích lũy Swat</Link>
                                                    </li> : <></>
                                                }
                                                {
                                                showCustomerMenu?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isCustomerList ? 'active' : ''}`} to={UrlCollectionSarang.CustomerList}>Danh sách ta-lâng</Link>
                                                    </li>: <></>
                                                }
                                                {
                                                showCustomerNeedHelpMenu?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isCustomerNeedHelpList ? 'active' : ''}`} to={UrlCollectionSarang.CustomerNeedHelpList}>Ta-lâng cần bàn giao</Link>
                                                    </li>: <></>
                                                }
                                                {showCalendarMenu?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isCalendarContact ? 'active' : ''}`} to={UrlCollectionSarang.CalendarContact}>Lịch hẹn chia sẻ</Link>
                                                    </li> : <></>

                                                }
                                                {showCalendarNeedHelpMenu ?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isCalendarNeedHelp ? 'active' : ''}`} to={UrlCollectionSarang.CalendarNeedHelp}>Ca chia sẻ cần trợ giúp</Link>
                                                    </li> : <></>

                                                }
                                                {showSwatReportMenu?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isSwatReportList ? 'active' : ''}`} to={UrlCollectionSarang.SwatReportList}>Swat báo cáo hoạt động</Link>
                                                    </li>:<></>
                                                }
                                               
                                            </ul>
                                        </div>
                                    </li>
                                    : <></>
                            }
                            {
                                ((user && user.email === "tranvanhao@gmail.com") ||
                                    (user && user.userRoles && (user.userRoles.includes("CHAPSU") || user.userRoles.includes("DIAVUCTRUONG") || user.userRoles.includes("ADMIN")))) && 
                                <li className={`nav-item`}>
                                    <a className={`nav-link ${isSwatTrundo ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isSwatTrundo} data-target="#submenu-swattrundo" aria-controls="submenu-swattrundo">
                                        <i className="fas fa-fw fa-cogs"></i>Hoạt động Trundo<span className="badge badge-success">6</span>
                                    </a>
                                    <div id="submenu-swattrundo" className={`collapse submenu ${isSwatTrundo ? 'show' : ''}`}>
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <Link className={`nav-link ${isSwatTrundoInput ? 'active' : ''}`} to={UrlCollectionSarang.SwatTrundoInput}>Báo cáo hoạt động trundo</Link>
                                            </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isSwatTrundoSumaryHerdsmanReport ? 'active' : ''}`} to={UrlCollectionSarang.SwatTrundoSumaryHerdsmanReport}>Tổng hợp theo SWAT</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isSwatTrundoSumaryByDateReport ? 'active' : ''}`} to={UrlCollectionSarang.SwatTrundoSumaryByDateReport}>Chi tiết theo SWAT</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isSwatTrundoSumaryDepartmentReport ? 'active' : ''}`} to={UrlCollectionSarang.SwatTrundoSumaryDepartmentReport}>Tổng hợp theo Biệt đội</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isSwatTrundoSumaryZionReport ? 'active' : ''}`} to={UrlCollectionSarang.SwatTrundoSumaryZionReport}>Tổng hợp theo Si-ôn</Link>
                                                </li>
                                        </ul>
                                    </div>
                                </li>
                            }
                            {
                                showCommonMenu ?
                                    <li className={`nav-item`}>
                                        <a className={`nav-link ${isDepartmentGroup ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isSystem} data-target="#submenu-7" aria-controls="submenu-7">
                                            <i className="fas fa-fw fa-cogs"></i>Biệt đội SWAT<span className="badge badge-success">6</span>
                                        </a>
                                        <div id="submenu-7" className={`collapse submenu ${isDepartmentGroup ? 'show' : ''}`}>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isDepartment ? 'active' : ''}`} to={UrlCollectionSarang.Department}>Biệt đội</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isDepartmentRank ? 'active' : ''}`} to={UrlCollectionSarang.DepartmentListConfirmRank}>Duyệt thăng hạng</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> :
                                    <></>
                            }
                            {
                                showCuisineMenu ? 
                                    <li className={`nav-item`}>
                                        <a className={`nav-link ${isListCooking ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isListCooking} data-target="#submenu-2" aria-controls="submenu-2"><i className="fas fa-fw fa-birthday-cake"></i>Ẩm thực<span className="badge badge-success">6</span></a>
                                        <div id="submenu-2" className={`collapse submenu ${isListCooking ? 'show' : ''}`}>
                                            <ul className="nav flex-column">
                                                {showCuisineOrderMenu ?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isCuisineOrder ? 'active' : ''}`} to={UrlCollectionSarang.CuisineOrder}>Đăng ký suất ăn</Link>
                                                    </li> : <></>
                                                }
                                                {showCuisineReportMenu?
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isDashboardCooking ? 'active' : ''}`} to={UrlCollectionSarang.DashboardCooking}>Thống kê</Link>
                                                    </li>: <></>
                                                }
                                            </ul>
                                        </div>
                                    </li> :
                                <></>
                            }
                            { showTransportMenu ?
                                <li className={`nav-item`}>
                                    <a className={`nav-link ${isListShiping ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isListShiping} data-target="#submenu-4" aria-controls="submenu-4"><i className="fas fa-fw fa-truck-moving"></i>Giao vận<span className="badge badge-success">6</span></a>
                                    <div id="submenu-4" className={`collapse submenu ${isListShiping ? 'show' : ''}`}>
                                        <ul className="nav flex-column">
                                            {
                                                showTransportOrderMenu ?
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isReportActivityShipping ? 'active' : ''}`} to={UrlCollectionSarang.ReportActivityShipping}>Đơn hàng</Link>
                                                </li> : <></>
                                            }
                                            {showSendCuisineShipperMenu ?
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isSendCuisineShipper ? 'active' : ''}`} to={UrlCollectionSarang.SendCuisineToShipper}>Giao Shipper</Link>
                                                </li> : <></>
                                            }
                                            { showTransportMenu && showTransportReportMenu ? 
                                                <li className="nav-item">
                                                    <Link className={`nav-link ${isDashboardShipping ? 'active' : ''}`} to={UrlCollectionSarang.DashboardShipping}>Thống kê</Link>
                                                </li> : <></>
                                            }
                                        </ul>
                                    </div>
                                </li> :
                                <></>
                            }
                            {
                                showReportMenu || (user && user.userRoles && (user.userRoles.includes("CHAPSU") || user.userRoles.includes("ADMIN") || user.userRoles.includes("DIAVUCTRUONG"))) ? <li className={`nav-item`}>
                                    <a className={`nav-link ${isReport ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isReport} data-target="#submenu-6" aria-controls="submenu-6">
                                        <i className="fas fa-fw fa-chart-bar"></i>Báo cáo thống kê
                                    </a>
                                    <div id="submenu-6" className={`collapse submenu ${isReport ? 'show' : ''}`}>
                                        <ul className="nav flex-column">
                                            {
                                                showCustomerCountStepReportMenu ? 
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isRptCustomerStepDepartment ? 'active' : ''}`} to={UrlCollectionSarang.RptCustomerStepDepartment}>Tổng hợp ta-lâng theo bước</Link>
                                                    </li>: <></>
                                            }
                                            {
                                                (user && user.userRoles && (user.userRoles.includes("CHAPSU") || user.userRoles.includes("ADMIN"))) &&
                                                <>
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isExamHerdsmanReport ? 'active' : ''}`} to={UrlCollectionSarang.ExamHerdsmanReport}>Xếp hạng Swat</Link>
                                                    </li>
                                                    <li className="nav-item ">
                                                        <Link className={`nav-link ${isExamDepartmentReport ? 'active' : ''}`} to={UrlCollectionSarang.ExamDepartmentReport}>Xếp hạng đội Swat</Link>
                                                    </li>
                                                    {/*
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isExamDeliveryReport ? 'active' : ''}`} to={UrlCollectionSarang.ExamDeliveryReport}>Xếp hạng giao vận</Link>
                                                    </li>
                                                    */ }
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isExamSumaryDepartmentReport ? 'active' : ''}`} to={UrlCollectionSarang.ExamSumaryDepartmentReport}>Báo cáo trundo theo biệt đội</Link>
                                                    </li>
                                                </>
                                            }
                                            {
                                                (user && user.userRoles && (user.userRoles.includes("CHAPSU") || user.userRoles.includes("ADMIN") || user.userRoles.includes("DIAVUCTRUONG"))) &&
                                                <>
                                                    <li className="nav-item">
                                                        <Link className={`nav-link ${isHerdsmanTalangSumaryReport ? 'active' : ''}`} to={UrlCollectionSarang.HerdsmanTalangSumaryReport}>Báo cáo tổng hợp thẻ ta-lâng</Link>
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </div>
                                </li> : <></>
                            }
                            
                            {
                                showCommonMenu ?
                                <li className={`nav-item`}>
                                    <a className={`nav-link ${isSystem ? 'active' : ''}`} href="#" data-toggle="collapse" aria-expanded={isSystem} data-target="#submenu-5" aria-controls="submenu-5">
                                        <i className="fas fa-fw fa-cogs"></i>Danh mục<span className="badge badge-success">6</span>
                                    </a>
                                    <div id="submenu-5" className={`collapse submenu ${isSystem ? 'show' : ''}`}>
                                        <ul className="nav flex-column">
                                            {/*<li className="nav-item">*/}
                                            {/*    <Link className={`nav-link ${isDepartment ? 'active' : ''}`} to={UrlCollectionSarang.Department}>Biệt đội</Link>*/}
                                            {/*</li>*/}
                                            <li className="nav-item">
                                                <Link className={`nav-link ${isGroup ? 'active' : ''}`} to={UrlCollectionSarang.Group}>Bộ</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${isPosition ? 'active' : ''}`} to={UrlCollectionSarang.Position}>Chức vụ</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${isZion ? 'active' : ''}`} to={UrlCollectionSarang.Zion}>Địa vực</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    </li> :
                                <></>
                            }
                            {
                                user?.email=="sarang@gmail.com" ?
                                <li className={`nav-item`}>
                                    <Link className={`nav-link ${isFeedback ? 'active' : ''}`} to={UrlCollectionSarang.Feedback}><i className="fas fa-comment"></i>Sự cố</Link>
                                </li> : <></>
                            }
                            {/*
                            <li className="nav-item">
                                <a className={`nav-link ${ isReportActivity ? 'active' :''}`} href="#" data-toggle="collapse" aria-expanded={isReportActivity} data-target="#submenu-3" aria-controls="submenu-3"><i className="fa fa-fw fa-rocket"/>Báo cáo hoạt động</a>
                                <div id="submenu-3" className={`collapse submenu ${isReportActivity ? 'show' :''}`}>
                                    <ul className="nav flex-column">

                                        <li className="nav-item">
                                            <Link className={`nav-link ${ isSwatReportList ? 'active' :''}`} to={UrlCollectionSarang.ReportActivitySwat}>Swat</Link>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${ (isCuisineOrder || isDashboardCooking) ? 'active' :''}`}
                                                data-toggle="collapse"
                                                aria-expanded={isCuisineOrder || isDashboardCooking}
                                                data-target="#submenu-1-1"
                                                aria-controls="submenu-1-1"
                                            >
                                                Ẩm thực
                                            </a>

                                            <div id="submenu-1-1" className={`collapse submenu ${(isCuisineOrder || isDashboardCooking) ? 'show' :''}`}>
                                                <ul className="nav flex-column">
                                                    <li className="nav-item">
                                                        <Link
                                                            className={`nav-link ${ isCuisineOrder ? 'active' :''}`}
                                                            to={UrlCollectionSarang.CuisineOrder}
                                                        >
                                                            <i className="fas fa-fw fa-chart-bar"/>Quản lý danh sách
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link
                                                            className={`nav-link ${ isDashboardCooking ? 'active' :''}`}
                                                            to={UrlCollectionSarang.DashboardCooking}
                                                        >
                                                            <i className="fas fa-fw fa-chart-bar"/>Thống kê
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${ (isReportActivityShipping || isDashboardShipping) ? 'active' :''}`}
                                                data-toggle="collapse"
                                                aria-expanded={isReportActivityShipping || isDashboardShipping}
                                                data-target="#submenu-1-2"
                                                aria-controls="submenu-1-2"
                                            >
                                                Đơn hàng
                                            </a>

                                            <div id="submenu-1-2" className={`collapse submenu ${(isReportActivityShipping || isDashboardShipping) ? 'show' :''}`}>
                                                <ul className="nav flex-column">
                                                    <li className="nav-item">
                                                        <Link
                                                            className={`nav-link ${ isReportActivityShipping ? 'active' :''}`}
                                                            to={UrlCollectionSarang.ReportActivityShipping}
                                                        >
                                                            <i className="fas fa-fw fa-chart-bar"/>Quản lý danh sách
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link
                                                            className={`nav-link ${ isDashboardShipping ? 'active' :''}`}
                                                            to={UrlCollectionSarang.DashboardShipping}
                                                        >
                                                            <i className="fas fa-fw fa-chart-bar"/>Thống kê
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            */}
                            {/* <li className="nav-divider">
                                Hệ thống
                            </li>
                            <li className={`nav-item`}>
                                <a className="nav-link" href="#"><i className="fa fa-fw fa-user-circle"></i>Phân quyền</a>
                            </li>
                            <li className={`nav-item`}>
                                <a className="nav-link" href="#"><i className="fa fa-fw fa-user-circle"></i>Tỉnh / Thành phố</a>
                            </li>
                            <li className={`nav-item`}>
                                <a className="nav-link" href="#"><i className="fa fa-fw fa-user-circle"></i>Quận / Huyện</a>
                            </li>
                            <li className={`nav-item`}>
                                <a className="nav-link" href="#"><i className="fa fa-fw fa-user-circle"></i>Sion</a>
                            </li>
                            <li className={`nav-item`}>
                                <a className="nav-link" href="#"><i className="fa fa-fw fa-user-circle"></i>Swat</a>
                            </li>
                            <li className={`nav-item`}>
                                <a className="nav-link" href="#"><i className="fa fa-fw fa-user-circle"></i>Bộ</a>
                            </li>
                            <li className={`nav-item`}>
                                <a className="nav-link" href="#"><i className="fa fa-fw fa-user-circle"></i>Ban</a>
                            </li> */}
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}