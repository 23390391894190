import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListSwatActivityReport from './head-cell-list-swat-report';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as swatReportManagementAction from "../../redux/store-sarang/swat-report/swat-report-management.store";
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import FormAddEditSwatReport from './components/form-add-edit-swat-report';
import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import DepartmentTreeSelect from '../list-department/components/department-tree-select';
import DepartmentHelper from '../list-department/helper/department-helper';
import Select from "react-select";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as styles from "../../components/form-search-wrapper-sarang/form-search-wrapper-sarang.module.scss";

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "reportDate desc",
    orderBy: "reportDate",
    order: "desc",
}

export default function SwatReportList() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids
    const [herdsman, setHerdsman] = useState([]);
    const [optionsHerdsman, setOptionsHerdsman] = useState();
    const [job, setJob] = useState([]);
    const [department, setDepartment] = useState([]);
    const [openSearch, setOpenSearch] = useState(false);

    // -- End Data to map with ids

    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState();
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);

    const [isOpenAddEditDialog, setOpenAddEdit] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);

    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                setHerdsman(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupJob = async () => {
        try {
            const res = await swatReportManagementAction.GetLookupJob();
            if (res && res.content) {
                setJob(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupDepartment(),
                getLookupHerdsman(),
                getLookupJob(),
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }
    
    useEffect(() => {
        getListSwatActivityReportManagement();
        fetchData();
    }, []);

    const getListSwatActivityReportManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, searchObject=undefined) => {
        showLoading(true);
        try {
            const res = await swatReportManagementAction.GetListSwatActivityReport(pageIndex, pageSize, sortExpression, searchObject);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListSwatActivityReportManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListSwatActivityReportManagement(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListSwatActivityReportManagement(1, event.target.value, sortExpression, searchData);
    };
    
    const handleSubmitAddEditSwatActivityReport = async (data) => {
        showLoading(true);

        try {
            let res = null;

            if( !data.id ) {
                res = await swatReportManagementAction.CreateSwatActivityReport(data);
            } else {
                res = await swatReportManagementAction.UpdateSwatActivityReport(data);
            }

            if (res && res.content) {
                let sortExpression = orderBy + ' ' + order;
                getListSwatActivityReportManagement(page + 1, rowsPerPage, sortExpression, searchData);
                ShowNotification(
                    viVN.Success["UpdateSuccess"],
                    NotificationMessageType.Success
                );
            }
        } catch (err) {
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }

    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();

    const openAddDialog = () => {
        setOpenAddEdit(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = async (item) => {
        try {
            if (item.details && item.details.length>0)
                setSelectedItem(item);
            else {
                const res = await swatReportManagementAction.GetDetailSwatActivityReport(item.id);

                if (res && res.content) {
                    setSelectedItem(res.content);
                }
            }
            
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }

        setOpenAddEdit(true);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEdit(false);
        setSelectedItem(null);
    }

    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenConfirmDialog(false);
        setSelectedItem(null);
    }

    const handleConfirm = async () => {
        showLoading(true);
        try {
            let res = null;

            if( isOpenDeleteDialog ) {
                res = await swatReportManagementAction.DeleteSwatActivityReport(selectedItem.id);
                if (res && res.content && res.content.status) {
                    ShowNotification(
                        viVN.Success["DeleteSuccess"],
                        NotificationMessageType.Success
                    );
                }
            } else if (isOpenActiveDialog) {
                res = await swatReportManagementAction.ActiveSwatActivityReport(selectedItem.id);
                if (res && res.content && res.content.status) {
                    ShowNotification(
                        viVN.Success["ActiveSuccess"],
                        NotificationMessageType.Success
                    );
                }
            } else if (isOpenDeactiveDialog) {
                res = await swatReportManagementAction.DeActiveSwatActivityReport(selectedItem.id);
                if (res && res.content && res.content.status) {
                    ShowNotification(
                        viVN.Success["DeActiveSuccess"],
                        NotificationMessageType.Success
                    );
                }
            } else if (isOpenConfirmDialog) {
                res = await swatReportManagementAction.ChangeConfirm({ id: selectedItem.id, value: true });
                if (res && res.content && res.content.status) {
                    ShowNotification(
                        viVN.Success["UpdateSuccess"],
                        NotificationMessageType.Success
                    );
                }
            }

            if (res) {
                let sortExpression = orderBy + ' ' + order;
                getListSwatActivityReportManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }

            closeConfirmDialog();
        } catch (err) {
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }

    const onSubmit = (data) => {
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order;
        setPage(0);
        getListSwatActivityReportManagement(1, rowsPerPage, sortExpression, data);
        //if (isTabletOrMobile)
        //    setOpenSearch(false);
    }
    
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className={`card ${styles.search_section} ${(openSearch) ? styles._open : ''}`}>
                    <div className="card-body p-2 p-md-4">
                        <div className={`${styles.form_search_wrapper} ${(openSearch) ? styles._open : ''}`}>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="row">
                                    <div className="form-group col-12 col-md-6 col-lg-6 col-xl-6">
                                        <label htmlFor="input-department-general">Ban/Đội/Nhóm</label>
                                        <DepartmentTreeSelect
                                            {...register("departmentId", {})}
                                            onChange={(data) => {
                                                setValue("departmentId", data ? data.value : null);
                                                onSubmit({ ...searchData, departmentId: data ? data.value : null });
                                            }}
                                            placeholder="Chọn Ban"
                                            options={DepartmentHelper.RenderOptions(department)}
                                        />
                                    </div>

                                    <div className="form-group col-12 col-md-6 col-lg-6 col-xl-6">
                                        <label htmlFor="input-department-general">Swat</label>
                                        <Select
                                            isClearable
                                            placeholder="Swat"
                                            {...register("herdsmanId", {})}
                                            onChange={(data) => {
                                                setValue("herdsmanId", data ? data.value : null);
                                                onSubmit({ ...searchData, herdsmanId: data ? data.value : null });
                                            }}
                                            options={herdsman.map(item => { return { label: item.name, value: item.id } })}
                                            noOptionsMessage={() => "Không tồn tại"}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-4 col-lg-4 col-xl-4">
                                        <label htmlFor="input-department-general">Công việc</label>
                                        <Select
                                            isClearable
                                            {...register("jobId")}
                                            placeholder="Công việc"
                                            closeMenuOnSelect={true}
                                            onChange={(data) => {
                                                setValue("jobId", data ? data.value : null);
                                                onSubmit({ ...searchData, jobId: data ? data.value : null });
                                            }}
                                            options={job.map(item => { return { label: item.name, value: item.id } })}
                                            noOptionsMessage={() => "Không tồn tại"}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </div>
                                    <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                                        <label>Từ ngày</label>
                                        <DatePicker
                                            {...register("reportFromDate")}
                                            onChange={(data) => {
                                                setValue("reportFromDate", dayjs(data).format());
                                                setSearchData({ ...searchData, reportFromDate: dayjs(data).format() });
                                            }}
                                            value={searchData?.reportFromDate ? dayjs(searchData?.reportFromDate) : null}
                                            className="form-control"
                                            format='DD/MM/YYYY'
                                            slotProps={{ textField: { size: 'small' } }}
                                        //label={"Nhập Ngày nhận"}
                                        />
                                    </div>

                                    <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                                        <label>Đến ngày</label>
                                        <DatePicker
                                            {...register("reportToDate")}
                                            onChange={(data) => {
                                                setValue("reportToDate", dayjs(data).format());
                                                setSearchData({ ...searchData, reportToDate: dayjs(data).format() });
                                            }}
                                            value={searchData?.reportToDate ? dayjs(searchData?.reportToDate) : null}
                                            className="form-control"
                                            format='DD/MM/YYYY'
                                            slotProps={{ textField: { size: 'small' } }}
                                        //label={"Nhập Ngày nhận"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 pl-0">
                                        <p className="text-center">
                                            <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                            <button className="btn btn-space btn-secondary" onClick={(e) => {
                                                e.preventDefault();
                                                setValue("reportFromDate", null);
                                            }}>Xóa lọc</button>
                                            <button
                                                className="btn btn-space btn-warning"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openAddDialog();
                                                }}
                                            >Thêm mới</button>
                                            <button type="button" className={`btn btn-space ${!openSearch ? 'btn-primary' : 'btn-danger'}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenSearch(openSearch => !openSearch);
                                                }}
                                            >
                                                Thu gọn
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {!openSearch ? <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className="row">
                                <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                                    <DatePicker
                                        {...register("reportFromDate")}
                                        onChange={(data) => {
                                            setValue("reportFromDate", dayjs(data).format());
                                            setSearchData({ ...searchData, reportFromDate: dayjs(data).format() });
                                        }}
                                        //defaultValue={dayjs(now)}
                                        value={searchData?.reportFromDate ? dayjs(searchData?.reportFromDate) : null}
                                        className="form-control"
                                        format='DD/MM/YYYY'
                                        slotProps={{ textField: { size: 'small' } }}
                                        label={"Từ ngày"}
                                    />
                                </div>

                                <div className="form-group col-6 col-md-4 col-lg-4 col-xl-4">
                                    <DatePicker
                                        {...register("reportToDate")}
                                        onChange={(data) => {
                                            setValue("reportToDate", dayjs(data).format());
                                            setSearchData({ ...searchData, reportToDate: dayjs(data).format() });
                                        }}
                                        value={searchData?.reportToDate ? dayjs(searchData?.reportToDate) : null}
                                        className="form-control"
                                        format='DD/MM/YYYY'
                                        slotProps={{ textField: { size: 'small' } }}
                                        label={"Đến ngày"}
                                    />
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 col-xl-4 pl-0">
                                    <p className="text-center">
                                        <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                        <button type="button" className={`btn btn-space ${!openSearch ? 'btn-primary' : 'btn-danger'}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpenSearch(openSearch => !openSearch);
                                            }}
                                        >
                                            Mở rộng
                                        </button>
                                        {!openSearch ?
                                            <button
                                                className="btn btn-space btn-warning"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    openAddDialog();
                                                }}
                                            >Thêm mới</button> : <></>
                                        }
                                    </p>
                                </div>

                            </div>
                        </form> : <></>}
                         
                    </div>
                </div>
                <DataTableSarang
                    // button functions
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // head cells
                    headCells={headCellsListSwatActivityReport}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    // pagination
                    totalItemCount={totalItemCount}
                    setRowsPerPage={setRowsPerPage}
                    pageIndex={page}
                    handleChangePage={handleChangePage}
                >
                    <tbody>
                        {
                            data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td><span>{row.departmentName}</span></td>
                                    <td><span>{row.herdsmanName}</span></td>
                                    <td><span>{dayjs(row.reportDate).format("DD/MM/YYYY")}</span></td>
                                    <td>
                                        {
                                            row.details && row.details.map((detail, index) => <>
                                                {
                                                    (detail.quantity || detail.triMaiQuantity) && detail.quantity + detail.triMaiQuantity !=0 ? <div className="mt-1">
                                                        {detail.jobName}: {detail.quantity + detail.triMaiQuantity} {detail.description  ? "(" + detail.description + ")" : ""}
                                                    </div> : <></>
                                                }
                                            </>)
                                        }
                                    </td>
                                    <td>
                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                            title="Sửa"
                                            onClick={() => {openEditDialog(row)}}
                                        >
                                            <i className="far fa-edit"></i>
                                        </button>
                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                            title="Xóa"
                                            onClick={() => {
                                                setOpenDeleteDialog(true);
                                                openConfirmDialog(row);
                                            }}
                                        >
                                            <i className="far fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                        }
                    </tbody>
                </DataTableSarang>
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>
            <ModalSubmitForm
                title={!selectedItem ? "Thêm mới hoạt động" : "Chỉnh sửa hoạt động"}
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                <FormAddEditSwatReport
                    herdsman={herdsman}
                    job={job}
                    // ===
                    updateItem={selectedItem}
                    onSubmitAddEdit={handleSubmitAddEditSwatActivityReport}
                />
            </ModalSubmitForm>

            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa bản ghi này không?" :
                    isOpenActiveDialog ? "Bạn có chắc chắn muốn mở khóa bản ghi này không?" :
                    isOpenDeactiveDialog ? "Bạn có chắc chắn muốn khóa bản ghi này không?" : 
                    isOpenConfirmDialog ? "Bạn có chắc chắn muốn xác nhận bản ghi này không?" : ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog || isOpenConfirmDialog}
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}