import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as appActions from "../../../core/app.store";

import { headCellsZionPoint } from '../head-cell-list';
import * as viVN from "../../../language/vi-VN.json";
import { NotificationMessageType} from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";

import * as reportAcademyAction from "../../../redux/store-sarang/report-academy/report-academy-management.store";

export default function ReportZionSumaryAcademy(props) {

    const { week, weekNumber, zionId } = props;
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    //const userInfo = getUserInfo();
    // -- Data to map with ids
    const [sumaryPoint, setSumaryPoint] = useState([]);

    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(0);
    const [totalItem, setTotalItem] = useState();

    useEffect(() => {
        GetListZionWeeklyRank(weekNumber, zionId);
    }, [weekNumber, zionId]);

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem]);

    const GetListZionWeeklyRank = async (weekNumber, zionId) => {
        if (!week) return;
        if (weekNumber == 10) return GetListZionTotallyRank(zionId);

        showLoading(true);
        try {
            const res = await reportAcademyAction.GetListZionWeeklyRank(undefined, weekNumber ? weekNumber : week?.week, weekNumber && weekNumber < 5 ? 6 : weekNumber && weekNumber == 5 ? 7 : week?.month, week?.year, zionId, false);

            if (res && res.content) {
                setSumaryPoint(res.content);
                setTotalItem(res.content.length);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const GetListZionTotallyRank = async (zionId) => {
        if (!week) return;

        showLoading(true);
        try {
            const res = await reportAcademyAction.GetListZionTotallyRank(undefined, zionId, false);

            if (res && res.content) {
                setSumaryPoint(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const renderRow = (item, index) => {
        return <>
            <tr style={{ cursor: "pointer" }} key={index}>
                {
                    headCellsZionPoint.map((itemPoint, indexPoint) => <>
                        {
                            itemPoint.id === 'rank' ? <td key={indexPoint}>
                                {
                                    item.rank ? item.rank : index+1
                                }
                            </td> :
                            itemPoint.id === 'action' ? <td key={indexPoint}></td> :
                                itemPoint.id === 'basicPoint' ? <td key={indexPoint}>
                                    <label className="custom-control custom-checkbox custom-control-inline">

                                        {
                                            item.basicPoint ? item.basicPoint : (item.completeBasicPoint ? <><input
                                                type="checkbox"
                                                defaultChecked={item.completeBasicPoint}
                                                name={`chk${item.herdsmanId}`}
                                                id={`chk${item.herdsmanId}`}
                                                disabled
                                                className="custom-control-input"
                                                /><span className="custom-control-label"></span></> : <></>)
                                        }
                                        
                                    </label>
                                    </td> :
                                        itemPoint.id === 'averagePoint' ? <td>{Math.round(item[itemPoint.id] * 100) / 100}</td> :
                                    <td key={indexPoint}>{item[itemPoint.id]}</td>
                        }
                    </>
                    )
                }
            </tr>
        </>
    }

    const renderTableForDesktop = () => {
        return <>
            <div className="card">
                <div className="card-body">
                    <div className="row text-center">
                        <div className="col-12 mb-2 dataTables" ref={scrollRoot}>
                            <table className="table dataTable table-hover " id="datatable" ref={scrollTable} >
                                <thead>
                                    <tr>
                                        {
                                            headCellsZionPoint.map((item) =>
                                                <td key={item.id}
                                                    style={item.style ? { ...item.style, fontWeight: 'bold', textAlign: 'center' } : { fontWeight: 'bold', textAlign: 'center' }}
                                                ><b>{item.label}</b></td>)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.map((item, index) => <>
                                            {
                                                renderRow(item, index)
                                            }
                                        </>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                    {
                        scrollTableWidth ?
                            <div className='scrollRoot'
                                onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                            >
                                <div style={{ width: `${scrollTableWidth}px` }}>
                                    &nbsp;
                                </div>
                            </div> : <></>
                    }
                </div>
                
            </div>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    
                    renderTableForDesktop()
                }
            </div>
        </div>
    )
}