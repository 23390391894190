import React, { useEffect, useState } from 'react';
import * as reportAcademyAction from "../../../redux/store-sarang/report-academy/report-academy-management.store";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
import Popover from '@mui/material/Popover';
export default function HerdsmanRankCupAcademy(props) {
    const { userInfo, week, limit } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState(false);
    const [listHerdsmanRank, setListHerdsmanRank] = useState([]);
    const [herdsmanData, setHerdsmanData] = useState();

    useEffect(() => {
        GetAllListHerdsmanRank()
    }, [week]);

    const GetAllListHerdsmanRank = async () => {
        if (!week) return;
        setLoading(true);
        try {
            const res = await reportAcademyAction.GetListHerdsmanRank(week?.week, week?.month, week?.year, undefined, undefined, 1,limit);

            if (res && res.content && res.content.items) {
                setListHerdsmanRank(res.content.items);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const herdsmanClick = (event, hdmId) => {
        setAnchorEl(event.currentTarget);
        //GetHerdsmanExaminationReport(hdmId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderTop = (top) => {
        return <div className="col-4">
            {
                listHerdsmanRank.length > top-1 &&
                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                    //herdsmanClick(event, listHerdsmanRank[1].herdsmanId);
                }}>
                        <img src={`assets/images/rank/person_top${top}.jpg`} style={top == 1 ? { width: "50px" } : top == 2 ? { width: "40px", paddingTop: "10px" } : { width: "35px", paddingTop: "15px" }} />
                    <div style={{ marginTop: "10px", fontWeight: 700 }} >{
                        listHerdsmanRank[top-1].herdsmanName + " (" + listHerdsmanRank[top-1].zionName + ")"
                    }</div>
                    <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                        listHerdsmanRank[top-1].totalPoint
                    }</div>
                </div>
            }
        </div>
    }
    return (
        <>
            {
                <Popover
                    id='buoc1'
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {/*<Typography sx={{ p: 2 }}>test</Typography>*/}
                    {
                        herdsmanData?.rP_Examination_Report_Dtos && herdsmanData?.rP_Examination_Report_Dtos.length > 0 &&
                        <table className="table dataTable">
                            <thead>
                                <tr >
                                    <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                    <td style={{ minWidth: "50px" }}><b>#</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    herdsmanData?.rP_Examination_Report_Dtos.map((itemherdsman, index) =>
                                        <tr>
                                            <td>{optionsExamination.find(x => x.value == itemherdsman.status)?.label}</td>
                                            <td>{itemherdsman.quantity}</td>
                                            <td>{Math.round((itemherdsman.point) * 100) / 100}</td>
                                            {/*<td>{Math.round((itemherdsman.quantity * itemherdsman.point) * 100) / 100}</td>*/}
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    }
                </Popover>
            }
            <div className="card">
                <h5 className="card-header">Cá nhân ưu tú ({week?.week ? "tuần " + week?.week : "tháng " + week?.month })</h5>
                <div className="card-body">
                    <div className="row text-center">
                        {
                            renderTop(2)
                        }
                        {
                            renderTop(1)
                        }
                        {
                            renderTop(3)
                        }
                    </div>
                    <div className="row text-center">
                        <table className="table dataTable table-hover " id="datatable">
                            <thead>
                                <tr>
                                    <td style={{ minWidth: "30px" }}><b>Hạng</b></td>
                                    <td style={{ minWidth: "100px" }}><b>Họ tên ACE</b></td>
                                    <td style={{ minWidth: "70px" }}><b>Địa vực</b></td>
                                    <td style={{ minWidth: "30px" }}><b>Điểm</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listHerdsmanRank.map((item, index) => 
                                        index > 2 && index <=(limit ? limit -1: 9) &&
                                            <tr>
                                                <td>{item.rank}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                                    //herdsmanClick(event, item.herdsmanId);
                                                }}>{item.herdsmanName}&nbsp;
                                                    {
                                                        item.basicPoint === 0 ? <img title="đã hoàn thành mục tiêu cơ bản"
                                                            src={require("../../../assets/icon/tick.png")}
                                                            alt="Đã hoàn thành mục tiêu cơ bản"
                                                        />: <></>
                                                    }
                                                </td>
                                                <td>{item.zionName}</td>
                                                <td>{item.totalPoint}</td>
                                            </tr>
                                    
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <LoadingViewAria loading={loading} />
            </div>
        </>
    )
}