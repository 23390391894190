import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListCustomerNeedHelp from './head-cell-list-customer-need-help';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";
import * as taskCalendarManagementAction from "../../redux/store-sarang/task-calendar/task-calendar-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as districtManagementAction from "../../redux/store/district-management/district.store"
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import FormAssignHerdman from './components/form-assign-herdsman';
import Select from "react-select";
import { optionsCustomerStatus } from '../../common/constant';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import DepartmentHelper from '../list-department/helper/department-helper';
import FormAddEditTask from '../calendar-contact/add-edit-task/form-add-edit-task';
import { patternNumber } from '../../common/validatePhoneNumber';
import { useMediaQuery } from 'react-responsive';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';
import MobileHeaderSarang from '../../components/mobile-header/mobile-header-sarang';

import * as styles from '../custome-style.module.scss'

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function CustomerNeedHelpList() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids
    const [department, setDepartment] = useState([]);
    const [district, setDistrict] = useState([]);
    const [herdsman, setHerdsman] = useState([]);
    const [herdsmanOwn, setHerdsmanOwn] = useState([]);
    const [optionsHerdsman, setOptionsHerdsman] = useState();
    const [optionsDistrict, setOptionsDistrict] = useState();
    const [searchData, setSearchData] = useState();
    const [herdsmanId, setHerdsmanId] = useState(getUserInfo().herdsmanId);
    const [openSearch, setOpenSearch] = useState(false);

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetListNoPermiss(); //.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items.filter(x => (x.parentId == null || x.parentId === undefined) && x.id!==53)));
            }
        } catch (err) {
            throw err;
        }
    }
    
    const getLookupDistrict = async () => {
        try {
            const res = await districtManagementAction.GetLookupDistrict();
            if (res && res.content) {
                setDistrict(res.content);
                setOptionsDistrict(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    }
    
    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                //setHerdsman(res.content);
                setOptionsHerdsman(res.content.map(item => { return { label: item.name, value: item.id } }));
                setHerdsmanOwn(res.content);
            }

            const res1 = await herdsmanManagementAction.GetLookupHerdsman(false);
            if (res1 && res1.content) {
                setHerdsman(res1.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const [customer, setCustomer] = useState([]);
    const getLookupCustomer = async () => {
        try {
            const res = await customerManagementAction.GetLookupCustomer();
            if (res && res.content) {
                setCustomer(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupDepartment(),
                getLookupDistrict(),
                getLookupHerdsman(),
                getLookupCustomer()
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }
    
    // -- End Data to map with ids

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);

    useEffect(() => {
        getListCustomerManagement();
        fetchData();
    }, []);

    const getListCustomerManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, searchData=undefined) => {
        showLoading(true);
        try {
            const res = await customerManagementAction.GetListNeedHelp(pageIndex, pageSize, sortExpression, searchData);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };
    
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListCustomerManagement(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListCustomerManagement(1, event.target.value, sortExpression, searchData);
    };

    const handleSubmitAssignHerdsman = async (data) => {
        showLoading(true);

        try {
            let res = null;

            res = await customerManagementAction.AssignHerdsman(data);

            if (res && res.content) {
                ShowNotification(
                    viVN.Success["AssignHerdsmanSuccess"],
                    NotificationMessageType.Success
                );
                let sortExpression = orderBy + ' ' + order;
                getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }

    const handleSubmitAddEditTask = async (data) => {
        showLoading(true);

        try {
            let res = null;

            res = await taskCalendarManagementAction.CreateTask(data);
            if ( res && res.content ) {
                let sortExpression = orderBy + ' ' + order;
                getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
    const [isOpenCreateCalendarDialog, setOpenCreateCalendarDialog] = useState(false);
    const [isOpenConfirmCreateCalendarDialog, setOpenConfirmCreateCalendarDialog] = useState(false);
    const [isOpenHelpingToMeDialog, setOpenHelpingToMeDialog] = useState(false);
    const [isOpenCancelHelping, setOpenCancelHelping] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setOpenCreateCalendarDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenChangeStatusDialog(false);

        if (!isOpenConfirmCreateCalendarDialog && !isOpenHelpingToMeDialog)
            setSelectedItem(null);

        setOpenConfirmCreateCalendarDialog(false);
        setOpenHelpingToMeDialog(false);
        setOpenCancelHelping(false);

    }

    const handleConfirm = async () => {
        try {
            let res = null;

            if (isOpenDeleteDialog) {
                res = await customerManagementAction.DeleteCustomer(selectedItem.id);
            } else if (isOpenActiveDialog) {
                res = await customerManagementAction.ActiveCustomer(selectedItem.id);
            } else if (isOpenDeactiveDialog) {
                res = await customerManagementAction.DeActiveCustomer(selectedItem.id);
            } else if (isOpenChangeStatusDialog) {
                res = await customerManagementAction.ChangeStatusCustomer({ id: selectedItem.id, status: selectedItem.status });
                if (res) {
                    ShowNotification(
                        viVN.Success["ChangeStatusSuccess"],
                        NotificationMessageType.Success
                    );
                }
            } else if (isOpenConfirmCreateCalendarDialog) {
                setOpenCreateCalendarDialog(true);
            }
            else if (isOpenHelpingToMeDialog && herdsmanId) {
                res = await customerManagementAction.ReceiptHelping(selectedItem.id);
                if (res) {
                    setOpenAddEditDialog(true);
                    buttonOpenAddEditRef.current.click();
                }
            }
            else if (isOpenCancelHelping) {
                res = await customerManagementAction.CancelHelping(selectedItem.id);
                if (res) {
                    ShowNotification(
                        viVN.Success["CancelHelpingSuccess"],
                        NotificationMessageType.Success
                    );
                }
            }

            if (res) {
                let sortExpression = orderBy + ' ' + order;
                getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }

            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }
    const onSubmit = (data) => {
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order;
        setPage(0);
        getListCustomerManagement(1, rowsPerPage, sortExpression, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const renderFormSearch = () => {
        return <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-3">
                    <div className="form-group">
                        <label htmlFor="input-fullName" className="col-form-label">Họ và tên</label>
                        <input
                            id="fullName"
                            className="form-control"
                            type="text"
                            name="fullName"
                            placeholder="Nhập họ tên"
                            ref={register()}
                        />
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <div className="form-group">
                        <label htmlFor="input-phoneNumber" className="col-form-label">Số điện thoại</label>
                        <input
                            id="phoneNumber"
                            className="form-control"
                            type="text"
                            name="phoneNumber"
                            placeholder="Số điện thoại"
                            ref={register()}
                        />
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <div className="form-group">
                        <label htmlFor="input-district">Chọn Quận/Huyện</label>
                        <Select
                            placeholder="Chọn Quận/huyện"
                            isClearable
                            {...register("districtId")}
                            onChange={(data) => {
                                setValue("districtId", data ? data.value : null);
                            }}
                            options={optionsDistrict}
                            noOptionsMessage={() => "Không tồn tại"}
                            // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="input-district">Trạng thái</label>
                    <Select
                        placeholder="Chọn Trạng thái"
                        isClearable
                        {...register("status")}
                        onChange={(data) => {
                            setValue("status", data ? data.value : null);
                        }}
                        options={optionsCustomerStatus}
                        noOptionsMessage={() => "Không tồn tại"}
                        // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="input-age">Năm sinh</label>
                    <div className="row">
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="fromYearOfBirth"
                                placeholder="Từ năm"
                                ref={register({ maxLength: 4, minLength: 4 })}
                                onChange={(e) =>
                                    setValue(
                                        "fromYearOfBirth",
                                        e.target.value.replace(patternNumber, "")
                                    )
                                }
                            />
                        </div>

                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="toYearOfBirth"
                                placeholder="Đến năm"
                                ref={register({ maxLength: 4, minLength: 4 })}
                                onChange={(e) =>
                                    setValue(
                                        "toYearOfBirth",
                                        e.target.value.replace(patternNumber, "")
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="input-department-general">Ace dẫn dắt</label>
                    <Select
                        isClearable
                        {...register("herdsmanId")}
                        placeholder="Chọn ACE dẫn dắt"
                        onChange={(data) => {
                            setValue("herdsmanId", data ? data.value : null);
                        }}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            </div>
            <FormSearchFunctionButtons>
                <div className="row">
                    <div className="col-sm-12 pl-0">
                        <p className="text-center">
                            <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                            <button className="btn btn-space btn-secondary"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}>Xóa lọc</button>
                        </p>
                    </div>
                </div>
            </FormSearchFunctionButtons>
        </form>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <MobileHeaderSarang
                    headCell={headCellsListCustomerNeedHelp}
                    handleSortChange={(sortExpression) => getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData)}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                />
                <div id="accordion">

                    {
                        data.length > 0 ?
                            data.map((item, index) => (
                                <div className="card" key={index}>
                                    <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                            data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='mt-0'>
                                                    <span><b>{item.gender ? <li className="fas fa-male text-primary" title="Nam"></li> : <li className="fas fa-female text-secondary" title="Nữ"></li>}
                                                        {item?.status >= 70 ?
                                                            <img title={item.statusName} style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/fb_tick.png")}
                                                                alt="Tick"
                                                                /> : <></>} {item.fullName}</b></span>
                                                    <span>({item.yearOfBirth} - {item.departmentName})</span>
                                                    </div>
                                                    
                                                    <div className='mt-0'>
                                                        {item.buoc1_Virtual ? <img title="Đã qua bước 1"
                                                            src={require("../../assets/icon/tick.png")}
                                                            alt="Tick"
                                                        /> : <img title="Chưa qua bước 1"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel" />}&nbsp;
                                                        {item.buoc2_Virtual ? (
                                                            <img title="Đã qua bước 2"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : <img title="Chưa qua bước 2"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel" />

                                                        }
                                                        &nbsp;
                                                        {item.buoc3_Virtual ? (
                                                            <img title="Đã qua bước 3"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : <img title="Chưa qua bước 3"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel" />
                                                        }
                                                        &nbsp;
                                                        {item.buoc4_Virtual ? (
                                                            <img title="Đã qua bước 4"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : <img title="Chưa qua bước 4"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel"
                                                        />}
                                                        &nbsp;
                                                        {item.buoc5_Virtual ? (
                                                            <img title="Đã qua bước 5"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : <img title="Chưa qua bước 5"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel"
                                                        />}
                                                        &nbsp;
                                                        {item.buoc6_Virtual ? (
                                                            <img title="Đã qua bước 6"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : <img title="Chưa qua bước 6"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel"
                                                        />}
                                                        &nbsp;
                                                        {item.buoc7_Virtual ? (
                                                            <img title="Đã qua bước 7"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : <img title="Chưa qua bước 7"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel"
                                                        />}
                                                        &nbsp;
                                                        {item.buoc8_Virtual ? (
                                                            <img title="Đã qua bước 8"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : <img title="Chưa qua bước 8"
                                                            src={require("../../assets/icon/cancel.png")}
                                                            alt="Cancel"
                                                        />}
                                                    </div>
                                                    <div className='mt-2'><b>Ace dẫn dắt: </b>
                                                        {item.herdsmanName1 && <><span>{item.herdsmanName1} {item?.showAssignButton && <> (<a href={`tel:${item.herdsmanPhone1}`}>{item.herdsmanPhone1}</a>)</>}
                                                        </span><br /></>}
                                                        {item.herdsmanName2 && <><span>{item.herdsmanName2}
                                                        </span><br /></>}
                                                        {item.herdsmanName3 && <span>{item.herdsmanName3}
                                                        </span>}
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    {item.phoneNumber !== "" ?
                                                        <div className='mt-2'><b><li className=" fas fa-phone text-primary">&nbsp;</li></b>
                                                            <span>
                                                                {item.phoneNumber}
                                                            </span>
                                                        </div> : <></>}

                                                    <div className='mt-2'><b>Nhận giúp đỡ: </b>
                                                        <span>
                                                            {item.needHelp && !item?.departmentHelpingName ? (
                                                                <>
                                                                    {
                                                                        (item?.showReceiptButton) ?
                                                                            <span className={styles.assign_to_me} onClick={() => {
                                                                                setOpenHelpingToMeDialog(true);
                                                                                openConfirmDialog(item);
                                                                            }}>   Nhận</span> : <></>
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>{item?.departmentHelpingName}&nbsp;
                                                                    {
                                                                        item?.showAssignButton ?
                                                                            <li title="Hủy trợ giúp" className="far fa-times-circle text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                setOpenCancelHelping(true);
                                                                                openConfirmDialog(item);
                                                                            }}></li> : <></>
                                                                    }
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className='mt-2'><b>Ace giúp đỡ:</b>
                                                        <span>
                                                            {item.herdsmanHelpingName1}&nbsp;
                                                            {
                                                                item?.showAssignButton ?
                                                                    <i className="far fa-edit text-primary" title="Ae trợ giúp" onClick={() => { openEditDialog(item) }} style={{ cursor: 'pointer' }}></i> : <></>
                                                            }
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <i className="fas fa-angle-down p-2"></i>
                                    </div>
                                    <div id={`collapse-${item.id}`} className="collapse" aria-labelledby={`heading-${item.id}`} data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className='mt-0'><b>Quận/huyện: </b>
                                                        <span>{item.districtName}</span>
                                                    </div>
                                                    <div className='mt-0'><b>Nghề nghiệp: </b>
                                                        <span>{item.career}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mt-0'><b>Ghi chú: </b>
                                                        <span>{item.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-2'>
                                                {
                                                    item?.showAssignButton &&
                                                    <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-calendar-customer" data-toggle="tooltip" data-placement="top"
                                                        title="Xếp lịch"
                                                        onClick={() => {
                                                            setOpenConfirmCreateCalendarDialog(true);
                                                            openConfirmDialog(item);
                                                        }}
                                                    >
                                                        <i className="far fa-calendar-alt"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                        <DataTablePagination
                            totalItemCount={totalItemCount}
                            rowsPerPage={rowsPerPage}
                            pageIndex={page + 1}
                            handleChangePage={handleChangePage}
                        /> :
                        <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog} hideNewButton={true}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog} hideNewButton={true}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsListCustomerNeedHelp}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td><span>{row.gender ? <li className="fas fa-male text-primary" title="Nam"></li> : <li className="fas fa-female text-secondary" title="Nữ"></li>}&nbsp;{row.fullName} ({row.yearOfBirth} - {row.departmentName})</span></td>
                                    <td>
                                        <span>
                                            {row.needHelp && !row?.departmentHelpingName ? (
                                                <>
                                                    {
                                                        (row?.showReceiptButton) ?
                                                            <span className={styles.assign_to_me} onClick={() => {
                                                                setOpenHelpingToMeDialog(true);
                                                                openConfirmDialog(row);
                                                            }}>   Nhận</span> : <></>
                                                    }
                                                </>
                                            ) : (
                                                <>{row?.departmentHelpingName}&nbsp;
                                                    {
                                                        row?.showAssignButton ?
                                                            <li title="Hủy trợ giúp" className="far fa-times-circle text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                                setOpenCancelHelping(true);
                                                                openConfirmDialog(row);
                                                            }}></li> : <></>
                                                    }
                                                </>
                                            )}
                                        </span>
                                    </td>
                                    <td>{row.herdsmanHelpingName1}&nbsp;
                                        {
                                            row?.showAssignButton ?
                                                <i className="far fa-edit text-primary" title="Ae trợ giúp" onClick={() => { openEditDialog(row) }} style={{ cursor: 'pointer' }}></i> : <></>
                                        }
                                    </td>
                                    <td><span>{row.districtName}</span></td>
                                    <td>
                                        <span>{row.phoneNumber}</span>
                                    </td>
                                    <td>
                                        <span>{row.herdsmanName1}
                                            {row?.showAssignButton && <> (<a href={`tel:${row.herdsmanPhone1}`}>{row.herdsmanPhone1}</a>)</>}
                                        </span><br />
                                        <span>{row.herdsmanName2}
                                        </span><br />
                                        <span>{row.herdsmanName3}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {/*
                                                <Select
                                                value={row?.status ? { label: row.statusName, value: row.status } : null}
                                                placeholder="Chọn Trạng thái"
                                                onChange={(data) => {
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: data.value, statusName: data.label });
                                                }}
                                                options={optionsCustomerStatus}
                                                noOptionsMessage={() => "Không tồn tại"}
                                                // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                                */ }

                                            {row.buoc1_Virtual ? <img title="Đã qua bước 1"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            /> : <img title="Chưa qua bước 1"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" />}&nbsp;
                                            {row.buoc2_Virtual ? (
                                                <img title="Đã qua bước 2"
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : <img title="Chưa qua bước 2"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" />
                                                
                                            }
                                            &nbsp;
                                            {row.buoc3_Virtual ? (
                                                <img title="Đã qua bước 3"
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : <img title="Chưa qua bước 3"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" />    
                                            }
                                            &nbsp;
                                            {row.buoc4_Virtual ? (
                                                <img title="Đã qua bước 4"
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : <img title="Chưa qua bước 4"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                            />}
                                            &nbsp;
                                            {row.buoc5_Virtual ? (
                                                <img title="Đã qua bước 5"
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : <img title="Chưa qua bước 5"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                            />}
                                            &nbsp;
                                            {row.buoc6_Virtual ? (
                                                <img title="Đã qua bước 6"
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : <img title="Chưa qua bước 6"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                            />}
                                            &nbsp;
                                            {row.buoc7_Virtual ? (
                                                <img title="Đã qua bước 7"
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : <img title="Chưa qua bước 7"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                            />}
                                            &nbsp;
                                            {row.buoc8_Virtual ? (
                                                <img title="Đã qua bước 8"
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick"
                                                />
                                            ) : <img title="Chưa qua bước 8"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                            />}
                                        </span>
                                    </td>
                                    <td><span>{row.career}</span></td>
                                    <td>
                                        <span>{row.description}</span>
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            {
                                                row?.showAssignButton &&
                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-calendar-customer" data-toggle="tooltip" data-placement="top"
                                                    title="Xếp lịch"
                                                    onClick={() => {
                                                        setOpenConfirmCreateCalendarDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                                >
                                                    <i className="far fa-calendar-alt"></i>
                                                </button>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
                
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>

            <ModalSubmitForm
                title={isOpenAddEditDialog ? "Bổ sung Ae/Ce hỗ trợ" : "Tạo lịch"}
                open={isOpenAddEditDialog || isOpenCreateCalendarDialog}
                onClose={closeAddEditDialog}
            >
                {
                    !isOpenCreateCalendarDialog ? 
                        <FormAssignHerdman
                        // department={department}
                        herdsman={optionsHerdsman}
                        // ===
                        updateItem={selectedItem}
                        handleSubmitAssignHerdsman={handleSubmitAssignHerdsman}
                    /> : 
                    <FormAddEditTask
                        customer={customer}
                        herdsman={herdsman}
                        herdsmanOwn={herdsmanOwn}
                        // ===
                        customerForCreateTask={selectedItem}
                        handleSubmitAddEditTask={handleSubmitAddEditTask}
                    />
                }
                
            </ModalSubmitForm>

            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Ae/Ce có chắc chắn muốn xóa bản ghi này không?":
                    isOpenActiveDialog ? "Ae/Ce có chắc chắn muốn mở khóa bản ghi này không?":
                    isOpenDeactiveDialog ? "Ae/Ce có chắc chắn muốn khóa bản ghi này không?": 
                    isOpenChangeStatusDialog ? `Ae/Ce có chắc chắn muốn thay đổi trạng thái cho chiên <b>"${selectedItem?.fullName}"</b> thành <b>"${selectedItem?.statusName}"</b>?` :
                    isOpenConfirmCreateCalendarDialog ? `Ae/Ce có chắc chắn muốn tạo lịch cho chiên <b>"${selectedItem?.fullName}"</b>?` : 
                    isOpenHelpingToMeDialog ? `Ae/Ce có chắc chắn muốn hỗ trợ chăm sóc ta-lâng này??` : 
                    isOpenCancelHelping ? `Ae/Ce có chắc chắn muốn hủy hỗ trợ ta-lâng này?` : ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog || isOpenChangeStatusDialog || isOpenCreateCalendarDialog || isOpenHelpingToMeDialog || isOpenCancelHelping}
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}