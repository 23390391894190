const headCellsListCuisineOrder = [
    {
        id: 'OrderCode',
        numeric: false,
        disablePadding: true,
        label: 'Mã',
        className: 'pt-3 pb-3',
    },
    {
        id: 'orderDate',
        numeric: false,
        disablePadding: false,
        label: 'Ngày',
        className: 'pt-3 pb-3',
    },
    {
        id: 'receiptTime',
        numeric: false,
        disablePadding: false,
        label: 'Thời gian',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Quatity',
        numeric: false,
        disablePadding: false,
        label: 'Số lượng',
        className: 'pt-3 pb-3',
    },
    {
        id: 'HerdsManOrder.Name',
        numeric: false,
        disablePadding: false,
        label: 'Ace đặt',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'HerdsManOrder.PhoneNumber',
        numeric: false,
        disablePadding: false,
        label: 'Điện thoại',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'DeliveryType',
        numeric: false,
        disablePadding: false,
        label: 'Hình thức giao',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    //{
    //    id: 'District.Name',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Quận/Huyện',
    //    className: 'pt-3 pb-3',
    //},
    {
        id: 'Address',
        numeric: false,
        disablePadding: true,
        label: 'Địa chỉ',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'Status',
        numeric: false,
        disablePadding: false,
        label: 'Trạng thái',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'IsActived',
        numeric: false,
        disablePadding: true,
        label: 'Tình trạng',
        className: 'pt-3 pb-3',
    },
    {
        id: 'HerdsManConfirm.Name',
        numeric: false,
        disablePadding: false,
        label: 'Ace Xác nhận',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'HerdsManDelivery.Name',
        numeric: false,
        disablePadding: true,
        label: 'Ace giao',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: true,
        label: 'Ghi chú',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
    },
];

export default headCellsListCuisineOrder;