import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Select from "react-select";
import { getUserInfo } from '../../../utils/configuration';
import { patternNumber } from '../../../common/validatePhoneNumber';

export default function FormAddEditSwatReport(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem,
        herdsman, job,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    const [checkActive, setCheckActive] = useState(true);
    const [checkConfirm, setCheckConfirm] = useState(false);
    const [optionsHerdsman, setOptionsHerdsman] = useState(herdsman.map(item => { return { label: item.name, value: item.id } }));
    const [currentLoggedInHerdsman] = useState({ label: getUserInfo()?.fullName, value: getUserInfo()?.herdsmanId });
    const [jobType, setJobType] = useState(6);
    const [reportDetail, setReportDetail] = useState([]);

    useEffect(() => {
        setCheckActive(updateItem?.isActived);
        setCheckConfirm(updateItem?.isConfirm);
        var jobItem = new Array();
        var objs = job.map((item) => {
            jobItem.push({ jobId: item.id, jobName: item.name, isActived: true });
        }
        );

        var jobDetail = new Array();
        if (updateItem?.details && updateItem?.details.length > 0) {
            var sr=updateItem?.details.map((item) => {
                jobDetail.push(item)
            });
        }

        if (jobDetail && jobDetail.length > 0) {
            job.map((item) => {
                var s = jobDetail.find(x => x.jobId == item.id);
                if (!s)
                    jobDetail.push({ jobId: item.id, jobName: item.name, isActived: true });
            }
            )
        }
        
        /*setReportDetail(updateItem?.details && updateItem?.details.length > 0 ? updateItem?.details : jobItem);*/
        setReportDetail(jobDetail && jobDetail.length>0 ? jobDetail : jobItem);
        setValue("timeQuatity", updateItem?.timeQuatity);
        setValue("herdsmanId", updateItem?.herdsmanId ? updateItem?.herdsmanId : getUserInfo()?.herdsmanId);
        setValue("herdsmanConfirmId", updateItem?.herdsmanConfirmId);
        setValue("jobId", updateItem?.jobId ? updateItem?.jobId : 6);
        setValue("description", updateItem?.description);
        setValue("reportDate", updateItem?.reportDate || dayjs(new Date()).format()); //dayjs(new Date()).format()
    }, [])

    const { register, handleSubmit, errors, setValue, control } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }
        
        const dataInput = { ...data, id: updateItem?.id, isActive: updateItem?.isActive!=undefined ? updateItem?.isActive : true, isConfirm: updateItem?.isConfirm != undefined ? updateItem?.isConfirm : true, details: reportDetail};
        //console.log(dataInput)
        onSubmitAddEdit(dataInput);
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Swat <span className='required'>*</span></label>
                    <Select
                        isClearable
                        {...register("herdsmanId", { required: true })}
                        defaultValue={updateItem?.herdsmanId ? { label: updateItem?.herdsmanName, value: updateItem?.herdsmanId } : (currentLoggedInHerdsman ? currentLoggedInHerdsman : null)}
                        placeholder="Chọn Swat"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("herdsmanId", data ? data.value : null)}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {errors.herdsmanId && errors.herdsmanId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Ngày thực hiện <span className='required'>*</span></label>
                    <DatePicker
                        {...register("reportDate", { required: true })}
                        onChange={(data) => {
                            //console.log("data", data);
                            setValue("reportDate", dayjs(data).format());
                        }}
                        defaultValue={updateItem?.reportDate ? dayjs(updateItem?.reportDate) : dayjs(new Date())}  //dayjs(new Date())
                        className="form-control"
                        format='DD/MM/YYYY'
                        slotProps={{ textField: { size: 'small' } }}
                    />
                    {errors.reportDate && errors.reportDate.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <table className="table dataTable table-hover " id={`datatableDetail`}>
                        <tbody>
                            <tr>
                                <td>Công việc</td>
                                <td>SL H.Mai/T.Trì</td>
                                <td>SL Quận/Huyện #</td>
                                <td>Diễn giải</td>
                            </tr>
                            {
                            reportDetail && reportDetail.length > 0 && reportDetail.map((detail, index) => <tr key={index }>
                                <td>{detail.jobName}</td>
                                <td><input
                                    className="form-control"
                                    type="text"
                                    name={`details[${index}][triMaiQuantity]`}
                                    defaultValue={detail?.triMaiQuantity || null}
                                    placeholder="Số lượng T.Trì/H.Mai"
                                    ref={register({

                                    })}
                                    onChange={(e) => {
                                        setValue(
                                            `details[${index}][triMaiQuantity]`,
                                            e.target.value.replace(patternNumber, "")
                                        );
                                        detail.triMaiQuantity = e.target.value.replace(patternNumber, "");
                                        if (detail.triMaiQuantity === "") detail.triMaiQuantity = 0
                                    }
                                    }
                                /></td>
                                <td><input
                                    className="form-control"
                                    type="text"
                                    name={`details[${index}][quantity]`}
                                    defaultValue={detail?.quantity || null}
                                    placeholder="Số lượng Q/H khác"
                                    ref={register({

                                    })}
                                    onChange={(e) => {
                                        setValue(
                                            `details[${index}][quantity]`,
                                            e.target.value.replace(patternNumber, "")
                                        );
                                        detail.quantity = e.target.value.replace(patternNumber, "");
                                        if (detail.quantity === "") detail.quantity = 0
                                    }
                                    }
                                /></td>
                                <td><input
                                    className="form-control"
                                    type="text"
                                    name={`details[${index}][description]`}
                                    defaultValue={detail?.description}
                                    placeholder="Diễn giải"
                                    ref={register({

                                    })}
                                    onChange={(e) => {
                                        setValue(
                                            `details[${index}][description]`,
                                            e.target.value
                                        );
                                        detail.description = e.target.value;
                                    }
                                    }
                                /></td>
                        </tr>)
                            }
                        </tbody>
                    </table>
                </div>
                {/*<div className="form-group col-md-6">*/}
                {/*    <label>Công việc <span className='required'>*</span></label>*/}
                {/*    <Select*/}
                {/*        isClearable*/}
                {/*        {...register("jobId", { required: true })}*/}
                {/*        defaultValue={updateItem?.jobId ? { label: updateItem?.jobName, value: updateItem?.jobId } : { label: "Quảng bá", value: 6 }}*/}
                {/*        placeholder="Chọn Công việc"*/}
                {/*        closeMenuOnSelect={true}*/}
                {/*        onChange={(data) => {*/}
                {/*            setValue("jobId", data ? data.value : null);*/}
                {/*            setJobType(data ? data.value : null);*/}
                {/*        }}*/}
                {/*        options={job.map(item => { return { label: item.name, value: item.id } })}*/}
                {/*        noOptionsMessage={() => "Không tồn tại"}*/}
                {/*    />*/}
                {/*    {errors.jobId && errors.jobId.type === "required" && (*/}
                {/*        <span className="error">Trường này là bắt buộc</span>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*<div className="form-group col-md-6">*/}
                {/*    <label>{jobType == 6 ? "Tổng số lượt quảng bá" : "Thời lượng" }<span className='required'>*</span></label>*/}
                {/*    <input*/}
                {/*        className="form-control"*/}
                {/*        type="text"*/}
                {/*        name="timeQuatity"*/}
                {/*        placeholder={jobType == 6 ? "Tổng số lượt quảng bá" : "Thời lượng"}*/}
                {/*        onChange={(e) => {*/}
                {/*            if (jobType == 6)*/}
                {/*                setValue(*/}
                {/*                    "timeQuatity",*/}
                {/*                    e.target.value.replace(patternNumber, "")*/}
                {/*                )*/}
                {/*        }*/}
                {/*        }*/}
                {/*        ref={register({ required: true })}*/}
                {/*    />*/}
                {/*    {errors.timeQuatity && errors.timeQuatity.type === "required" && (*/}
                {/*      <span className="error">Trường này là bắt buộc</span>*/}
                {/*    )}*/}
                    
                {/*</div>*/}
                
            </div>
            <div className="row">
                {/*
                    <div className="form-group col-md-6">
                    <label>Người xác nhận</label>
                    <Select
                        isClearable
                        {...register("herdsmanConfirmId")}
                        defaultValue={updateItem?.herdsmanConfirmId ? {label: updateItem?.herdsmanConfirmName, value: updateItem?.herdsmanConfirmId} : null}
                        placeholder="Chọn Swat"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("herdsmanConfirmId", data ? data.value : null)}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                </div>
                    */ }
                <div className="form-group col-md-12">
                    <label>Mô tả</label>
                    <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={updateItem?.description}
                        placeholder="Nhập Mô tả"
                        ref={register()}
                    />
                </div>
            </div>
            <div className="row">
                {/* <div className="form-group col-md-6">
                    <label>Trạng thái hoạt động</label>
                    <div className="switch-button switch-button-yesno ml-5">
                        <input
                            type="checkbox"
                            checked={checkActive}
                            name="isActived"
                            id="isActived"
                            ref={register()}
                            onChange={() => setCheckActive(prev => !prev)}
                        />
                        <span><label htmlFor="isActived"></label></span>
                    </div>
                </div> 
                <div className="form-group col-md-6">
                    <label>Trạng thái xác nhận</label>
                    <div className="switch-button switch-button-yesno ml-5">
                        <input
                            type="checkbox"
                            checked={checkConfirm}
                            name="isConfirm"
                            id="isConfirm"
                            ref={register()}
                            onChange={() => setCheckConfirm(prev => !prev)}
                        />
                        <span><label htmlFor="isConfirm"></label></span>
                    </div>
                </div>
                */}
            </div>
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
              Lưu
            </button>
        </form>
    )
}