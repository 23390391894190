import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import { patternNumber } from '../../../common/validatePhoneNumber';
import { optionsCustomerStatus } from '../../../common/constant';

export default function FormChangeDelivery(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        group, department, zion, district, position, herdsman,
        onSubmitChanges } = props;
    const buttonSubmitRef = useRef(null);

    const [optionsHerdsman, setOptionsHerdsman] = useState(herdsman.map(item => {return {label: item.name, value: item.id}}));
    const [selectedHerdsman, setSelectedHerdsman] = useState([]);
    
    useEffect(() => {
        setValue("fullName", updateItem?.fullName);
        setValue("yearOfBirth", updateItem?.yearOfBirth);
        setValue("departmentId", updateItem?.departmentId);
        setValue("districtId", updateItem?.districtId);
        setValue("status", updateItem?.status);
        setValue("description", updateItem?.description);
        setValue("career", updateItem?.career);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }

        const dataInput = {
            ...data,
            transportOrderId: updateItem?.id
        };
        onSubmitChanges(dataInput);
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Chọn Người giao <span className='required'>*</span></label>
                    <Select
                        isClearable
                        {...register("herdsmanDeliverId", { required: true })}
                        defaultValue={
                            (updateItem.deliveries && Array.isArray(updateItem.deliveries) && updateItem.deliveries.length > 0)
                            ? {label: updateItem.deliveries[0].herdsmanDeliverName, value: updateItem.deliveries[0].herdsmanDeliverId} : null}
                        placeholder="Chọn Người giao"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("herdsmanDeliverId", data ? data.value : null)}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {errors.herdsmanDeliverId && errors.herdsmanDeliverId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}