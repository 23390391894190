import React, { useEffect, useRef, useState } from 'react';
import { APIUrlDefault, getUserInfo } from '../../utils/configuration';
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import * as styles from "./profile.module.scss"
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as config from '../../common/config'
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../language/vi-VN.json";
import dayjs from 'dayjs';
import PasswordUpdate from '../../modules/my-account/update-password/update-password'
import AccountUpdate from '../../modules/my-account/update-my-account/update-my-account.view'
import { useMediaQuery } from 'react-responsive';
import CircularProgress from "@material-ui/core/CircularProgress";
import MyExaminationWidget from '../examination-dashboard/components/my-examination-widget';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function ProfilePage() {
    const [currentUser] = useState(getUserInfo());
    const [week, setWeek] = useState();
    const [herdsman, setHerdsman] = useState(null);
    const [customers, setCustomers] = useState();
    const [baptem, setBaptem] = useState(0);
    const [laxaro, setLaxaro] = useState(0);
    const [swat, setSwat] = useState(0);

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1120px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1119px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const GetDetailHerdsman = async () => {
        try {
            if (currentUser?.herdsmanId == undefined || currentUser?.herdsmanId=="") return;
            const res = await herdsmanManagementAction.GetDetailHerdsman(currentUser?.herdsmanId);
            if (res && res.content) {
                setHerdsman(res.content);
            }
        } catch (err) {
            setHerdsman(null);
            throw err;
        }
    };

    const GetCurrentWeek = async () => {
        try {
            const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
            }
        } catch (err) {
            ;

        } finally {
            ;
        }
    };

    //const GetMyCustomer = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression) => {
    const GetMyCustomer = async () => {
        //showLoading(true);
        try {
            //const res = await customerManagementAction.GetMyCustomer(pageIndex, pageSize, sortExpression);
            const res = await customerManagementAction.GetMyCustomer();

            if (res && res.content) {
                setCustomers(res.content);
                setBaptem(res.content.filter(x => x.status >= 60 && x.customerType===0).length + res.content.filter(x => x.status === 80).length);
                setLaxaro(res.content.filter(x => x.status >= 60 && x.customerType===1).length);
                setSwat(res.content.filter(x => x.status === 80).length);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            //showLoading(false);
        }
    };

    useEffect(() => {
        if ( !currentUser ) return;
        GetDetailHerdsman();
        GetMyCustomer();
    }, [currentUser])

    const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);
    useEffect(() => {
        calculateAspectRatio();

        const img = new Image();
        if (herdsman?.herdsmanCards && herdsman?.herdsmanCards.length > 0) {
            const imageLoadPromises = herdsman.herdsmanCards.map((card, index) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        resolve();
                    };
                    img.src = APIUrlDefault + (isDesktopOrLaptop ? card.cardImageDesktopPath : card.cardImageMobilePath).replace(/\\/g, "/");
                });
            });
    
            // Wait for all image promises to resolve
            Promise.all(imageLoadPromises).then(() => {
                setBackgroundImageLoaded(true);
            });
        } else {
            const img = new Image();
            img.onload = () => setBackgroundImageLoaded(true);
            if (isDesktopOrLaptop) {
                img.src = APIUrlDefault + (herdsman?.cardImageDesktopPath !== "" ? herdsman?.cardImageDesktopPath?.replace(/\\/g, "/") : "Uploads/Template/desktop-gold-blank.jpg");
            } else {
                img.src = APIUrlDefault + (herdsman?.cardImageMobilePath !== "" ? herdsman?.cardImageMobilePath?.replace(/\\/g, "/") : "Uploads/Template/mobile-gold-blank.jpg");
            }
        }

        // Clean up function
        return () => {
            img.onload = null;
        };
    }, [herdsman, isDesktopOrLaptop])

    const talentCardWrapper = useRef(null);
    const [talentCardWidthRatioToWindow, setTalentCardWidthRatioToWindow] = useState(null);
    const [customStyleTalentCard, setCustomStyleTalentCard] = useState(null);
    
    const calculateAspectRatio = () => {
        if (talentCardWrapper.current) {
            const width = talentCardWrapper.current.offsetWidth;
            // const height = talentCardWrapper.current.offsetHeight;
            // const aspectRatio = width / height;

            const windowWidth = window.innerWidth;
            const ratio = width / windowWidth;


            setTalentCardWidthRatioToWindow(ratio);
        }
    };

    useEffect(() => {
        // console.log("talentCardWidthRatioToWindow", talentCardWidthRatioToWindow);
        // Với từng aspect ratio thì set padding dựa trên container width tương ứng
        if ( talentCardWidthRatioToWindow < 0.3 ) {
            // setCustomStyleTalentCard({
            //     paddingLeft: '3.4cqw'
            // })
        } else if ( talentCardWidthRatioToWindow > 0.3 && talentCardWidthRatioToWindow <= 0.4 ) {
            setCustomStyleTalentCard({
                paddingLeft: '3.3cqw'
        })
        } else if ( talentCardWidthRatioToWindow > 0.4 && talentCardWidthRatioToWindow <= 0.5 ) {
            setCustomStyleTalentCard({
                paddingLeft: '4.3cqw'
        })
        } else if ( talentCardWidthRatioToWindow > 0.5 && talentCardWidthRatioToWindow <= 0.8 ) {
            if (isTabletOrMobile) {
                setCustomStyleTalentCard({
                    paddingLeft: '4.4cqw'
                })
            } else {
                setCustomStyleTalentCard({
                    paddingLeft: '3.8cqw'
                })
            }
        } else if ( talentCardWidthRatioToWindow > 0.8 && talentCardWidthRatioToWindow <= 0.9 ) {
            setCustomStyleTalentCard({
                paddingLeft: '8.4cqw'
            })
        } else {
            setCustomStyleTalentCard({
                paddingLeft: '7.7cqw'
            })
        }
    }, [talentCardWidthRatioToWindow])
    
    useEffect(() => {
        GetCurrentWeek();
        // Add event listener for window resize
        window.addEventListener('resize', calculateAspectRatio);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', calculateAspectRatio);
        };
        
    }, []);

    return (
        <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="user-avatar text-center d-block">
                            <img src={(!currentUser?.avatar) ? "/assets/images/avatar.png" : APIUrlDefault + currentUser?.avatar} alt="User Avatar" className="rounded-circle user-avatar-xxl"/>
                        </div>
                        <div className="text-center">
                            <h2 className="font-24 mb-0">{herdsman?.fullName}</h2>
                        </div>
                    </div>
                    <div className="card-body border-top">
                        <h3 className="font-16">Thông tin tài khoản</h3>
                        <div className="">
                            <ul className="list-unstyled mb-0">
                            <li className="mb-2"><i className="fas fa-fw fa-envelope mr-2"></i>{herdsman?.email}</li>
                            <li className="mb-0"><i className="fas fa-fw fa-phone mr-2 text-success"></i>{herdsman?.phoneNumber}</li>
                                <li className="mb-0"><i className="fab fa-telegram-plane mr-2 text-primary"></i>{herdsman?.telegramUser ? herdsman?.telegramUser : (herdsman?.chatTelegramId ? herdsman?.chatTelegramId : <a href="http://t.me/sarang_hn_bot" target="telegram">Bấm để kết nối telegram</a>)}</li>
                        </ul>
                        </div>
                    </div>
                    <div className="card-body border-top">
                        <div className='d-flex justify-content-between align-content-center'>
                            <h3 className="font-16">Thẻ Ta-lâng <b>({
                                herdsman?.cardType === 0 ? "BASIC" : (
                                    herdsman?.cardType ===1 ? "SILVER" : (
                                        herdsman?.cardType === 2 ? "GOLD" : "PLATINUM"
                                    )
                                )
                            })</b></h3>
                            <img src="/assets/images/rank/rank_none.png" height="32px"></img>
                        </div>
                        <div>
                            <div>Chiên đã Báp-têm: <b>{ baptem}</b></div>
                            <div>La-xa-rơ: <b>{ laxaro}</b></div>
                            <div>Swat: <b>{ swat}</b></div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12 col-12">
                <div className="influence-profile-content pills-regular">
                    <ul className="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="pills-campaign-tab" data-toggle="pill" href="#pills-campaign" role="tab" aria-controls="pills-campaign" aria-selected="true">Thẻ ta-lâng</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="examination-tab" data-toggle="pill" href="#examination" role="tab" aria-controls="examination" aria-selected="false">Điểm xếp hạng</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="account-update-tab" data-toggle="pill" href="#account-update" role="tab" aria-controls="account-update" aria-selected="false">Thông tin</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="change-pass-tab" data-toggle="pill" href="#change-pass" role="tab" aria-controls="change-pass" aria-selected="false">Đổi mật khẩu</a>
                        </li>
                        
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-campaign" role="tabpanel" aria-labelledby="pills-campaign-tab">
                            {
                                herdsman?.herdsmanCards && herdsman?.herdsmanCards.length > 0 ? (
                                    herdsman?.herdsmanCards.map((item, index) => (
                                        <div key={index} className="card">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    {
                                                        isDesktopOrLaptop ? <div className="media influencer-profile-data d-flex align-items-center p-2" ref={talentCardWrapper}>
                                                            {/* <div className="media-body ">
                                                                <div className="influencer-profile-data">
                                                                    <img src="assets/images/thetalang-exm.jpg" alt="User Avatar" width="100%"/>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-1"></div>
                                                            {
                                                                backgroundImageLoaded ? (
                                                                    <div className={`${styles.talent_card}`} style={{...customStyleTalentCard, backgroundImage: `url("${APIUrlDefault + (item.cardImageDesktopPath !== "" ? item.cardImageDesktopPath?.replace(/\\/g, "/") : "Uploads/Template/desktop-gold-blank.jpg")}")` }}>
                                                                        <div className={`${styles.header} row`}>
                                                                            <div className='col-3'></div>
                                                                            <div className='col-9'>
                                                                                {/*<span className={`${styles.title}`}>Talent Card</span>*/}
                                                                            </div>
                                                                        </div>
                                                                        <div className={`${styles.body} row`}>
                                                                            <div className={` col-4`}> {/*${styles.rank}*/}
                                                                            </div>
                                                                            <div className={`${styles.table_wrapper} col-8`}>
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>STT</th>
                                                                                            <th>Họ và tên</th>
                                                                                            <th>Giới tính</th>
                                                                                            <th>Độ tuổi</th>
                                                                                            <th>Hạng mục</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            customers && customers.length > 0 ?
                                                                                                customers.filter(x => x.cardType === item.cardType).map((row, rowIndex) =>
                                                                                                (
                                                                                                    <tr key={rowIndex}>
                                                                                                        <td>{rowIndex + 1}</td>
                                                                                                        <td>{row.fullName}</td>
                                                                                                        <td>{row.gender == 0 ? "Nữ" : "Nam"}</td>
                                                                                                        <td>{row.departmentName}</td>
                                                                                                        <td>{row.statusName}</td>
                                                                                                        {/*<td>{dayjs(row["created_date"]).format("DD/MM/YYYY")}</td>*/}
                                                                                                    </tr>
                                                                                                )) : <tr><td colSpan={4}>Chưa có dữ liệu</td></tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`${styles.footer} row`}>
                                                                            <div className={`${styles.code_wrapper} col-8`}>
                                                                                {/*<div className={`${styles.code}`}>{herdsman?.code}</div>*/}
                                                                                {/*<div className={`${styles.name}`}>{herdsman?.fullName}</div>*/}
                                                                            </div>
                                                                            {/* <div className={`${styles.valid} col-3`}> */}
                                                                            <div className={`${styles.date_wrapper} col-4`}>
                                                                                {/*<div className={`${styles.valid_thru}`}>Valid thru</div>*/}
                                                                                {/*<div className={`${styles.icon}`}>*/}
                                                                                {/*    <i className="fas fa-play"></i>*/}
                                                                                {/*</div>*/}
                                                                                {/*<div className={`${styles.date}`}>12/2024</div>*/}
                                                                            </div>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </div>
                                                                ) : <>
                                                                {/* INSERT LOADING EFFECT */}
                                                                <div className={styles._loading_wrapper}>
                                                                    <CircularProgress color="inherit" />

                                                                </div>
                                                                </>
                                                            }
                                                            <div className="col-1"></div>
                                                            
                                                        </div> :
                                                            <div className="media influencer-profile-data d-flex align-items-center p-2"  ref={talentCardWrapper}>
                                                                {
                                                                    backgroundImageLoaded ? (
                                                                        <div className={`${styles.talent_card}`} style={{...customStyleTalentCard, backgroundImage: `url("${APIUrlDefault + (item?.cardImageMobilePath !== "" ? item?.cardImageMobilePath?.replace(/\\/g, "/") : "Uploads/Template/mobile-gold-blank.jpg")}")` }}>
                                                                            <div className={`${styles.header}`}>
                                                                            </div>
                                                                            <div className={`${styles.body} row justify-content-end`}>
                                                                                {/* <div className={` col-2`}>
                                                                                </div> */}
                                                                                <div className={`${styles.table_mobile} col-10`}>
                                                                                    {
                                                                                        customers && customers.length > 0 ?
                                                                                            customers.filter(x => x.cardType === item.cardType).map((row, rowIndex) =>
                                                                                            (
                                                                                                <span key={rowIndex} className={styles.table_mobile_content}>
                                                                                                    {rowIndex + 1}.
                                                                                                    {row.fullName} - {row.gender == 0 ? "Nữ" : "Nam"} - {row.departmentName}<br />
                                                                                                
                                                                                                    {/*<td>{dayjs(row["created_date"]).format("DD/MM/YYYY")}</td>*/}
                                                                                                </span>
                                                                                            )) : <></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className={`${styles.footer}`}>
                                                                                <div className={`${styles.code_wrapper} col-8`}>
                                                                                </div>
                                                                                <div className={`${styles.date_wrapper} col-4`}>
                                                                                </div>
                                                                                {/* </div> */}
                                                                            </div>
                                                                        </div>
                                                                    ) : <>
                                                                    {/* INSERT LOADING EFFECT */}
                                                                    <div className={styles._loading_wrapper}>
                                                                        <CircularProgress color="inherit" />

                                                                    </div>
                                                                    </>
                                                                }
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : 
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                {
                                                    isDesktopOrLaptop ? <div className="media influencer-profile-data d-flex align-items-center p-2" ref={talentCardWrapper}>
                                                        {/* <div className="media-body ">
                                                                <div className="influencer-profile-data">
                                                                    <img src="assets/images/thetalang-exm.jpg" alt="User Avatar" width="100%"/>
                                                                </div>
                                                            </div> */}
                                                        <div className="col-2"></div>
                                                        {
                                                            backgroundImageLoaded ? (
                                                                <div className={`${styles.talent_card}`} style={{ ...customStyleTalentCard, backgroundImage: `url("${APIUrlDefault + (herdsman?.cardImageDesktopPath !== "" ? herdsman?.cardImageDesktopPath?.replace(/\\/g, "/") : "Uploads/Template/desktop-gold-blank.jpg")}")` }}>
                                                                    <div className={`${styles.header} row`}>
                                                                        <div className='col-3'></div>
                                                                        <div className='col-9'>
                                                                            {/*<span className={`${styles.title}`}>Talent Card</span>*/}
                                                                        </div>
                                                                    </div>
                                                                    <div className={`${styles.body} row`}>
                                                                        <div className={` col-4`}> {/*${styles.rank}*/}
                                                                        </div>
                                                                        <div className={`${styles.table_wrapper} col-8`}>
                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>STT</th>
                                                                                        <th>Họ và tên</th>
                                                                                        <th>Giới tính</th>
                                                                                        <th>Độ tuổi</th>
                                                                                        <th>Hạng mục</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        customers && customers.length > 0 ?
                                                                                            customers.filter(x => x.status>=30 && x.status<=50).map((row, rowIndex) =>
                                                                                                <>
                                                                                                    {
                                                                                                        rowIndex <= 5 && <tr key={rowIndex}>
                                                                                                            <td>{rowIndex + 1}</td>
                                                                                                            <td>{row.fullName}</td>
                                                                                                            <td>{row.gender == 0 ? "Nữ" : "Nam"}</td>
                                                                                                            <td>{row.departmentName}</td>
                                                                                                            <td>{row.statusName}</td>
                                                                                                        </tr>
                                                                                                    }
                                                                                                </>
                                                                                            ) : <tr><td colSpan={4}>Chưa có dữ liệu</td></tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`${styles.footer} row`}>
                                                                        <div className={`${styles.code_wrapper} col-8`}>
                                                                            {/*<div className={`${styles.code}`}>{herdsman?.code}</div>*/}
                                                                            {/*<div className={`${styles.name}`}>{herdsman?.fullName}</div>*/}
                                                                        </div>
                                                                        {/* <div className={`${styles.valid} col-3`}> */}
                                                                        <div className={`${styles.date_wrapper} col-4`}>
                                                                            {/*<div className={`${styles.valid_thru}`}>Valid thru</div>*/}
                                                                            {/*<div className={`${styles.icon}`}>*/}
                                                                            {/*    <i className="fas fa-play"></i>*/}
                                                                            {/*</div>*/}
                                                                            {/*<div className={`${styles.date}`}>12/2024</div>*/}
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                            ) : <>
                                                                {/* INSERT LOADING EFFECT */}
                                                                <div className={styles._loading_wrapper}>
                                                                    <CircularProgress color="inherit" />

                                                                </div>
                                                            </>
                                                        }
                                                        <div className="col-2"></div>

                                                    </div> :
                                                        <div className="media influencer-profile-data d-flex align-items-center p-2" ref={talentCardWrapper}>
                                                            {
                                                                backgroundImageLoaded ? (
                                                                    <div className={`${styles.talent_card}`} style={{ ...customStyleTalentCard, backgroundImage: `url("${APIUrlDefault + (herdsman?.cardImageMobilePath !== "" ? herdsman?.cardImageMobilePath?.replace(/\\/g, "/") : "Uploads/Template/mobile-gold-blank.jpg")}")` }}>
                                                                        <div className={`${styles.header}`}>
                                                                        </div>
                                                                        <div className={`${styles.body} row justify-content-end`}>
                                                                            {/* <div className={` col-2`}>
                                                                                </div> */}
                                                                            <div className={`${styles.table_mobile} col-10`}>
                                                                                {
                                                                                    customers && customers.length > 0 ?
                                                                                        customers.filter(x => x.status>=30 && x.status<=50).map((row, rowIndex) =>
                                                                                        (
                                                                                            <span key={rowIndex} className={styles.table_mobile_content}>
                                                                                                {rowIndex + 1}.
                                                                                                {row.fullName} - {row.gender == 0 ? "Nữ" : "Nam"} - {row.departmentName}<br />

                                                                                                {/*<td>{dayjs(row["created_date"]).format("DD/MM/YYYY")}</td>*/}
                                                                                            </span>
                                                                                        )) : <></>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className={`${styles.footer}`}>
                                                                            <div className={`${styles.code_wrapper} col-8`}>
                                                                            </div>
                                                                            <div className={`${styles.date_wrapper} col-4`}>
                                                                            </div>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </div>
                                                                ) : <>
                                                                    {/* INSERT LOADING EFFECT */}
                                                                    <div className={styles._loading_wrapper}>
                                                                        <CircularProgress color="inherit" />

                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                            }
                            
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="m-b-10">AE được hỗ trợ thành SWAT</h3>
                                </div>
                                <div className="border-top card-footer p-2">
                                    <div className={styles._block}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Tên</th>
                                                    <th>Số điện thoại</th>
                                                    <th>Địa chỉ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    customers && customers.length > 0 && customers.filter(x => x.status === 80).length > 0 ?
                                                        customers.filter(x => x.status === 80).map((row, rowIndex) =>
                                                        (
                                                            <tr key={rowIndex}>
                                                                <td>{rowIndex + 1}</td>
                                                                <td>{row.fullName}</td>
                                                                <td>{row.phoneNumber}</td>
                                                                <td>{row.districtName}, {row.provinceName}</td>
                                                            </tr>
                                                        )) : <tr><td colSpan={4}>Chưa có dữ liệu</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="account-update" role="tabpanel" aria-labelledby="account-update-tab">
                            <AccountUpdate/>
                        </div>
                        <div className="tab-pane fade" id="change-pass" role="tabpanel" aria-labelledby="change-pass-tab">
                            <PasswordUpdate/>
                        </div>
                        <div className="tab-pane fade" id="examination" role="tabpanel" aria-labelledby="examination-tab">
                            <MyExaminationWidget
                                userInfo={currentUser}
                                week={week}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}