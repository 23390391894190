import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import { patternNumber } from '../../../common/validatePhoneNumber';
import { optionsCustomerStatus } from '../../../common/constant';
import DepartmentHelper from '../../list-department/helper/department-helper';
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';
import { getUserInfo } from '../../../utils/configuration';

export default function FormAddDepartmentBatized(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, customerId, 
        department, departmentHelping,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    useEffect(() => {
        console.log(department);
    }, [department])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }
        
        const dataInput = { ...data, id: customerId };
        onSubmitAddEdit(dataInput);
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label>Chọn đội ace sẽ sinh hoạt sau báp têm</label>
                            <Select
                                {...register("departmentBaptizedId", { required: true })}
                                //defaultValue={updateItem?.districtId ? { label: updateItem?.districtName, value: updateItem?.districtId } : null}
                                placeholder="Chọn biệt đội"
                                closeMenuOnSelect={true}
                                onChange={(data) => setValue("departmentBaptizedId", data ? data.value : null)}
                                options={department.map(item => { return { label: item.name, value: item.id } })}
                                noOptionsMessage={() => "Không tồn tại"}
                            />
                            {errors.departmentBaptizedId && errors.departmentBaptizedId.type === "required" && (
                                <span className="error">Trường này là bắt buộc</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}