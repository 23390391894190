import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as appActions from "../../../core/app.store";

import { headCellsZionPoint } from '../head-cell-list';
import * as viVN from "../../../language/vi-VN.json";
import { NotificationMessageType} from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";

import * as reportAcademyAction from "../../../redux/store-sarang/report-academy/report-academy-management.store";

export default function ZionRankCupAcademy(props) {

    const { week, weekNumber } = props;
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    //const userInfo = getUserInfo();
    // -- Data to map with ids
    const [sumaryPoint, setSumaryPoint] = useState([]);

    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);

    useEffect(() => {
        GetListZionWeeklyRank(weekNumber);
    }, [weekNumber]);

    const GetListZionWeeklyRank = async (weekNumber) => {
        if (!week) return;
        if (weekNumber == 10) return GetListZionTotallyRank();

        showLoading(true);
        try {
            const res = await reportAcademyAction.GetListZionWeeklyRank(undefined, weekNumber ? weekNumber : week?.week, weekNumber && weekNumber < 5 ? 6 : weekNumber && weekNumber == 5 ? 7 : week?.month, week?.year, undefined, false);

            if (res && res.content) {
                setSumaryPoint(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const GetListZionTotallyRank = async () => {
        if (!week) return;

        showLoading(true);
        try {
            const res = await reportAcademyAction.GetListZionTotallyRank(undefined, undefined, false);

            if (res && res.content) {
                setSumaryPoint(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const renderTop = (top) => {
        return <div className="col-4">
            {
                sumaryPoint.length > top - 1 &&
                <div>
                        <img src={`assets/images/rank/${top === 1 ? "First" : top === 2 ? "Second" : "Third"}.png`} style={top == 1 ? { width: "50px" } : top == 2 ? { width: "40px", paddingTop: "10px" } : { width: "35px", paddingTop: "15px" }} />
                    <div style={{ marginTop: "10px", fontWeight: 700 }} >{
                            sumaryPoint[top - 1].zionName
                    }</div>
                    <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                            Math.round(sumaryPoint[top - 1].averagePoint *100)/100
                    }</div>
                </div>
            }
        </div>
    }

    const renderTableForDesktop = () => {
        return <>
            <div className="card">
                <h5 className="card-header">Đ/vực ưu tú ({week?.week ? "tuần " + week?.week : "tháng " + week?.month})</h5>
                <div className="card-body">
                    <div className="row text-center">
                        {
                            renderTop(2)
                        }
                        {
                            renderTop(1)
                        }
                        {
                            renderTop(3)
                        }
                    </div>
                    <div className="row text-center">
                        <div className="col-12 mb-2 dataTables" ref={scrollRoot}>
                            <table className="table dataTable table-hover " id="datatable" ref={scrollTable} >
                                <thead>
                                    <tr>
                                        <td style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Hạng</b></td>
                                        <td style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Địa vực</b></td>
                                        <td style={{ fontWeight: 'bold', textAlign: 'center' }}><b>Điểm TB</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.map((item, index) =>
                                            index > 2 && index <= 9 &&
                                            <tr>
                                            <td>{item.rank ? item.rank : index + 1}</td>
                                            <td>{item.zionName}</td>
                                            <td>{Math.round(item.averagePoint * 100) / 100}</td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                
            </div>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    
                    renderTableForDesktop()
                }
            </div>
        </div>
    )
}