import React, { useEffect, useState, useRef } from 'react';
import Select from "react-select";

import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";

import * as swatTrundoManagementAction from "../../../redux/store-sarang/swat-trundo/swat-trundo-management.store";
import * as departmentManagementAction from "../../../redux/store-sarang/department/department-management.store";
import * as festivalManagementAction from "../../../redux/store-sarang/festival-cog/festival-cog-management.store";
import DepartmentExamReport from '../../examination-dashboard/components/department-exam-report';
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import DepartmentHelper from '../../list-department/helper/department-helper';
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';
import BodyReport from './body-report';

export default function SwatTrundoSumaryHerdsmanReport() {
    //const userInfo = getUserInfo();
    const [loadingSwat, setLoadingSwat] = useState();

    const [herdsmanSumary, setHerdsmanSumary] = useState();
    const [department, setDepartment] = useState([]);
    const [festivalCog, setFestivalCog] = useState([]);
    const [departmentId, setDepartmentId] = useState();
    const [festivalId, setFestivalId] = useState();
    const [totalItem, setTotalItem] = useState(0);
    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(100);

    useEffect(() => {
        getLookupDepartment();
        getLookupFestival();
        GetSumaryHerdsman();
    }, [])

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupFestival = async () => {
        try {
            const res = await festivalManagementAction.GetLookupFestivalCog();
            if (res && res.content) {
                setFestivalCog(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    };

    const GetSumaryHerdsman = async (festivalCogId, departmentId, zionId) => {
        setLoadingSwat(true);
        try {
            const res = await swatTrundoManagementAction.GetSumaryHerdsman(festivalCogId, departmentId, zionId);

            if (res && res.content) {
                setHerdsmanSumary(res.content);
                setTotalItem(res.content.length);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoadingSwat(false);
        }
    };

    const formatNumber = (num) => {
        return num ? num.toLocaleString(undefined, { maximumFractionDigits: 2 }) : null;
    }
    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    {festivalCog ? <div className="form-group  col-12 col-md-6 col-lg-6  col-xl-6">
                        <label>Lễ hội trundo</label>
                        <Select
                            isClearable
                            placeholder="Chọn lễ hội trundo"
                            closeMenuOnSelect={true}
                            onChange={(data) => {
                                setFestivalId(data ? data.value : null);
                                GetSumaryHerdsman(data ? data.value : null, departmentId, null);
                            }}
                            options={festivalCog}
                            noOptionsMessage={() => "Không tồn tại"}
                        />
                    </div>
                        :
                        <></>
                    }
                    {department ? <div className="form-group  col-12 col-md-6 col-lg-6  col-xl-6">
                        <label>Ban/ đội/ nhóm</label>
                        <DepartmentTreeSelect
                            isClearable
                            //{...register("departmentId", {})}
                            onChange={(data) => {
                                setDepartmentId(data ? data.value : null);
                                GetSumaryHerdsman(festivalId, data ? data.value : null, null);
                            }}
                            placeholder="Chọn Ban"
                            options={DepartmentHelper.RenderOptions(department)}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                        :
                        <></>
                    }
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <BodyReport
                        scrollRoot={scrollRoot}
                        scrollTable={scrollTable}
                        nameString="Họ tên Swat"
                        data={herdsmanSumary}
                        columnName="herdsmanName"
                    />

                    <LoadingViewAria loading={loadingSwat} />
                </div>
                {
                    scrollTableWidth ?
                        <div className='scrollRoot'
                            onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                        >
                            <div style={{ width: `${scrollTableWidth}px` }}>
                                &nbsp;
                            </div>
                        </div> : <></>
                }
            </div>
        </div>
    )
}