import React, { useEffect, useState, useRef } from 'react';
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import DepartmentExamReport from '../examination-dashboard/components/department-exam-report';
;export default function ExamSumaryDepartmentReport() {
    //const userInfo = getUserInfo();
    const [week, setWeek] = useState();
    const [month, setMonth] = useState();
    const limit = 10;
    useEffect(() => {
        
    }, [])

    const GetCurrentWeek = async () => {
        try {
            const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
            }
        } catch (err) {
            ;
            
        } finally {
            ;
        }
    };

    return (
        <DepartmentExamReport
        />
    )
}