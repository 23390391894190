import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListDepartment = (pageIndex = 1, pageSize = 10, sortExpression = "", code = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    // code && params.append("code", code);
    // name && params.append("name", name);
    return service.get(ApiUrl.GetListDepartment, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailDepartment = (id) => {
    return service.get(ApiUrl.GetDetailDepartment.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateDepartment = (data) => {
    return service.post(ApiUrl.CreateDepartment, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateDepartment = (data) => {
    return service.put(ApiUrl.UpdateDepartment, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteDepartment = (id) => {
    return service.delete(ApiUrl.DeleteDepartment.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentDepartment = (id) => {
    return service.delete(ApiUrl.DeletePermanentDepartment.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveDepartment = (id) => {
    return service.put(ApiUrl.ActiveDepartment.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveDepartment = (id) => {
    return service.put(ApiUrl.DeActiveDepartment.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const GetLookupDepartment = (pageIndex = 1, pageSize = 500, sortExpression = "",code="", name="") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    // code && params.append("code", code);
    // name && params.append("name", name);
    return service.get(ApiUrl.GetListDepartment, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetListNoPermiss = (pageIndex = 1, pageSize = 500, sortExpression = "", code = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sortExpression", sortExpression);
    return service.get(ApiUrl.GetListNoPermiss, params).then((res) => { return res }).catch((err) => { throw err });
}

export const ConfirmRank = (data) => {
    return service.put(ApiUrl.ConfirmRank, data).then((res) => { return res }).catch(err => { throw err });
}