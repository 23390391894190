import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';

export default function FormAddEditFeedback(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        group, department, zion, herdsman, district, position,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    const [groupSelected1, setGroupSelected1] = useState(0);
    const [groupSelected2, setGroupSelected2] = useState(0);
    const [checkActive, setCheckActive] = useState(true);
    const [herdsmanType, setHerdsmanType] = useState();

    useEffect(() => {
        setCheckActive(updateItem?.isActived);
        setValue("title", updateItem ? updateItem?.title : "");
        setValue("content", updateItem ? updateItem?.content : "");
        setValue("herdsmanId", updateItem?.herdsmanId);
        setValue("parentId", updateItem?.parentId);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }

        if (data.Image && data.Image.length > 0) //Gán file vào data
            data.Image = data.Image[0];
        onSubmitAddEdit({ ...data, id: updateItem?.id, documentUploadId: updateItem?.documentUploadId});
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Tiêu đề <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        maxLength={150 }
                        name="title"
                        defaultValue={updateItem ? updateItem?.title : ""}
                        placeholder="Nhập tiêu đề"
                        ref={register({ required: true })}
                    />
                    {errors.firstName && errors.firstName.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="form-group col-12">
                    <label>Nội dung</label>
                    <textarea
                        rows="6"
                        className="form-control"
                        type="text"
                        maxLength={540 }
                        name="content"
                        defaultValue={updateItem ? updateItem?.content : ""}
                        placeholder="Nội dung"
                        ref={register()}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Ảnh đính kèm</label>
                    <input
                        className="note-image-input form-control-file note-form-control note-input"
                        type="file"
                        name="Image"
                        ref={register()}
                        accept="image/*"
                    />
                </div>
            </div>
           
            
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}