import React, { useEffect, useState, useRef } from 'react';

import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../language/vi-VN.json";

import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";
import DepartmentExamReport from '../examination-dashboard/components/department-exam-report';
import LoadingViewAria from '../../components/layout-sarang/loading-view-aria'
import DepartmentHelper from '../list-department/helper/department-helper';
import DepartmentTreeSelect from '../list-department/components/department-tree-select';

;export default function HerdsmanTalangSumaryReport() {
    //const userInfo = getUserInfo();
    const [loadingSwat, setLoadingSwat] = useState();

    const [herdsmanTalent, setHerdsmanTalent] = useState();
    const [department, setDepartment] = useState([]);
    const [departmentId, setDepartmentId] = useState();
    const [totalItem, setTotalItem] = useState(0);
    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(100);

    useEffect(() => {
        getLookupDepartment();
        GetDepartmentExaminationReport();
    }, [])

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.PrepareSortData(res.content.items));
            }
        } catch (err) {
            throw err;
        }
    };

    const GetDepartmentExaminationReport = async (dptmId) => {
        setLoadingSwat(true);
        try {
            const res = await customerManagementAction.GetListHerdsmanTalang(dptmId);

            if (res && res.content) {
                setHerdsmanTalent(res.content);
                setTotalItem(res.content.length);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoadingSwat(false);
        }
    };

    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    {department ? <div className="form-group col-md-3 col-sm-6">
                        <label>Ban/ đội/ nhóm</label>
                        <DepartmentTreeSelect
                            isClearable
                            //{...register("departmentId", {})}
                            onChange={(data) => {
                                setDepartmentId(data ? data.value : null);
                                GetDepartmentExaminationReport(data ? data.value : null);
                            }}
                            placeholder="Chọn Ban"
                            options={DepartmentHelper.RenderOptions(department)}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                        :
                        <></>
                    }
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="dataTables freeze-column" ref={scrollRoot}>
                            <table className="table table-bordered table-hover dataTable" ref={scrollTable} id="datatable">
                                <thead>
                                    <tr>
                                        <th className="freeze" rowSpan={2} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '100px' }}>Swat</th>
                                        <th style={{ minWidth: '90px' }} rowSpan={2}>Biệt đội</th>
                                        <th style={{ minWidth: '60px' }} rowSpan={2}>Hạng thẻ</th>
                                        <th colSpan={5}>Ta-lâng</th>
                                        {/*{weeks.map((item, index) => <th colSpan={optionsExamination.length * 2 + 1}>Tuần {index + 1}</th>)}*/}
                                        {/*<th colSpan={optionsExamination.length * 2 + 1} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>Tháng</th>*/}
                                    </tr>
                                    <tr>
                                        <th style={{ minWidth: '100px' }}>Họ tên</th>
                                        <th>Giới tính</th>
                                        <th>Độ tuổi</th>
                                        <th>Phân loại</th>
                                        <th>Giai đoạn</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        herdsmanTalent && herdsmanTalent.length > 0 && herdsmanTalent.map((itemTalent, indexTalent) => <>
                                            <tr>
                                                <td rowSpan={itemTalent.customers.length}>{indexTalent+1}. {itemTalent.herdsman.fullName}</td>
                                                <td rowSpan={itemTalent.customers.length}>{itemTalent.herdsman.departmentName}</td>
                                                <td rowSpan={itemTalent.customers.length}>{itemTalent.herdsman.cardType === 0 ? "Basic" : itemTalent.herdsman.cardType === 1 ? "Silver" : itemTalent.herdsman.cardType === 2 ? "Gold" : "Platinum"}
                                                   <br/>({itemTalent.herdsman.talentAmount } ta-lâng)
                                                </td>
                                                {
                                                    itemTalent.customers && itemTalent.customers.length > 0 && <>
                                                        <td>1. {itemTalent.customers[0].fullName}
                                                            {itemTalent.customers[0]?.status >= 70 && itemTalent.customers[0].customerType==0 ?
                                                                <img title={itemTalent.customers[0].statusName}
                                                                    src={require("../../assets/icon/fb_tick.png")}
                                                                    alt="Tick"
                                                                /> : <></>}
                                                        </td>
                                                        <td>{itemTalent.customers[0].gender === 0 ? "Nữ" : "Nam"}</td>
                                                        <td>{itemTalent.customers[0].departmentName}</td>
                                                        <td>{itemTalent.customers[0].customerType === 0 ? "Ch" : (itemTalent.customers[0].customerType === 1 ? "Lxr" : (itemTalent.customers[0].status===80 ? "TĐ=>SWAT" : ""))}</td>
                                                        <td>{itemTalent.customers[0].statusName}</td>
                                                    </>
                                                }
                                            </tr>
                                            {
                                                itemTalent.customers && itemTalent.customers.length > 0 && itemTalent.customers.map((itemCus, index) => <>
                                                    {
                                                        index > 0 && <tr>
                                                            <td style={{ position: 'inherit', backgroundColor: 'inherit' }}>{index + 1}. {itemCus.fullName}
                                                                {itemCus?.status >= 70 && itemCus.customerType == 0 ?
                                                                    <img title={itemCus.statusName}
                                                                        src={require("../../assets/icon/fb_tick.png")}
                                                                        alt="Tick"
                                                                    /> : <></>}
                                                            </td>
                                                            <td>{itemCus.gender === 0 ? "Nữ" : "Nam"}</td>
                                                            <td>{itemCus.departmentName}</td>
                                                            <td>{itemCus.customerType === 0 ? "Ch" : (itemCus.customerType === 1 ? "Lxr" : (itemCus.status === 80 ? "TĐ=>SWAT" : ""))}</td>
                                                            <td>{itemCus.statusName}</td>
                                                        </tr>
                                                    }
                                                </>)
                                            }
                                        </>)
                                    }
                                </tbody>
                            </table>
                        </div>

                        <LoadingViewAria loading={loadingSwat} />
                    </div>
                    {
                        scrollTableWidth ?
                            <div className='scrollRoot'
                                onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                            >
                                <div style={{ width: `${scrollTableWidth}px` }}>
                                    &nbsp;
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
        </div>
    )
}