import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';

export default function FormReplyFeedback(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        group, department, zion, herdsman, district, position,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    const [groupSelected1, setGroupSelected1] = useState(0);
    const [groupSelected2, setGroupSelected2] = useState(0);
    const [checkActive, setCheckActive] = useState(true);
    const [herdsmanType, setHerdsmanType] = useState();

    useEffect(() => {
        setCheckActive(updateItem?.isActived);
        setValue("title", updateItem ? updateItem?.title : "");
        setValue("content", updateItem ? updateItem?.content : "");
        setValue("herdsmanId", updateItem?.herdsmanId);
        setValue("parentId", updateItem?.parentId);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }

        if (data.Image && data.Image.length > 0) //Gán file vào data
            data.Image = data.Image[0];
        else
            data.Image = null;
        onSubmitAddEdit({ ...data, parentId: updateItem?.id});
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h4>{ updateItem?.title}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5>{updateItem?.herdsmanName}</h5>
                    <span>{dayjs(updateItem.created_date).format("DD/MM/YYYY hh:mm:ss")}</span>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <span>{updateItem?.content}</span>
                </div>
            </div>
            {
                updateItem?.documentUploadId && <div className="row">
                    <div className="col-12">
                        <span><img src={"https://swat-api.sarang.live/" + updateItem?.fileAttach?.filePreview} title={ updateItem?.title} width="100%"/></span>
                    </div>
                </div>
            }
            {
                updateItem?.children && updateItem?.children.length > 0 ? 
                    updateItem.children.map((item, index) => <div className="row">
                        <div className="col-1"></div>
                        <div className="col-11">
                            <b>Trả lời: </b> {item.content }
                        </div>
                    </div>)
                : <></>
            }
            <div className="row">
                <div className="col-1"></div>
                <div className="col-11">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="row">
                            <div className="form-group col-12">
                                <label><b>Trả lời</b></label>
                                <textarea
                                    rows="6"
                                    className="form-control"
                                    type="text"
                                    name="content"
                                    maxLength={1500}
                                    defaultValue={updateItem ? updateItem?.content : ""}
                                    placeholder="Trả lời"
                                    ref={register()}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label>Ảnh đính kèm</label>
                                <input
                                    className="note-image-input form-control-file note-form-control note-input"
                                    type="file"
                                    name="Image"
                                    ref={register()}
                                    accept="image/*"
                                />
                            </div>
                        </div>


                        <button ref={buttonSubmitRef} style={{ display: "none" }} type="submit" className="btn btn-primary">
                            Lưu
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}