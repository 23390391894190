const headCellsSheepAnuallyReport = [
    {
        id: 'Status',
        numeric: false,
        disablePadding: true,
        label: 'Bước/Tháng',
        className: 'pt-3 pb-3',
        style: { minWidth: "40px" }
    },
    {
        id: 'thang1',
        numeric: false,
        disablePadding: true,
        label: 'T.1',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang2',
        numeric: false,
        disablePadding: true,
        label: 'T.2',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang3',
        numeric: false,
        disablePadding: true,
        label: 'T.3',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang4',
        numeric: false,
        disablePadding: true,
        label: 'T.4',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang5',
        numeric: false,
        disablePadding: true,
        label: 'T.5',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang6',
        numeric: false,
        disablePadding: true,
        label: 'T.6',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang7',
        numeric: false,
        disablePadding: true,
        label: 'T.7',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang8',
        numeric: false,
        disablePadding: true,
        label: 'T.8',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang9',
        numeric: false,
        disablePadding: true,
        label: 'T.9',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang10',
        numeric: false,
        disablePadding: true,
        label: 'T.10',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang11',
        numeric: false,
        disablePadding: true,
        label: 'T.11',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: 'thang12',
        numeric: false,
        disablePadding: true,
        label: 'T.12',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    },
    {
        id: '',
        numeric: false,
        disablePadding: true,
        label: 'Năm',
        className: 'pt-3 pb-3',
        style: { minWidth: "20px" }
    }
];

export default headCellsSheepAnuallyReport;