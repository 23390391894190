import React, { useEffect, useState, useRef } from 'react';
import Select from "react-select";

import { NotificationMessageType, formatNumber } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";

import * as swatTrundoManagementAction from "../../../redux/store-sarang/swat-trundo/swat-trundo-management.store";
import * as departmentManagementAction from "../../../redux/store-sarang/department/department-management.store";
import * as festivalManagementAction from "../../../redux/store-sarang/festival-cog/festival-cog-management.store";
import DepartmentExamReport from '../../examination-dashboard/components/department-exam-report';
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import DepartmentHelper from '../../list-department/helper/department-helper';
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';

export default function BodyReport(props) {
    const { scrollRoot, scrollTable, data, columnName, nameString } = props;

    return (
        <div className="dataTables freeze-column" ref={scrollRoot}>
            {data && data.length > 0 &&
            <table className="table table-bordered table-hover dataTable" ref={scrollTable} id="datatable">
                <thead>
                    <tr>
                        <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '100px' }} rowSpan={2}>{nameString}</th>
                        {
                            data && data.length > 0 && data[0].statusQuantities && data[0].statusQuantities.map((item, index) => (
                                <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }} colSpan={2}>{item.statusName}</th>
                            ))
                        }
                        <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }} rowSpan={2}>Tổng</th>
                    </tr>
                    <tr>
                        {
                            data && data.length > 0 && data[0].statusQuantities && data[0].statusQuantities.map((item, index) => (<>
                                <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }}>SL</th>
                                <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }}>Điểm</th>
                            </>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.map((item, index) => (
                            index < data.length - 1 && <tr key={index}>
                                <td style={{ verticalAlign: 'middle', fontWeight: index == data.length - 1 ? 'bold' : '', textAlign: index == data.length - 1 ? 'center' : '' }}>{item[columnName]}</td>
                                {
                                    item.statusQuantities && item.statusQuantities.map((itemStatus, itemIndex) => (<>
                                        <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: index == data.length - 1 ? 'bold' : '' }}>{formatNumber(itemStatus.quantity)}</td>
                                        <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: index == data.length - 1 ? 'bold' : '' }}>{formatNumber(itemStatus.point)}</td>
                                    </>
                                    ))
                                }
                                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: index == data.length - 1 ? 'bold' : '' }}>{formatNumber(item.sumaryPoint)}</td>
                            </tr>
                        ))
                    }
                </tbody>
                <tfoot>
                    {
                        data && data.length > 0 &&
                        <tr>
                                <td style={{ verticalAlign: 'middle', fontWeight: 'bold', textAlign: 'center' }}>{data[data.length - 1][columnName]}</td>
                            {
                                    data[data.length - 1].statusQuantities && data[data.length - 1].statusQuantities.map((itemStatus, itemIndex) => (<>
                                    <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold' }}>{formatNumber(itemStatus.quantity)}</td>
                                    <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold' }}>{formatNumber(itemStatus.point)}</td>
                                </>
                                ))
                            }
                                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold' }}>{formatNumber(data[data.length - 1].sumaryPoint)}</td>
                        </tr>

                    }
                </tfoot>
                </table>
            }
        </div>
    )
}