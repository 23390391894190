import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Select from "react-select";
import { getUserInfo } from '../../../utils/configuration';

export default function FormAddEditTask(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, customerForCreateTask,
        herdsman, herdsmanOwn, customer,
        handleSubmitAddEditTask,taskDate,onSubmitAddTask, onSubmitEditTask, onSubmitDeleteTask } = props;
    const buttonSubmitRef = useRef(null);
    const [optionsHerdsman, setOptionsHerdsman] = useState(herdsman.filter(x => x.id != getUserInfo()?.herdsmanId).map(item => { return { label: item.name, value: item.id } }));
    const [optionsHerdsmanOwn, setOptionsHerdsmanOwn] = useState(herdsmanOwn.filter(x => x.id != getUserInfo()?.herdsmanId).map(item => { return { label: item.name, value: item.id } }));
    
    const [currentLoggedInHerdsman] = useState({ label: getUserInfo()?.fullName, value: getUserInfo()?.herdsmanId });
    const [selectedHerdsman, setSelectedHerdsman] = useState([]);

    useEffect(() => {
        setCheckActive(updateItem?.isActived);
        setCheckNeedHelp(updateItem?.needHelp);
        setCheckIsDateConcurency(updateItem?.isDateConcurency);
        setValue("title", updateItem?.title);
        setValue("content", updateItem?.content);
        setValue("status", updateItem?.status);
        //setValue("herdsmanId", getUserInfo()?.herdsmanId);
        setValue("herdsmanId", updateItem?.herdsmanId ? updateItem?.herdsmanId : (currentLoggedInHerdsman?.value ? currentLoggedInHerdsman?.value : null));
        setValue("address", updateItem?.address);
        setValue("description", updateItem?.description);
        setValue("priotrity", updateItem?.priotrity);
        setValue("contentHelping", updateItem?.contentHelping);
        setValue("herdsmanHelpingId1", updateItem?.herdsmanHelpingId1);
        setValue("herdsmanHelpingId2", updateItem?.herdsmanHelpingId2);

        if (customerForCreateTask) {
            setValue("customerId", customerForCreateTask.id);
        } else {
            setValue("customerId", updateItem?.customerId);
        }

        setValue("date", dayjs(updateItem?.datingTime ? updateItem?.datingTime : taskDate).format());
        setValue("time", dayjs(updateItem?.datingTime ? updateItem?.datingTime : taskDate).format());

        const selectedHerdsmanTemp = [];

        if (updateItem?.herdsmanHelpingId1) {
            const filtered = optionsHerdsman.find(item => item.value === updateItem.herdsmanHelpingId1);
            if (filtered) selectedHerdsmanTemp.push(filtered);
        }
        if (updateItem?.herdsmanHelpingId2) {
            const filtered = optionsHerdsman.find(item => item.value === updateItem.herdsmanHelpingId2);
            if (filtered) selectedHerdsmanTemp.push(filtered);
        }
        setSelectedHerdsman(selectedHerdsmanTemp);
        setValue("selectedHerdsman", selectedHerdsmanTemp);
    }, [])

    const { register, handleSubmit, errors, setValue, control } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const [checkActive, setCheckActive] = useState(true);
    const [checkNeedHelp, setCheckNeedHelp] = useState(false);
    const [checkIsDateConcurency, setCheckIsDateConcurency] = useState(true);

    const onSubmit = (data) => {
        if (!data) {
          return;
        }

        const dateData = dayjs(data.date);
        const datingTime = dayjs(data.time)
            .set('date', dateData.get("date"))
            .set('month', dateData.get("month"))
            .set('year', dateData.get("year")).format();

        const dataInput = {
            ...data, datingTime: datingTime, id: updateItem?.id,
            isActived: updateItem?.isActived ?? true,
            isDateConcurency: updateItem?.isDateConcurency ?? true,
            status: updateItem?.status ?? 10,
            priotrity: updateItem?.priotrity ?? 1,
            herdsmanHelpingId1: updateItem?.departmentHelpingId ? updateItem?.herdsmanHelpingId1 : selectedHerdsman && selectedHerdsman.length > 0 ? selectedHerdsman[0].value : null,
            herdsmanHelpingId2: updateItem?.departmentHelpingId ? updateItem?.herdsmanHelpingId2 : selectedHerdsman && selectedHerdsman.length > 1 ? selectedHerdsman[1].value : null,
            departmentHelpingId: updateItem?.departmentHelpingId
        }
        
        handleSubmitAddEditTask(dataInput);
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Chủ đề tiếp xúc <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="title"
                        placeholder="Chủ đề tiếp xúc"
                        ref={register({ required: true })}
                    />
                    {errors.title && errors.title.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Swat <span className='required'>*</span></label>
                    <Select
                        // isClearable
                        {...register("herdsmanId", { required: true })}
                        defaultValue={updateItem?.herdsmanId ? { label: updateItem?.herdsmanName, value: updateItem?.herdsmanId } : (currentLoggedInHerdsman ? currentLoggedInHerdsman : null)}
                        //value={currentLoggedInHerdsman ? { label: currentLoggedInHerdsman?.name, value: currentLoggedInHerdsman?.id } : null}
                        placeholder="Swat"
                        // closeMenuOnSelect={true}
                        onChange={(data) => setValue("herdsmanId", data ? data.value : null)}
                        options={updateItem?.herdsmanId ? optionsHerdsmanOwn : [...optionsHerdsmanOwn, currentLoggedInHerdsman]}
                        // noOptionsMessage={() => "Không tồn tại"}
                        //isDisabled
                    />
                    {errors.herdsmanId && errors.herdsmanId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            <div className="row">

                <div className="form-group col-md-6">
                    <label>Ta-lâng <span className='required'>*</span></label>
                    <Select
                        isClearable
                        {...register("customerId", { required: true })}
                        defaultValue={customerForCreateTask ? { label: customerForCreateTask?.fullName, value: customerForCreateTask?.id } :
                            updateItem?.customerId ? { label: updateItem?.customerName, value: updateItem?.customerId } : null}
                        placeholder="Chọn Ta-lâng"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("customerId", data ? data.value : null)}
                        options={customer.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                        isDisabled={customerForCreateTask || updateItem ? true : false}
                    />
                    {errors.customerId && errors.customerId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Địa điểm tiếp xúc <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="address"
                        placeholder="Nhập địa điểm"
                        ref={register({ required: true })}
                    />
                    {errors.address && errors.address.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Ngày <span className='required'>*</span></label>
                    <DatePicker
                        {...register("date", { required: true })}
                        onChange={(data) => setValue("date", dayjs(data).format())}
                        defaultValue={dayjs(updateItem?.datingTime ? updateItem?.datingTime : taskDate)}
                        className="form-control"
                        format='DD/MM/YYYY'
                        disablePast
                        slotProps={{ textField: { size: 'small' } }}
                    />
                    {errors.date && errors.date.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Giờ <span className='required'>*</span></label>
                    <TimePicker
                        {...register("time", { required: true })}
                        onChange={(data) => setValue("time", dayjs(data).format())}
                        defaultValue={dayjs(updateItem?.datingTime ? updateItem?.datingTime : taskDate)}
                        className="form-control"
                        // format='DD/MM/YYYY'
                        slotProps={{ textField: { size: 'small' } }}
                        label={"Nhập giờ"}
                    />
                    {errors.time && errors.time.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            
            <div className="row">
                <div className="form-group col-md-12">
                    <label>Ghi chú</label>
                    <textarea
                        className="form-control"
                        type="text"
                        name="description"
                        placeholder="Nhập nội dung ghi chú"
                        rows={4}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>ACE liên hiệp c/sẻ<span className="text-success">(tối đa 2 Ace)</span></label>
                    <Select
                        {...register("selectedHerdsman", {})}
                        isClearable
                        isMulti
                        value={selectedHerdsman}
                        placeholder="ACE liên hiệp c/sẻ"
                        onChange={(data) => {
                            if (Array.isArray(data) && data.length > 2) return;
                            setSelectedHerdsman(data);
                            setValue("selectedHerdsman", data);
                        }}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                        isDisabled={updateItem?.departmentHelpingId}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-4 d-flex justify-content-between align-content-center">
                    <label>Cần trợ giúp</label>
                    <div className="switch-button switch-button-yesno ml-5 switch-button-xs">
                        <input
                            type="checkbox"
                            checked={checkNeedHelp}
                            name="needHelp"
                            id="needHelp"
                            ref={register()}
                            onChange={() => setCheckNeedHelp(prev => !prev)}
                        />
                        <span><label htmlFor="needHelp"></label></span>
                    </div>
                </div>
                <div className="form-group col-md-8">
                    
                    <input
                        className="form-control"
                        type="text"
                        name="contentHelping"
                        placeholder="Nhập nội dung trợ giúp"
                        ref={register()}
                        readOnly={!checkNeedHelp }
                    />
                </div>
            </div>
           
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
              Lưu
            </button>
        </form>
    )
}