import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListFestivalCog = (pageIndex = 1, pageSize = 10, sortExpression = "", code = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    return service.get(ApiUrl.GetListFestivalCog, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailFestivalCog = (id) => {
    return service.get(ApiUrl.GetDetailFestivalCog.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const GetCurrentFestivalCog = () => {
    return service.get(ApiUrl.GetCurrentFestivalCog).then(res => { return res }).catch((err) => { throw err });
}
export const CreateFestivalCog = (data) => {
    return service.post(ApiUrl.CreateFestivalCog, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateFestivalCog = (data) => {
    return service.put(ApiUrl.UpdateFestivalCog, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteFestivalCog = (id) => {
    return service.delete(ApiUrl.DeleteFestivalCog.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentFestivalCog = (id) => {
    return service.delete(ApiUrl.DeletePermanentFestivalCog.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveFestivalCog = (id) => {
    return service.put(ApiUrl.ActiveFestivalCog.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveFestivalCog = (id) => {
    return service.put(ApiUrl.DeActiveFestivalCog.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const GetLookupFestivalCog = () => {
    return service.get(ApiUrl.GetLookupFestivalCog).then((res) => { return res }).catch((err) => { throw err });
}