import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import { useMediaQuery } from 'react-responsive';
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";

export default function RptCustomerStepDepartment() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

//    const currentYear = (new Date()).getFullYear();

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(0);
    const thang = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    const [department, setDepartment] = useState([]);
    const [customerStepReport, setCustomerStepReport] = useState([]);
    const [totalItem, setTotalItem] = useState();
    const year = new Date().getFullYear();
    useEffect(() => {
        getReportCustomer();
    }, [])

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem]);

    const getReportCustomer = async () => {
        showLoading(true);
        try {
            const res = await customerManagementAction.GetCustomerCountStepReport(year);

            if (res && res.content) {
                setDepartment(res.content.department);
                setCustomerStepReport(res.content.report);
                setTotalItem(10);
            }

        } catch (err) {
            ;
        } finally {
            showLoading(false);
        }
    };

    const onSubmit = (data) => {
        getReportCustomer(data);
        //if (isTabletOrMobile)
        //    setOpenSearch(false);
    }

    const renderFormSearch = () => {
        return <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

        </form>
    }

    const renderTableForDesktop = () => {
        return <>
            {renderFormSearch()}
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 mb-2 dataTables freeze-column" ref={scrollRoot}>
                            <table className="table table-bordered table-hover dataTable" ref={scrollTable} id="datatable">
                                <thead>
                                    <tr>
                                        <th className="freeze" rowSpan={2} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }}>Bước</th>
                                        {thang.map((item, index) => <th key={index}
                                            scope="col"
                                            colSpan={6}
                                            style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(0, 0, 0, .04)' }}
                                        >
                                            {item!==13 ? "Tháng " + item : "Năm " + year }
                                        </th>)}
                                    </tr>
                                    <tr>
                                        {thang.map((itemMonth) => <>
                                            {department && department.length > 0 && department.map((item, index) => <th key={index} scope="col" style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>{item.name}</th>)}
                                            <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(0, 0, 0, .04)' }}>
                                                
                                                {itemMonth !== 13 ? "Tổng tháng " + itemMonth : "Tổng năm " + year}
                                            </th>
                                        </>)}

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customerStepReport && customerStepReport.length > 0 && customerStepReport.map((itemCustomer, indexCus) => (
                                            <tr key={indexCus}>
                                                <td style={indexCus === customerStepReport.length - 1 ? { fontWeight: 'bold', textAlign: 'left', verticalAlign: 'middle' } : {}}><span>{itemCustomer.customerStatus}</span></td>
                                                {thang.map((itemMonth) => <>
                                                    {
                                                        department && department.length > 0 && department.map((item, index) => (
                                                            <td key={index} scope="col" style={indexCus === customerStepReport.length - 1 ? { fontWeight: 'bold', textAlign: 'right', verticalAlign: 'middle' } : { textAlign: 'right', verticalAlign: 'middle' }}><span>
                                                                {itemCustomer["thang" + itemMonth + "_" + item.id] !== 0 ? itemCustomer["thang" + itemMonth + "_" + item.id] : ""}
                                                            </span></td>
                                                        ))
                                                    }
                                                    <td style={{ fontWeight: 'bold', textAlign: 'right', verticalAlign: 'middle', backgroundColor:'rgba(0, 0, 0, .04)' }}>{itemCustomer["thang" + itemMonth + "_Sum"] !== 0 ? itemCustomer["thang" + itemMonth + "_Sum"] : ""}</td>
                                                </>)}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        scrollTableWidth ?
                            <div className='scrollRoot'
                                onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                            >
                                <div style={{ width: `${scrollTableWidth}px` }}>
                                    &nbsp;
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderTableForDesktop() : <></>
                }
            </div>
        </div>
    )
}