import React, { useEffect, useState } from 'react';
import * as examinationAction from "../../../redux/store-sarang/examination-report/examination-report-management.store";
import { NotificationMessageType, getFestivalCog } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
export default function ListHerdsmanRankWidget(props) {
    const { listHerdsmanRank, loading, userInfo, week } = props;

    const [loadingSwat, setLoadingSwat] = useState(loading);
    const [showIndexHerdsman, setShowIndexHerdsman] = useState(-1);
    const [herdsmanData, setHerdsmanData] = useState();
    const currentFestival = getFestivalCog();

    useEffect(() => {

    }, []);

    const GetHerdsmanExaminationReport = async (herdsmanId) => {
        setLoadingSwat(true);
        try {
            const res = await examinationAction.GetHerdsmanExaminationReport(herdsmanId, week?.festivalCOGId, week?.week);

            if (res && res.content) {
                setHerdsmanData(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoadingSwat(false);
        }
    };

    return (
        <>
            {
                listHerdsmanRank && listHerdsmanRank.length > 0 &&
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card">
                            <h5 className="card-header">{week == null ? "Điểm tích lũy 3 tháng các thành viên trong đội" : `Xếp hạng thành viên (tuần ${week?.week}` })</h5>
                        <div className="row card-body text-center">
                            <table className="table dataTable table-hover " id="datatable">
                                <thead>
                                    <tr>
                                        <td style={{ minWidth: "30px" }}><b>Hạng</b></td>
                                            <td style={{ minWidth: "100px" }}><b>Swat</b></td>
                                            <td style={{ minWidth: "100px" }}><b>Đội</b></td>
                                        <td style={{ minWidth: "30px" }}><b>Điểm</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listHerdsmanRank.map((item, index) => index<20 && item.totalPoint!==0 &&
                                            <>
                                            <tr style={{ cursor: "pointer" }} onClick={() => {
                                                if (week == null) return;
                                                    if (index == showIndexHerdsman) 
                                                        setShowIndexHerdsman(-1);
                                                    else
                                                        setShowIndexHerdsman(index);
                                                    setHerdsmanData();
                                                    GetHerdsmanExaminationReport(item.herdsmanId);
                                            }}>
                                                    <td>{item.rank}</td>
                                                    <td>{item.herdsmanName}</td>
                                                    <td>{item.departmentName}</td>
                                                    <td>{Math.round((item.totalPoint) * 100) / 100}</td>
                                                    
                                                </tr>
                                                {
                                                    herdsmanData?.rP_Examination_Report_Dtos && herdsmanData?.rP_Examination_Report_Dtos.length > 0 &&
                                                    <tr style={{ display: showIndexHerdsman == index ? "contents" : "none" }}>
                                                        <td colSpan={3}>
                                                            <table className="table dataTable">
                                                                <thead>
                                                                    <tr >
                                                                        <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                                                        <td style={{ minWidth: "50px" }}><b>#</b></td>
                                                                        <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        herdsmanData?.rP_Examination_Report_Dtos.map((itemherdsman, index) =>
                                                                            <tr>
                                                                                <td>{optionsExamination.find(x => x.value == itemherdsman.status)?.label}</td>
                                                                                <td>{itemherdsman.quantity}</td>
                                                                                {/*<td>{Math.round((itemherdsman.quantity * itemherdsman.point) * 100) / 100}</td>*/}
                                                                                <td>{Math.round((itemherdsman.point) * 100) / 100}</td>
                                                                            </tr>)
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                }

                                            </>)
                                    }
                                </tbody>
                            </table>
                        </div>

                        <LoadingViewAria loading={loadingSwat} />
                    </div>
                </div>
            }
        </>
    )
}