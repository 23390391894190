import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


import * as customerManagementAction from "../../../../redux/store-sarang/customer/customer-management.store";
import headCellsSheepAnuallyReport from './head-cell-sheep-anually_report';
import DataTableHeader from "../../../../components/datatable-sarang/datatable-content";
import { optionsCustomerStatus } from '../../../../common/constant';
import LoadingViewAria from '../../../../components/layout-sarang/loading-view-aria'

const SheepAnuallyReport = () => {
    const [data, setData] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const getAnuallyReport = async () => {
        setLoading(true);
        try {
            const res = await customerManagementAction.GetAnuallyReport();

            if (res && res.content) {
                setData(res.content);
                setSeriesData(res.content.map((row, index) => {
                    return {
                        name: optionsCustomerStatus.find(x => x.value == row.customerStatus)?.label,
                        data: [row.thang1, row.thang2, row.thang3, row.thang4, row.thang5, row.thang6, row.thang7, row.thang8, row.thang9, row.thang10, row.thang11, row.thang12]
                    }
                })
                );
            }
        } catch (err) {
            if (err && err.errorType) {

            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnuallyReport();
    }, []);

    useEffect(() => {
        console.log(seriesData);
    }, [seriesData]);

    const options = {
        chart: {
            type: 'line'
        },
        title: {
            text: 'Tổng hợp số lượng ta-lâng theo tháng',
            
        },
        xAxis: {
            categories: headCellsSheepAnuallyReport.slice(1).map((item, index) => {
                if (index<headCellsSheepAnuallyReport.length-1)
                    return item.label;

            }),
            crosshair: true,
            accessibility: {
                description: 'Tháng'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Số lượng ta-lâng'
            }
        },
        tooltip: {
            //valueSuffix: ' (1000 MT)'
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            shared: true
        },
        plotOptions: {
            line: {
                //stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            },
            column: {
                pointPadding: 0.1,
                borderWidth: 0,
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: seriesData
    }
    return <div className="row">
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <div className="card">
                <div className="card-body">
                    <DataTableHeader
                        headCells={headCellsSheepAnuallyReport}
                    //handleRequestSort={handleRequestSort}
                    //order={order}
                    //orderBy={orderBy}
                    //listData={listData}
                    totalItemCount={13}
                    >
                        <tbody>
                            {
                                data.length > 0 ? 
                                    data.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td>{optionsCustomerStatus.find(x=>x.value== row.customerStatus)?.label}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang1!=0 ? row.thang1 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang2 != 0 ? row.thang2 : ""}</td>
                                            <td style={{ textAlign: 'right' } }>{row.thang3 != 0 ? row.thang3 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang4 != 0 ? row.thang4 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang5 != 0 ? row.thang5 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang6 != 0 ? row.thang6 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang7 != 0 ? row.thang7 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang8 != 0 ? row.thang8 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang9 != 0 ? row.thang9 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang10 != 0 ? row.thang10 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang11 != 0 ? row.thang11 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang12 != 0 ? row.thang12 : ""}</td>
                                            <td style={{ textAlign: 'right' }}>{row.thang1 + row.thang2 + row.thang3 + row.thang4 + row.thang5 + row.thang6 + row.thang7 + row.thang8 + row.thang9 + row.thang10 + row.thang11+row.thang12}</td>
                                        </tr>
                                    )) : <></>
                            }
                        </tbody>
                    </DataTableHeader>
                </div>
                <LoadingViewAria loading={loading} />
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
            <div className="card">
                <div className="card-body">
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
                <LoadingViewAria loading={loading} />
            </div>
        </div>
    </div>


};

export default SheepAnuallyReport;
