import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListSwatActivityReport = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData = undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && sortExpression!="" && params.append("sorting", sortExpression);
    searchData?.herdsmanId && params.append("herdsmanId", searchData?.herdsmanId);
    searchData?.departmentId && params.append("departmentId", searchData?.departmentId);
    searchData?.jobId && params.append("jobId", searchData?.jobId);
    searchData?.reportFromDate && params.append("reportFromDate", searchData?.reportFromDate);
    searchData?.reportToDate && params.append("reportToDate", searchData?.reportToDate);
    return service.get(ApiUrl.GetListSwatActivityReport, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailSwatActivityReport = (id) => {
    return service.get(ApiUrl.GetDetailSwatActivityReport.replace("{id}",id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateSwatActivityReport = (data) => {
    return service.post(ApiUrl.CreateSwatActivityReport, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateSwatActivityReport = (data) => {
    return service.put(ApiUrl.UpdateSwatActivityReport, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteSwatActivityReport = (id) => {
    return service.delete(ApiUrl.DeleteSwatActivityReport.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentSwatActivityReport = (id) => {
    return service.postParams(ApiUrl.DeletePermanentSwatActivityReport.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveSwatActivityReport = (id) => {
    return service.put(ApiUrl.ActiveSwatActivityReport.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveSwatActivityReport = (id) => {
    return service.put(ApiUrl.DeActiveSwatActivityReport.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ChangeConfirm = (body) => {
    return service.put(ApiUrl.ChangeConfirmSwatActivityReport, body).then((res) => { return res }).catch(err => { throw err });
}
export const GetLookupJob = () => {
    return service.get(ApiUrl.GetLookupJob).then((res) => { return res }).catch((err) => { throw err });
}

export const GetSumaryIntroduce = (month, year) => {
    const params = new URLSearchParams();
    params.append("month", month);
    params.append("year", year);
    return service.get(ApiUrl.GetSumaryIntroduce, params).then((res) => { return res }).catch((err) => { throw err });
}