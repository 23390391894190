import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListFeedback = (pageIndex = 1, pageSize = 10, sortExpression = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    return service.get(ApiUrl.GetListFeedback, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailFeedback = (id) => {
    return service.get(ApiUrl.GetDetailFeedback.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateFeedback = (data) => {
    return service.post(ApiUrl.CreateFeedback, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateFeedback = (data) => {
    return service.put(ApiUrl.UpdateFeedback, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteFeedback = (id) => {
    return service.delete(ApiUrl.DeleteFeedback.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentFeedback = (id) => {
    return service.delete(ApiUrl.DeletePermanentFeedback.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveFeedback = (id) => {
    return service.put(ApiUrl.ActiveFeedback.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveFeedback = (id) => {
    return service.put(ApiUrl.DeActiveFeedback.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const SendMessageToHerdsman = (data) => {
    return service.post(ApiUrl.SendMessageToHerdsman, data).then((res) => { return res }).catch(err => { throw err });
}