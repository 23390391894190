import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from "react-select";

export default function FormAssignHerdsman(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        herdsman, handleSubmitAssignHerdsman,} = props;
    const buttonSubmitRef = useRef(null);
    
    useEffect(() => {
        setValue("herdsmanId1", updateItem?.herdsmanHelpingId1);
    }, [])

    const { register, handleSubmit, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }

        const dataInput = {
            ...data, CustomerId: updateItem?.id
        }
        handleSubmitAssignHerdsman(dataInput);
        onCloseModal();
    }

    useEffect(() => {
        
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Swat <span className='required'>*</span></label>
                    <Select
                         isClearable
                        {...register("herdsmanId1", {  })}
                        defaultValue={updateItem?.herdsmanHelpingId1 ? { label: updateItem?.herdsmanHelpingName1, value: updateItem?.herdsmanHelpingId1 } : null}
                        placeholder="Swat"
                        onChange={(data) => setValue("herdsmanId1", data ? data.value : null)}
                        options={herdsman}
                    />
                </div>
            </div>
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
              Lưu
            </button>
        </form>
    )
}