import React, { useEffect, useState } from 'react';
import * as customerManagementAction from "../../../../redux/store-sarang/customer/customer-management.store";
import { NotificationMessageType } from "../../../../utils/configuration";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import * as viVN from "../../../../language/vi-VN.json";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LoadingViewAria from '../../../../components/layout-sarang/loading-view-aria'
export default function SheepNumberWidget() {
    const [data, setData] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const buoc1 = "Bước 1: làm ấm mối quan hệ";
    const buoc2 = "Bước 2: Quảng bá hội thánh";
    const buoc3 = "Bước 3: Học lẽ thật";
    const buoc4 = "Bước 4: KVT/ĐVT phỏng vấn hoặc đến thăm Si-ôn";
    const buoc5 = "Bước 5: Báp-têm";
    const buoc6 = "Bước 6: giữ tp>=4 lần/tháng. Sang đầu tháng sẽ reset về Bước 5";
    const buoc7 = "Bước 7: Giữ tp>=4 lần/tháng và dâng mên chi";
    const buoc8 = "Bước 8: Trở thành SWAT";
    const [message, setMesssage] = React.useState(buoc1);
    const [loading, setLoading]=useState(false);
    const open = Boolean(anchorEl);

    useEffect(() => {
        getCustomerCount();
    }, []);

    const getCustomerCount = async () => {
        setLoading(true);
        try {
            const res = await customerManagementAction.GetSumarize();

            if (res && res.content) {
                setData(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const buoc1Click = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {
                <Popover
                id='buoc1'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                    <Typography sx={{ p: 2 }}>{message}</Typography>
            </Popover>
            }
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="card">

                    <h5 className="card-header">Thống kê ta-lâng (7 ngày)</h5>
                    <div className="row card-body">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-info">
                                <div className="card-body" title={buoc1}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted"><span style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc1);
                                            buoc1Click(event);
                                        }}>Bước 1&nbsp;<li className="fas fa-question-circle"></li></span></h5>
                                        <h2 className="mb-0"> {data?.buoc1}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md  bg-info-light mt-1">
                                        <i className="fas fa-hands-helping fa-fw fa-sm text-info"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-top-primary">
                                <div className="card-body" title={buoc2}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc2);
                                            buoc1Click(event);
                                        }}>Bước 2&nbsp;<li className="fas fa-question-circle"></li></h5>
                                        <h2 className="mb-0"> {data?.buoc2}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md bg-primary-light mt-1">
                                        <i className="fas fa-laptop fa-fw fa-sm text-primary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-secondary">
                                <div className="card-body" title={buoc3}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc3);
                                            buoc1Click(event);
                                        }}>Bước 3&nbsp;<li className="fas fa-question-circle"></li></h5>
                                        <h2 className="mb-0">{data?.buoc3}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md  bg-secondary-light mt-1">
                                        <i className="fas fa-child fa-fw fa-sm text-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-brand">
                                <div className="card-body" title={buoc4}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc4);
                                            buoc1Click(event);
                                        }}>Bước 4&nbsp;<li className="fas fa-question-circle"></li></h5>
                                        <h2 className="mb-0"> {data?.buoc4}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md bg-brand-light mt-1">
                                        <i className="fas fa-utensil-spoon fa-fw fa-sm text-brand"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <LoadingViewAria loading={loading} />
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="card">
                    <h5 className="card-header">Thống kê ta-lâng (tháng)</h5>
                    <div className="row card-body">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-info">
                                <div className="card-body" title={buoc5}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc5);
                                            buoc1Click(event);
                                        }}>Bước 5&nbsp;<li className="fas fa-question-circle"></li></h5>
                                        <h2 className="mb-0"> {data?.buoc5}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md  bg-info-light mt-1">
                                        <i className=" fas fa-heartbeat fa-fw fa-sm text-info"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-secondary">
                                <div className="card-body" title={buoc6}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc6);
                                            buoc1Click(event);
                                        }}>Bước 6&nbsp;<li className="fas fa-question-circle"></li></h5>
                                        <h2 className="mb-0"> {data?.buoc6}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md  bg-secondary-light mt-1">
                                        <i className="fas fa-hand-holding-usd fa-fw fa-sm text-secondary"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-top-primary">
                                <div className="card-body" title={buoc7}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc7);
                                            buoc1Click(event);
                                        }}>Bước 7&nbsp;<li className="fas fa-question-circle"></li></h5>
                                        <h2 className="mb-0">{data?.buoc7}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md bg-primary-light mt-1">
                                        <i className="fas fa-check-circle fa-fw fa-sm text-primary"></i>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="card border-3 border-top border-brand">
                                <div className="card-body" title={buoc8}>
                                    <div className="d-inline-block">
                                        <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                            setMesssage(buoc8);
                                            buoc1Click(event);
                                        }}>Bước 8&nbsp;<li className="fas fa-question-circle"></li></h5>
                                        <h2 className="mb-0"> {data?.buoc8}</h2>
                                    </div>
                                    <div className="float-right icon-circle-small  icon-box-md bg-brand-light mt-1">
                                        <i className="fas fa-user-secret fa-fw fa-sm text-brand"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoadingViewAria loading={loading} />
                </div>
            </div>
            {/*
            <div className="card">
                
                <h5 className="card-header">Thống kê ta-lâng (7 ngày)</h5>
                <div className="row card-body">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-info">
                            <div className="card-body" title={buoc1 }>
                                <div className="d-inline-block">
                                    <h5 className="text-muted"><span style={{ cursor: 'Pointer' }} onClick={(event) => {
                                        setMesssage(buoc1);
                                        buoc1Click(event);
                                    }}>Bước 1&nbsp;<li className="fas fa-question-circle"></li></span></h5>
                                    <h2 className="mb-0"> { data?.buoc1}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md  bg-info-light mt-1">
                                    <i className="fas fa-hands-helping fa-fw fa-sm text-info"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-top-primary">
                            <div className="card-body" title={buoc2 }>
                                <div className="d-inline-block">
                                    <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                        setMesssage(buoc2);
                                        buoc1Click(event);
                                    }}>Bước 2&nbsp;<li className="fas fa-question-circle"></li></h5>
                                    <h2 className="mb-0"> {data?.buoc2}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md bg-primary-light mt-1">
                                    <i className="fas fa-laptop fa-fw fa-sm text-primary"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-secondary">
                            <div className="card-body" title={buoc3}>
                                <div className="d-inline-block">
                                    <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                        setMesssage(buoc3);
                                        buoc1Click(event);
                                    }}>Bước 3&nbsp;<li className="fas fa-question-circle"></li></h5>
                                    <h2 className="mb-0">{data?.buoc3}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md  bg-secondary-light mt-1">
                                    <i className="fas fa-child fa-fw fa-sm text-secondary"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-brand">
                            <div className="card-body" title={buoc4}>
                                <div className="d-inline-block">
                                    <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                        setMesssage(buoc4);
                                        buoc1Click(event);
                                    }}>Bước 4&nbsp;<li className="fas fa-question-circle"></li></h5>
                                    <h2 className="mb-0"> {data?.buoc4}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md bg-brand-light mt-1">
                                    <i className="fas fa-utensil-spoon fa-fw fa-sm text-brand"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-info">
                            <div className="card-body" title={buoc5}>
                                <div className="d-inline-block">
                                    <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                        setMesssage(buoc5);
                                        buoc1Click(event);
                                    }}>Bước 5&nbsp;<li className="fas fa-question-circle"></li></h5>
                                    <h2 className="mb-0"> {data?.buoc5}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md  bg-info-light mt-1">
                                    <i className=" fas fa-heartbeat fa-fw fa-sm text-info"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-secondary">
                            <div className="card-body" title={buoc6}>
                                <div className="d-inline-block">
                                    <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                        setMesssage(buoc6);
                                        buoc1Click(event);
                                    }}>Bước 6&nbsp;<li className="fas fa-question-circle"></li></h5>
                                    <h2 className="mb-0"> {data?.buoc6}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md  bg-secondary-light mt-1">
                                    <i className="fas fa-hand-holding-usd fa-fw fa-sm text-secondary"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-top-primary">
                            <div className="card-body" title={buoc7}>
                                <div className="d-inline-block">
                                    <h5 className="text-muted" style={{ cursor: 'Pointer' }} onClick={(event) => {
                                        setMesssage(buoc7);
                                        buoc1Click(event);
                                    }}>Bước 7&nbsp;<li className="fas fa-question-circle"></li></h5>
                                    <h2 className="mb-0">{data?.buoc7}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md bg-primary-light mt-1">
                                    <i className="fas fa-check-circle fa-fw fa-sm text-primary"></i>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="card border-3 border-top border-brand">
                            <div className="card-body" title={buoc8}>
                                <div className="d-inline-block">
                                    <h5 className="text-muted" style={{cursor: 'Pointer'}} onClick={(event) => {
                                        setMesssage(buoc8);
                                        buoc1Click(event);
                                    }}>Bước 8&nbsp;<li className="fas fa-question-circle"></li></h5>
                                    <h2 className="mb-0"> {data?.buoc8}</h2>
                                </div>
                                <div className="float-right icon-circle-small  icon-box-md bg-brand-light mt-1">
                                    <i className="fas fa-user-secret fa-fw fa-sm text-brand"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <LoadingViewAria loading={ loading} />
            </div>

            
                */ }
        </>   
    )
}