import React, { useEffect, useState } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import dayjs from 'dayjs';

import * as transportOrderManagementAction from "../../../../redux/store-sarang/transport-order/transport-order-management.store";
import LoadingViewAria from '../../../../components/layout-sarang/loading-view-aria'

export default function TransportationChart(props) {
    const { listMonth } = props;
    //const data = [...fakeDataCookingReg];
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());

    const getSumaryByDateCuisineOrder = async (getYear = undefined, getMonth = undefined) => {
        getYear = getYear ? getYear : year;
        getMonth = getMonth ? getMonth : month;

        let fromDate = new Date(getYear + "-" + getMonth + "-1");
        let toDate = new Date(getYear + "-" + getMonth + "-1");
        toDate = new Date(new Date(toDate.setMonth(getMonth)).setDate(0));  //tháng sau
        setLoading(true);
        try {
            const res = await transportOrderManagementAction.SumaryByDateTransportOrder(dayjs(fromDate).format(), dayjs(toDate).format());

            if (res && res.content) {
                setOptions(
                    {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: 'Tổng hợp giao vận',
                        },
                        xAxis: {
                            categories: res.content.map((entry) => dayjs(entry.orderDate).format("DD/MM/YYYY")),
                        },
                        yAxis: {
                            title: {
                                text: 'Số suất ăn',
                            },
                            stackLabels: {
                                enabled: true
                            }
                        },
                        plotOptions: {
                            column: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        },
                        series: [{
                            name: 'Suất ăn buổi trưa',
                            data: res.content.map((entry) => entry.numberMealSetLunch)
                        }, {
                            name: 'Suất ăn buổi chiều',
                            data: res.content.map((entry) => entry.numberMealSetDinner)
                        }]
                    }
                );
            }

        } catch (err) {
            if (err && err.errorType) {
                //ShowNotification(
                //    viVN.Errors[err.errorType],
                //    NotificationMessageType.Error
                //);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSumaryByDateCuisineOrder();
    }, []);

    const onChangeMonth = (event = undefined) => {
        let eventMonth = month;
        let eventYear = year;
        if (event?.target?.name === "month") {
            eventMonth = event.target.value;
            setMonth(event.target.value);
        }
        else if (event?.target?.name === "year") {
            eventYear = event.target.value;
            setYear(event.target.value);
        }
        getSumaryByDateCuisineOrder(eventYear, eventMonth);
    }

    return options ?
        <div className="card">
            <div className="card-header d-flex">
                <div className="row">
                    <div className="col-7" style={{ paddingRight: "0px" }}>
                        <select className="form-control form-control-sm" name="month" placeholder="Tháng" style={{ width: "100px" }} onChange={(e) => onChangeMonth(e)} defaultValue={month}>
                            {
                                listMonth && listMonth.length > 0 && listMonth.map((item) => <option value={item}>Tháng {item}</option>)
                            }
                        </select>
                    </div>
                    <div className="col-5" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                        <select className="form-control form-control-sm" name="year" placeholder="Năm" onChange={(e) => onChangeMonth(e)}>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                        </select>
                    </div>
                </div>
                <div className="dropdown ml-auto">
                    <a className="toolbar" href="#" onClick={(e) => {
                        e.preventDefault();
                        onChangeMonth();
                    }} role="button" aria-haspopup="true" aria-expanded="false"><i title="Làm mới" className=" fas fa-history"></i> </a>
                </div>
            </div>
            <div className="card-body">
                <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
            <LoadingViewAria loading={loading} />
        </div>
        : <></>;
}