import { UrlCollectionSarang } from "./common/url-collection-sarang";

const BreadcrumbConfig = {
    DashboardHome : {
        breadcrumbList : null,
        breadcrumbActive: null
    },
    EximDashboard: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    HerdsmanList : {
        breadcrumbList : [
            {
              title: "SWAT",
              link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Danh sách swat"
        }
    },
    HerdsmanListPointThreeMonth: {
        breadcrumbList: [
            {
                title: "ĐIỂM SWAT 3 THÁNG",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Điểm swat 3 tháng"
        }
    },
    CustomerList : {
        breadcrumbList : [
            {
                title: "SWAT",
              link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Danh sách swat"
        }
    },
    CustomerNeedHelpList: {
        breadcrumbList: [
            {
                title: "SWAT",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Ta-lâng cần bàn giao"
        }
    },
    CalendarContact : {
        breadcrumbList : [
            {
                title: "SWAT",
              link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Lịch hẹn chia sẻ"
        }
    },
    CalendarNeedHelp: {
        breadcrumbList: [
            {
                title: "SWAT",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Ca chia sẻ cần trợ giúp"
        }
    },
    SwatReportList : {
        breadcrumbList : [
            {
                title: "SWAT",
              link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Swat Báo cáo hoạt động"
        }
    },
    SwatTrundoInput: {
        breadcrumbList: [
            {
                title: "SWAT",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Báo cáo hoạt động trundo"
        }
    },
    SwatTrundoSumaryHerdsmanReport: {
        breadcrumbList: [
            {
                title: "SWAT",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Tổng hợp hoạt động trundo theo swat"
        }
    },
    SwatTrundoSumaryDepartmentReport: {
        breadcrumbList: [
            {
                title: "SWAT",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Tổng hợp hoạt động trundo theo biệt đội"
        }
    },
    SwatTrundoSumaryZionReport: {
        breadcrumbList: [
            {
                title: "SWAT",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Tổng hợp hoạt động trundo theo địa vực"
        }
    },
    SwatTrundoSumaryByDateReport: {
        breadcrumbList: [
            {
                title: "SWAT",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Chi tiết hoạt động trundo theo swat"
        }
    },
    CuisineOrder : {
        breadcrumbList : [
            //{
            //  title: "Báo cáo hoạt động",
            //  link: "#"
            //},
            {
                title: "Ẩm thực",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Danh sách đăng ký"
        }
    },
    ReportActivityShipping : {
        breadcrumbList : [
            //{
            //  title: "Báo cáo hoạt động",
            //  link: "#"
            //},
            {
                title: "Giao vận",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Theo dõi Đơn hàng"
        }
    },
    SendCuisineToShipper: {
        breadcrumbList: [
            //{
            //  title: "Báo cáo hoạt động",
            //  link: "#"
            //},
            {
                title: "Giao shipper",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Giao shipper"
        }
    },
    DashboardCooking : {
        breadcrumbList : [
            //{
            //  title: "Báo cáo hoạt động",
            //  link: "#"
            //},
            {
                title: "Ẩm thực",
                link: ""
            }
        ],
        breadcrumbActive: {
            title: "Báo cáo tổng hợp Ẩm thực"
        }
    },
    DashboardShipping : {
        breadcrumbList : [
            //{
            //  title: "Báo cáo hoạt động",
            //  link: "#"
            //},
            {
                title: "Giao vận",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Báo cáo hoạt động Đơn hàng"
        }
    },
    Department: {
        breadcrumbList: [
            {
                title: "Danh mục",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Biệt đội"
        }
    },
    DepartmentListConfirmRank: {
        breadcrumbList: [
            {
                title: "Danh mục",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Duyệt thăng hạng"
        }
    },
    Organization: {
        breadcrumbList: [
            {
                title: "Danh mục",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Hội thánh/chi hội"
        }
    },
    Position: {
        breadcrumbList: [
            {
                title: "Danh mục",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Hội thánh/chi hội"
        }
    },
    Helping: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    Group: {
        breadcrumbList: [
            {
                title: "Danh mục",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Bộ"
        }
    },
    Zion: {
        breadcrumbList: [
            {
                title: "Danh mục",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Địa vực"
        }
    },
    Feedback: {
        breadcrumbList: [
            {
                title: "Danh mục",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Sự cố"
        }
    },
    Profile: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    RptCustomerStepDepartment: {
        breadcrumbList: [
            {
                title: "Báo cáo",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Báo cáo ta-lâng theo từng bước"
        }
    },
    MyReportAcademy: {
        breadcrumbList: [
            {
                title: "Phát biểu lễ hội lời",
                link: "#"
            }
        ],
        breadcrumbActive: {
            title: "Phát biểu lễ hội lời"
        }
    },
    ExamHerdsmanReport: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    ExamDepartmentReport: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    ExamDeliveryReport: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    ExamSumaryDepartmentReport: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    ExamAcademyDashboard: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
    ReportSumaryAcademy: {
        breadcrumbList: null,
        breadcrumbActive: null
    },
}

export default BreadcrumbConfig;