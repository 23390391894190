
const headCellsListHerdsmanThreeMonth = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: false,
        label: 'Họ và tên',
        className: 'pt-3 pb-3',
        style: { minWidth: "130px" }
    },
    {
        id: 'herdsmanType',
        numeric: false,
        disablePadding: false,
        label: '',
        className: 'pt-3 pb-3',
        style: { minWidth: "40px" }
    },
    {
        id: 'DepartmentName',
        numeric: false,
        disablePadding: false,
        label: 'Biệt đội',
        className: 'pt-3 pb-3',
        style: { minWidth: "100px" }
    },
    {
        id: 'PointPrevThreeMonth',
        numeric: false,
        disablePadding: false,
        label: `Điểm 3 tháng ${new Date().getMonth() - 2}, ${new Date().getMonth() - 1}, ${new Date().getMonth()}`,
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'CustomerPrevThreeMonth',
        numeric: false,
        disablePadding: false,
        label: `SL Chiên 3 tháng ${new Date().getMonth() - 2}, ${new Date().getMonth() - 1}, ${new Date().getMonth()}`,
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'PointNowThreeMonth',
        numeric: false,
        disablePadding: false,
        label: `Điểm 3 tháng ${new Date().getMonth() - 1}, ${new Date().getMonth()}, ${new Date().getMonth()+1}`,
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'CustomerNowThreeMonth',
        numeric: false,
        disablePadding: false,
        label: `SL Chiên 3 tháng ${new Date().getMonth() - 1}, ${new Date().getMonth()}, ${new Date().getMonth() + 1}`,
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    }
];

export default headCellsListHerdsmanThreeMonth;