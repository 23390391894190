import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import * as appActions from "../../../core/app.store";

import { headCellsHerdsmanPoint, headCellsHerdsmanPointDaily } from '../head-cell-list';

import * as viVN from "../../../language/vi-VN.json";
import { NotificationMessageType} from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import dayjs from 'dayjs';

import * as reportAcademyAction from "../../../redux/store-sarang/report-academy/report-academy-management.store";

export default function ReportHerdsmanSumaryAcademy(props) {
    const { week, weekNumber, zionId } = props;
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    //const userInfo = getUserInfo();
    // -- Data to map with ids
    const [sumaryPoint, setSumaryPoint] = useState([]);
    const [dailyPoint, setDailyPoint] = useState([]);
    const [showIndexHerdsman, setShowIndexHerdsman] = useState(-1);
    //const [selectedHerdsman, setSelectedHerdsman] = useState(null);
    
    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(0);
    const [totalItem, setTotalItem] = useState();


    useEffect(() => {
        GetAllListHerdsmanRank(weekNumber);
    }, [weekNumber, zionId]);

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem]);


    const GetAllListHerdsmanRank = async (weekNumber) => {
        if (!week) return;
        
        showLoading(true);
        try {
            const res = await reportAcademyAction.GetListHerdsmanRank(weekNumber==10 ? undefined : weekNumber ? weekNumber : week?.week, weekNumber && weekNumber < 5 ? 6 : weekNumber && weekNumber == 5 ? 7 : week?.month, week?.year, zionId, undefined, 1, 1000);

            if (res && res.content && res.content.items) {
                setSumaryPoint(res.content.items);
                setTotalItem(res.content.totalItemCount);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const GetListReportAcademy = async (herdsmanId = undefined, week = undefined, fromDate = undefined, toDate = undefined) => {
        showLoading(true);
        try {
            const res = await reportAcademyAction.GetListReportAcademy(herdsmanId, week, fromDate, toDate);

            if (res && res.content && res.content) {
                setDailyPoint(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const renderRow = (item, index) => {
        return <>
            <tr style={{ cursor: "pointer" }} key={index} onClick={() => {
                setDailyPoint([]);
                if (index == showIndexHerdsman)
                    setShowIndexHerdsman(-1);
                else {
                    setShowIndexHerdsman(index);
                    GetListReportAcademy(item.herdsmanId, item.week);
                    //setSelectedHerdsman(item.herdsmanName);
                }

            }}>
                {
                    headCellsHerdsmanPoint.map((itemPoint, indexPoint) => <>
                        {
                            itemPoint.id === 'rank' ? <td key={indexPoint}>
                                {
                                    item.rank ? item.rank : index+1
                                }
                            </td> :
                            itemPoint.id === 'action' ? <td key={indexPoint}></td> :
                                itemPoint.id === 'basicPoint' ? <td key={indexPoint}>
                                    <label className="custom-control custom-checkbox custom-control-inline">

                                        {
                                            item.basicPoint ? item.basicPoint : (item.completeBasicPoint ? <><input
                                                type="checkbox"
                                                defaultChecked={item.completeBasicPoint}
                                                name={`chk${item.herdsmanId}`}
                                                id={`chk${item.herdsmanId}`}
                                                disabled
                                                className="custom-control-input"
                                                /><span className="custom-control-label"></span></> : <></>)
                                        }
                                        
                                    </label>
                                </td> :
                                    <td key={indexPoint}>{item[itemPoint.id]}</td>
                        }
                    </>
                    )
                }
            </tr>
            {
                dailyPoint && dailyPoint.length > 0 &&
                <tr key={ index*100} style={{ display: showIndexHerdsman == index ? "contents" : "none" }}>
                    <td colSpan={7}>
                        <table className="table dataTable table-hover " id="datatable1">
                            <thead>
                                <tr>
                                    {
                                        headCellsHerdsmanPointDaily.map((item) =>
                                            <td key={item.id}
                                                style={item.style ? { ...item.style, fontWeight: 'bold', textAlign: 'center' } : { fontWeight: 'bold', textAlign: 'center' }}
                                            ><b>{item.label}</b></td>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dailyPoint.map((itemDailyPoint, index) =>  //filter(x => x.basicPoint === null || x.basicPoint === undefined || x.basicPoint === 0)
                                        <tr>
                                            {
                                                headCellsHerdsmanPointDaily.map((itemPoint, indexPoint) =>
                                                    <>
                                                        {
                                                            itemPoint.id === 'action' ? <td>
                                                            </td> :
                                                                itemPoint.id === 'totalPoint' ? <td>
                                                                    {itemDailyPoint["nmSumQuantity"] * 5 + itemDailyPoint["omSumQuantity"]}
                                                                </td> :
                                                                    itemPoint.id === 'reportDate' ? <td key={indexPoint}>{dayjs(itemDailyPoint.reportDate).format("DD/MM/YYYY")}</td> :
                                                                        <td key={indexPoint}>{itemDailyPoint[itemPoint.id]}</td>
                                                        }
                                                    </>
                                                )
                                            }
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            }
        </>
    }

    const renderTableForDesktop = () => {
        return <>
            <div className="card">
                <div className="row text-center">
                    <div className="col-12 mb-2 dataTables" ref={scrollRoot}>
                        <table className="table dataTable table-hover " id="datatable" ref={scrollTable} >
                            <thead>
                                <tr>
                                    {
                                        headCellsHerdsmanPoint.map((item) =>
                                            <td key={item.id}
                                                style={item.style ? { ...item.style, fontWeight: 'bold', textAlign: 'center' } : { fontWeight: 'bold', textAlign: 'center' }}
                                            ><b>{item.label}</b></td>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.map((item, index) => <>
                                        {
                                            renderRow(item, index)
                                        }
                                    </>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
                {
                    scrollTableWidth &&
                        <div className='scrollRoot'
                            onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                        >
                            <div style={{ width: `${scrollTableWidth}px` }}>
                                &nbsp;
                            </div>
                        </div>
                }
            </div>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    renderTableForDesktop()
                }
            </div>
        </div>
    )
}