import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import { patternNumber } from '../../../common/validatePhoneNumber';
import { optionsCustomerStatus } from '../../../common/constant';
import DepartmentHelper from '../../list-department/helper/department-helper';
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';
import { getUserInfo } from '../../../utils/configuration';
import Divider from '@mui/material/Divider';

export default function FormViewCustomer(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        herdsman,onSubmitAddEdit, customerDiary } = props;
    const buttonSubmitRef = useRef(null);

    const [checkActive, setCheckActive] = useState(true);
    const [checkNeedHelp, setCheckNeedHelp] = useState(false);
    const [optionsHerdsman, setOptionsHerdsman] = useState(herdsman.map(item => {return {label: item.name, value: item.id}}));
    const [selectedHerdsman, setSelectedHerdsman] = useState([]);
    const [currentLoggedInHerdsman] = useState({ label: getUserInfo()?.fullName, value: getUserInfo()?.herdsmanId });
    
    useEffect(() => {
        setCheckNeedHelp(updateItem?.needHelp);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Họ và tên đệm: </label>
                    <label>{updateItem?.fullName}</label>
                </div>
                <div className="form-group col-md-3 col-sm-6">
                    <label>Năm sinh: </label>
                    <label>{updateItem?.yearOfBirth}</label>
                </div>
                <div className="form-group col-md-3  col-sm-6">
                    <label>Giới tính: </label>
                    <label>{updateItem?.gender===0 ? "Nữ" : "Nam" }</label>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Số điện thoại: </label>
                    <label>{updateItem?.phoneNumber}</label>
                </div>
                <div className="form-group col-md-6">
                    <label>Quận/ huyện: </label>
                    <label>{updateItem?.districtName}</label>
                </div>
            </div>
            <div className="row">
                
                <div className="form-group col-md-6">
                    <label>Độ tuổi: </label>
                    <label>{updateItem?.departmentName}</label>
                </div>
                <div className="form-group col-md-6">
                    <label>Nghề nghiệp: </label>
                    <label>{updateItem?.career}</label>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Loại: </label>
                    <label>{updateItem?.customerType===0 ? "Chiên" : "La-xa-rơ"}</label>
                </div>
                <div className="form-group col-md-6">
                    <label>ACE dẫn dắt: </label>
                    <label>
                        {updateItem.herdsmanName1 && <span>{updateItem.herdsmanName1}</span>} <br />
                        {updateItem.herdsmanName2 && <span>{updateItem.herdsmanName2}</span>}<br />
                        {updateItem.herdsmanName3 && <span>{updateItem.herdsmanName3}
                        </span>}
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Ghi chú: </label>
                    <label>{updateItem?.description}</label>
                </div>
                <div className="form-group col-md-6">
                    <div className="row">
                        <div className="form-group col-md-12">
                            {checkNeedHelp && updateItem?.departmentHelpingId && <>
                                <label>Đội giúp đỡ: {updateItem?.departmentHelpingName}</label><br/>
                                <label>Ace giúp đỡ: {updateItem?.herdsmanHelpingName1}</label>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12"><Divider /></div><br/>
            </div>
            <div className="row">
                {customerDiary && customerDiary.length && customerDiary.map((item, index) => <>
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4" style={{ paddingRight: '10px' }} key={index}>
                        <label><b>{index+1}.</b>&nbsp;<i className="far fa-calendar-alt text-warning"></i> {dayjs(item.diaryDate).format("DD/MM/YYYY hh:mm")} &nbsp;-&nbsp;{item.diaryContent}</label>
                        <Divider />        
                    </div>
                </>)}
            </div>
        </>
    )
}