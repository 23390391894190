const headCellsListCustomerNeedHelp = [
    {
        id: 'FullName',
        numeric: false,
        disablePadding: false,
        label: 'Họ và tên',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'needHelp',
        numeric: false,
        disablePadding: true,
        label: 'Nhận giúp đỡ',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'helpingCount',
        numeric: false,
        disablePadding: true,
        label: 'Ace giúp đỡ',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'District.Name',
        numeric: false,
        disablePadding: false,
        label: 'Quận/Huyện',
        className: 'pt-3 pb-3',
    },
    {
        id: 'PhoneNumber',
        numeric: false,
        disablePadding: true,
        label: 'Điện thoại',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Herdsman1.LastName',
        numeric: false,
        disablePadding: false,
        label: 'ACE dẫn dắt',
        className: 'pt-3 pb-3',
        style: { minWidth: "100px" }
    },
    {
        id: 'Status',
        numeric: false,
        disablePadding: true,
        label: 'Các bước',
        className: 'pt-3 pb-3',
        style: {minWidth: "170px"}
    },
    {
        id: 'Career',
        numeric: false,
        disablePadding: false,
        label: 'Nghề nghiệp',
        className: 'pt-3 pb-3',
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: true,
        label: 'Ghi chú',
        className: 'pt-3 pb-3',
        style: { minWidth: "200px" }
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
    },
];

export default headCellsListCustomerNeedHelp;