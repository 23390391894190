const headCellsListTask = [
        {
            id: 'needHelp',
            numeric: false,
            disablePadding: true,
            label: 'Cần giúp đỡ',
            className: 'pt-3 pb-3',
            style: { minWidth: "50px" }
        },
        {
            id: 'datingTime',
            numeric: false,
            disablePadding: false,
            label: 'Ngày giờ hẹn',
            className: 'pt-3 pb-3',
            style: { minWidth: "90px" }
        },
        //{
        //    id: 'priotrity',
        //    numeric: false,
        //    disablePadding: false,
        //    label: 'Độ ưu tiên',
        //    className: 'pt-3 pb-3',
        //},
       
        {
            id: 'customerName',
            numeric: false,
            disablePadding: false,
            label: 'Ta-lâng',
            className: 'pt-3 pb-3',
            style: { minWidth: "120px" }
        },
        //{
        //    id: 'status',
        //    numeric: false,
        //    disablePadding: false,
        //    label: 'Trạng thái',
        //    className: 'pt-3 pb-3',
        //},
        {
            id: 'Herdsman.Lastname',
            numeric: false,
            disablePadding: false,
            label: 'Swat',
            className: 'pt-3 pb-3',
            style: { minWidth: "120px" }
        },
        {
            id: 'dptmName',
            numeric: false,
            disablePadding: true,
            label: 'BP trợ giúp',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
    },
    {
        id: '',
        numeric: false,
        disablePadding: false,
        label: 'Trạng thái',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
        {
            id: 'address',
            numeric: false,
            disablePadding: true,
            label: 'Địa điểm tiếp xúc',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: 'Chủ đề tiếp xúc',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
        
        {
            id: 'description',
            numeric: false,
            disablePadding: true,
            label: 'Ghi chú',
            className: 'pt-3 pb-3',
            style: { minWidth: "150px" }
        },
        //{
        //    id: 'IsActived',
        //    numeric: false,
        //    disablePadding: false,
        //    label: 'Hoạt động',
        //    className: 'pt-3 pb-3',
        //},
        {
            id: 'action',
            numeric: false,
            disablePadding: true,
            label: '',
            className: 'pt-3 pb-3 pl-4',
        },
];

export default headCellsListTask;