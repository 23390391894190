const fakeDataCookingReg = [
    {
        numberMealSet: 3,
        createAt: 1705215900000
    },
    {
        numberMealSet: 5,
        createAt: 1705302300000
    },
    {
        numberMealSet: 2,
        createAt: 1705388700000
    },
    {
        numberMealSet: 8,
        createAt: 1705475100000
    },
    {
        numberMealSet: 1,
        createAt: 1705561500000
    },
    {
        numberMealSet: 6,
        createAt: 1705647900000
    },
    {
        numberMealSet: 3,
        createAt: 1705734300000
    },
]

export default fakeDataCookingReg;